import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class AutoCheckoutRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {}
    }
  }

  changeProperty(property, event) {
    var rule = this.props.rule
    rule[property] = event.target.value;
    if (property == 'discount' && rule.auto_fill_counter) {
      rule.counter_offer = rule.discount
      rule.error.counter_offer = false
    }
    rule.error[property] = false
    if (property == 'filter_type') {
      rule.error.condition = false
    }
    this.props.changeRule(rule)
  }

  handleAutoFillCounter() {
    this.changeProperty('auto_fill_counter', { target: { value: false } })
  }

  renderCounterSettings () {
    return this.props.rule.action == 1 ?
      (<span>
        <label className="col-form-label rule-label">with</label>
        <input
          type="number"
          min="0.01"
          step="0.01"
          max="100"
          className={this.props.rule.error.counter_offer ? "form-control rule-counter-offer error-field" : "form-control rule-counter-offer"}
          value={this.props.rule.counter_offer}
          onChange={(e) => this.changeProperty('counter_offer', e)} />
        <label className="col-form-label rule-label">
          {I18n.t("admin.auto_checkout.rule.counter_after")}
        </label>
      </span>) : ""
  }

  percentageRuleForm() {
    return (
      <React.Fragment>
      <div className="form-group">
        <label className="col-form-label rule-label"
          dangerouslySetInnerHTML={{ __html: I18n.t("admin.auto_checkout.rule.discount_before") }}>
        </label>
        <input
          type="number"
          min="0.01"
          step="0.01"
          max="100"
          className={this.props.rule.error.discount ? "form-control rule-price-condition error-field" : "form-control rule-price-condition"}
          defaultValue={this.props.rule.discount}
          onChange={(e) => this.changeProperty('discount', e)}
          onBlur={() => this.handleAutoFillCounter()} />
        <label className="col-form-label rule-label">
          {I18n.t("admin.auto_checkout.rule.discount_after")}
        </label>
      </div>
      <div className="form-group">
        <label className="col-form-label rule-label"
          dangerouslySetInnerHTML={{ __html: I18n.t("admin.auto_checkout.rule.counter_before") }}>
        </label>
        <select className="form-control filter-type"
          value={this.props.rule.action}
          onChange={(e) => this.changeProperty('action', e)}>
          <option value="1">counter offer</option>
          <option value="2">don't counter and send email</option>
        </select>
        {this.renderCounterSettings()}
      </div>
      </React.Fragment>
    )
  }

  fixedRuleForm () {
    return (
      <div className="form-group">
        <label className="col-form-label rule-label"
          dangerouslySetInnerHTML={{ __html: I18n.t("admin.auto_checkout.rule.fixed_discount_label") }}>
        </label>
        <input
          type="number"
          min="0.01"
          step="0.01"
          max="100"
          style={{ margin: '0px 0px 0px 10px'}}
          className={this.props.rule.error.discount ? "form-control rule-price-condition error-field" : "form-control rule-price-condition"}
          defaultValue={this.props.rule.discount}
          onChange={(e) => this.changeProperty('discount', e)}
          onBlur={() => this.handleAutoFillCounter()} />
        <span className="input-group-addon" style={{ display: 'inline', backgroundColor: '#eee', marginRight: '10px' }}id="basic-addon2">{this.props.currency}</span>
      </div>
    )
  }

  ruleForm() {
    switch (this.props.rule.discount_type) {
      case 'percentage':
        return this.percentageRuleForm();
      case 'fixed':
        return this.fixedRuleForm();
      default:
        return this.percentageRuleForm()
    }
  }

  render() {
    let placeholer_for_condition = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      I18n.t("admin.auto_checkout.rule.condition_one.standard_plan"),
      I18n.t("admin.auto_checkout.rule.condition_one.basic_plan")
    )
    if (this.props.rule.filter_type == '3') {
      placeholer_for_condition = 'Product price'
    }
    return (
      <div className="form-inline">
        <div className="form-group">
          <select className="form-control filter-type"
            value={this.props.rule.filter_type}
            onChange={(e) => this.changeProperty('filter_type', e)}>
            <option value="1">Auto-accept only these products</option>
            <option value="2">Auto-accept all products</option>
            {
              this.props.flipperFlags.based_on_price_rules ?
                <option value="3">Auto-accept only products with price equal or more than</option> : null
            }
          </select>
          <input
            min="1"
            type={this.props.rule.filter_type == 3 ? 'number' : 'text'}
            disabled={this.props.rule.filter_type == 2}
            className={this.props.rule.error.condition ? "form-control rule-condition error-field" : "form-control rule-condition" }
            defaultValue={this.props.rule.condition}
            onChange={(e) => this.changeProperty('condition', e)}
            placeholder={placeholer_for_condition} />
        </div>
        <div className="form-group">
          <label>Discount type:</label>
          <select className="form-control filter-type"
            value={this.props.rule.discount_type}
            onChange={(e) => this.changeProperty('discount_type', e)}>
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed</option>
          </select>
        </div>
        {this.ruleForm()}
        <div className="form-group">
          <button type="button" className="btn btn-danger btn-sm remove-rule"
            onClick={() => this.props.removeRule(this.props.rule)}>
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>
      </div>
    )
  }
}

AutoCheckoutRule.propTypes = {

}

export default AutoCheckoutRule
