import React from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom';

class AdvancedSettingsForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='user-setting-form'>
        <div className="form-group">
          <label className="color-label">Advanced CSS:</label>
          <textarea className="form-control"
            defaultValue={this.props.userSetting.modal_css}
            onChange={(e) => this.props.handleModalCssChange(e)}
            placeholder="" rows="20"></textarea>
        </div>
        <p>This CSS will be inserted in the product page and in the Make an Offer modals.</p>
        <p>
          Need help? Our support team would be delighted to assist you with complete customization completely free of charge.
          Submit <a className="ml-5 text-" data-toggle="modal" data-target="#request_submit"><strong>this</strong></a> short form to get started.
        </p>
      </div>
    );
  }
}

export default AdvancedSettingsForm
