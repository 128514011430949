import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import SuccessForm from "./SuccessForm";

class SuccessPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  successHeading () {
    return this.props.themeSettings.data.success_heading || this.props.defaultTexts.success_heading;
  }

  successContent () {
    return this.props.themeSettings.data.success_content || this.props.defaultTexts.success_content;
  }

  render () {
    return (
      <div className="mf_modal">
        <div className="modal-content popup_with_notes">
          <div className="modal-header" style={this.divHeaderStyle()}>
            <button
              type="button"
              className="close"
              data-dismiss="mf_modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={this.secondaryHeadingTitleStyle()}>×</span>
            </button>
            <div className="modal-title">
            </div>
          </div>
          <div className="modal-body">
            <div className="success_container" style={{ display: 'block' }}>
              <h5>{this.successHeading()}</h5>
              <p>{this.successContent()}</p>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default SuccessPreview
