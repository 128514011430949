import { h } from 'preact'
import React from "react"
import MultiselectModal from './MultiselectModal'
import Checkbox from '@material-ui/core/Checkbox'
import AlertNotification from '../base/AlertNotification'
import PopupPagination from '../base/PopupPagination'

class AskForCombine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showMultiselectModal: false,
      offersCount: this.props.offers.length,
      selected: [],
      page: 0,
      rowsPerPage: 10,
      error: ""
    }
  }

  hide () {
    $("#" + this.modal_id()).modal('hide');
  }

  componentDidMount () {
    $("#" + this.modal_id()).modal('show');

    $("#" + this.modal_id()).on('hidden.bs.modal', () => {
      if (this.props.onCloseModal) {
        this.props.onCloseModal();
      }
    });
  }

  modal_id () {
    return this.props.offer.id + "_combine_offer";
  }

  declineCombine () {
    this.hide();
    this.props.declineCombine();
  }

  acceptCombine () {
    const {selected} = this.state
    if(selected.length > 100){
      this.setState({error: "You can't able to combine more than 100 offers."})
      return
    }

    if(selected.length == 0){
      this.setState({error: "Please select offers to continue."})
      return  
    }

    this.setState({ showMultiselectModal: true })
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = this.props.offers.map((n) => n.id);
      this.setState({selected: allSelected})
      return;
    }
    this.setState({selected: []});
  };

  handleCheckboxChange (value, event, offer) {
    const {selected} = this.state
    const selectedIndex = selected.indexOf(offer);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, offer);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({selected: newSelected})
  }

  itemSelected (self, offer) {
    return this.state.selected.indexOf(offer) !== -1
  }

  handleChangeOfferPage = (data) => {
    this.setState({page: data.selected})
  }

  hideError(e) {
    this.setState({ error: "" });
    e.preventDefault();
  }

  combinedLimit () {
    var combinedError = '';
    if (this.state.error && this.state.error != "") {
      combinedError = <AlertNotification handleClick={(e) => this.hideError(e)} alertType="danger" alertMessage={this.state.error} />
    }
    return(
      <div>
        {combinedError}
      </div>
    )
  }

  modalContent () {
    const {page, rowsPerPage, selected, offersCount} = this.state
    const pageCount = offersCount / rowsPerPage;
    var self = this
    var offers = [];
    this.props.offers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach(function(offer, index) {
      offers.push(
        <tr key={index}>
          <td>
            <Checkbox
              color="primary"
              checked={self.itemSelected(self, offer.id)}
              onChange={(e) => self.handleCheckboxChange(self, e, offer.id)}
            />
          </td>
          <td>{offer.created_at}</td>
          <td>
            <a target="_blank" href={offer.product.url}>
              {offer.product.title}
            </a>
          </td>
          <td>{offer.product.price}</td>
          <td>{offer.amount_with_currency}</td>
        </tr>
      )
    })

    return (
      <div className="modal-content">
        <div className="modal-header ">
          <a className="glyphicon glyphicon-remove" onClick={() => this.hide()}></a>
          <h4 className="modal-title capitalize">Combine Offers</h4>
        </div>
        <div className="modal-body">
          <h3 className="center">You have several offers from this user.</h3>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < offersCount}
                    checked={ selected.length > 0 && selected.length === offersCount}
                    onChange={this.handleSelectAllClick}
                  />
                </th>
                <th>Date (EST)</th>
                <th>Product</th>
                <th>Price</th>
                <th>Offer Amount</th>
              </tr>
            </thead>
            <tbody>
              {offers}
            </tbody>
          </table>
          <div className="text-center combined-paginate">
            <PopupPagination 
              pageCount={pageCount}
              handleChangeOfferPage={this.handleChangeOfferPage}
            />
          </div>
          {this.combinedLimit()}
          <h3 className="center">Do you want to combine these offers?</h3>
        </div>
        <div className="modal-footer combine-footer">
          <button id="submit" type="button" className="btn btn-primary"
            onClick={() => this.acceptCombine()}>Yes</button>
          <button className="btn btn-danger" onClick={() => this.declineCombine()}>No</button>
        </div>
      </div>
    )
  }

  multiselectModal () {
    return <MultiselectModal
      updateOffers={this.props.updateOffers}
      selectedOffers={this.props.offers.filter((e) => this.state.selected.indexOf(e.id) !== -1)}
      userSetting={this.props.userSetting}
      initialUserEmail={this.props.initialUserEmail}
      initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
      onCloseModal={() => this.hide()} />
  }

  render () {
    return (
      <div id={this.modal_id()} className="modal" >
        <div className="modal-dialog modal-lg">
          {this.state.showMultiselectModal ? this.multiselectModal() : this.modalContent()}
        </div>
      </div>
    )
  }
}

export default AskForCombine;
