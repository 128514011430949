import { h } from 'preact'
import React from "react"
// import PropTypes from "prop-types"
import AlertNotification from '../base/AlertNotification'

class OfferModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = { error: "", counterOffer: "" };
  }

  hide() {
    $("#" + this.modal_id()).modal('hide');
  }

  componentDidMount() {
    $("#" + this.modal_id()).modal('show');

    $("#" + this.modal_id()).on('hidden.bs.modal', () => {
      if (this.props.onCloseModal) {
        this.props.onCloseModal();
      }
    });
  }

  modal_id() {
    return this.props.offer.id + "_offer";
  }

  submitForm() {
    var button = $("#" + this.modal_id() + " #submit");
    button.prop('disabled', true);

    var params = {}

    var emailCopy = this._getEmailCopy();
    var required = this.props.action === "decline" ? [] : ["[[CHECKOUT]]"];
    var v = this._validateEmail(emailCopy, required);
    if (v.valid === false) {
      this.setState({error: v.errors.join("\n")});
      button.prop('disabled', false);
      return;
    } else {
      params['email_subject'] = emailCopy["subject"];
      params['email_body'] = emailCopy["body"];
    }

    if (this.props.action === "counter") {
      var counterOffer = this.state.counterOffer;

      if (!counterOffer) {
        this.setState({error: 'Please enter counter offer'});
      } else {
        var offer = this._parseOffer(counterOffer);

        if (this._isValidOffer(offer)) {
          params['counter_offer'] = counterOffer;
          this.setState({ counterOffer: '' })
          this.hide();
          this.props.submitForm(params);
        } else {
          this.setState({error: "Counter offer is not valid"});
        }
      }
    // accept or decline
    } else {
      this.hide();
      this.props.submitForm(params);
    }
    button.prop('disabled', false);
  }

  _getEmailCopy() {
    return {
      "subject": $("#" + this.modal_id() + " [name=email_subject]").val(),
      "body": $("#" + this.modal_id() + " [name=email_body]").val()
    }
  }

  _validateEmail(email, requiredVariables = []) {
    var subject = email["subject"];
    var body = email["body"];
    var res = {valid: true, errors: []};

    if (!subject || subject.length === 0) {
      res.valid = false;
      res.errors.push("Subject can't be empty");
    }

    if (!body || body.length === 0) {
      res.valid = false;
      res.errors.push("Body can't be empty");
    }

    // Check for presence of required variables
    for (let v in requiredVariables) {
      var variable = requiredVariables[v];
      if (body && !body.includes(variable)) {
        res.valid = false;
        res.errors.push(`${variable} is missing in body`);
      }
    }

    return res;
  }

  _parseOffer(offer_str) {
    try {
      var re = /^([\d.]+)$/;
      let offer = re.exec(offer_str.trim())[1];
      return parseFloat(offer);
    } catch (err) {
      return false;
    }
  }

  _isValidOffer(offer) {
    var product_price = parseFloat(this.props.offer.product.price);
    return offer && offer > 0;
  }

  hideError() {
    this.setState({ error: "" });
  }

  _button_class() {
    var action = this.props.action;
    if (action === "accept") {
      return "btn btn-primary capitalize";
    } else if (action === "counter") {
      return "btn btn-warning capitalize"
    } else if (action === "decline") {
      return "btn btn-danger capitalize"
    }
  }

  _shopUrl() {
    var url = this.props.offer.product.url;
    // Return base url
    return url.split('/')[2];
  }

  handleCounterOfferChange(e) {
    this.setState({ counterOffer: e.target.value });
  }

  form() {
    var action = this.props.action;
    var counterOfferForm = "";
    if (action === "counter") {
      counterOfferForm = (<div className="counter-offer-form">
                        <h3>The customer will be notified with the counter offer</h3>
                        <label>Counter offer:</label>
                        <input value={this.state.counterOffer}
                          id={"counter_offer_" + this.props.offer.id }
                          type="text" name="counter_offer"
                          onChange={(e) => this.handleCounterOfferChange(e)}></input>
                      </div>);
    }

    var formError = '';
    if (this.state.error && this.state.error != "") {
      formError = <AlertNotification
        handleClick={() => this.hideError()}
        alertType="danger" alertMessage={this.state.error} />
    }

    var footer = '';
    if (action == "accept") {
      footer = <div>[[CHECKOUT]] will be replaced with the actual checkout link</div>
    } else if (action == "counter") {
      footer = (<div>
        [[CHECKOUT]] will be replaced with the actual checkout link<br></br>
        [[COUNTER]] will be replaced with the counter offer
      </div>)
    }
    var subject = this.props.userSetting.email_copies[action]['subject'];
    var body = this.composeBody(this.props.userSetting.email_copies[action]['body']);

    return (
      <div>
        {formError}
        <form action="offer_accept" method="post" className="modal-form">
          {counterOfferForm}
          <h3>Email to customer</h3>
          <label>Subject:</label>
          <input name="email_subject" type="text" defaultValue={subject}></input><br></br>
          <label>Body:</label>
          <textarea name="email_body" defaultValue={body} wrap="off"></textarea>
          {footer}
        </form>
      </div>
    );
  }



  composeBody(body) {
    body = body.replace(/\[\[PRODUCT_TITLE\]\]/g, `${this.props.offer.product.title}${this.props.offer.product.variant_title ? `(${this.props.offer.product.variant_title})` : '' }`);
    body = body.replace(/\[\[OFFER_AMOUNT\]\]/g, this.props.offer.amount_with_currency);
    body = body.replace(/\[\[QUANTITY\]\]/g, this.props.offer.quantity);
    body = body.replace(/\[\[STORE_EMAIL_ADDRESS\]\]/g, `<a href='mailto:${this.props.initialUserEmail}'>${this.props.initialUserEmail}</a>`);
    body = body.replace(/\[\[STORE_SECONDARY_EMAIL_ADDRESS\]\]/, `<a href='mailto:${this.props.initialUserSecondaryEmail}'>${this.props.initialUserSecondaryEmail}</a>`);
    return body;
  }

  modalContent() {
    var action = this.props.action;

    return (
      <div className="modal-content">
        <div className="modal-header ">
          <a className="glyphicon glyphicon-remove" onClick={() => this.hide()}></a>
          <h4 className="modal-title capitalize">{action} Offer</h4>
        </div>
        <div className="modal-body">
         {this.form()}
        </div>
        <div className="modal-footer">
          <button id="submit" type="button"
            className={this._button_class()}
            onClick={() => this.submitForm()}>{action}</button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id={this.modal_id()} className="modal" >
        <div className="modal-dialog">
          {this.modalContent()}
        </div>
      </div>
    )
  }
}

export default OfferModal;
