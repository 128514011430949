import React from "react"

class Help extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    $(this.myRef.current).tooltip();
  }

  render() {
    return (
      <span className="help-icon">
        <i
          ref={this.myRef}
          data-toggle='tooltip'
          data-html="true" data-placement="right"
          title={this.props.text}
          className="fa fa-question-circle">
        </i>
      </span>
    );
  }
}

export default Help
