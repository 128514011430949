import { h } from 'preact'
import React from "react"
import Header from '../Header'

class EndStep extends React.Component {
  openLookAndFeel () {
    $('#onboarding_modal').modal('hide');
    document.getElementById("setup-btn").click();
    document.getElementById("look_and_feel_tab").click();
  }

  openEmailCopies () {
    $('#onboarding_modal').modal('hide');
    document.getElementById("setup-btn").click();
    document.getElementById("email_copies").click();
  }

  render () {
    return (
      <div className="content">
        <Header title="Congratulations!" />
        <div className="step_content end_step">
          <p className="critical_parts">
            You have successfully set up the most critical parts of the app.
            The following are a few more things that need to be set up and can be easily
            done through our app settings.
          </p>

          <ul className="next_features">
            <li onClick={() => this.openLookAndFeel()}>1. Customize popup and button look and feel.</li>
            <li onClick={() => this.openEmailCopies()}>2. Customize the emails that your customers receive when you manually accept, reject or counter offers.</li>
          </ul>

          <div className="contact_us">
            <p>Our number one priority is customer care.</p>
            <p>If you have any questions, please email us at <a href="mailto:support@magicalapps.ca?subject=Make an Offer - Support Request">support@magicalapps.ca</a></p>
            <p>We would be delighted to answer your questions and concerns.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default EndStep
