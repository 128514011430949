import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class ButtonPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  buttonStyle() {
    return {
      backgroundColor: this.props.userSetting.button_background_color,
      color: this.props.userSetting.button_font_color
    }
  }
  render() {
    return (
      <div>
        <span id="mf_button">
          <button type='button' style={this.buttonStyle()}> {this.props.userSetting.button_text}</button>
        </span>
        <style>{this.props.userSetting.modal_css}</style>
      </div>
    );
  }
}

export default ButtonPreview
