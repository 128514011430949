import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class PromotionRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
    }
  }

  changeProperty(property, event) {
    var product = this.props.product
    product[property] = event.target.value;
    product.errors[property] = false;
    this.props.changeProduct(product)
  }


  getSubCatgories() {
    const id = this.props.product.category_id
    if (id == '') {
      return []
    }
    const category = this.props.categories.find((c) => c.id == id)
    if (!category.children.length) {
      return [category]
    }
    return category.children
  }

  render() {
    const product = this.props.product;
    return (
      <div className="row mb-10">
        <div className="col-md-2">
          <label>Product ID</label>
          <input type="number"
            className={product.errors && product.errors.platform_id ? "form-control error-field" : "form-control"}
            placeholder="enter product id here"
            value={product.platform_id}
            onChange={(e) => this.changeProperty('platform_id', e)}
            />
        </div>

        <div className="col-md-2">
          <label>Sku (optional)</label>
          <input type="text"
            className="form-control"
            placeholder="enter specific sku number here"
            value={product.sku}
            onChange={(e) => this.changeProperty('sku', e)}
          />
        </div>

        <div className="col-md-3">
          <label>Category</label>
          <select className={product.errors && product.errors.category_id ? "form-control error-field" : "form-control"}
            value={product.category_id}
            onChange={(e) => this.changeProperty('category_id', e)}>
            <option value="">Select Category</option>
            {this.props.categories.map((category, index) => {
              return(
                <option key={index} value={category.id}>{category.title}</option>
              )
            })}
          </select>
        </div>

        <div className="col-md-3">
          <label>Sub Categroy</label>
          <select className={product.errors && product.errors.sub_category_id ? "form-control error-field" : "form-control"}
            value={product.sub_category_id}
            onChange={(e) => this.changeProperty('sub_category_id', e)}
            disabled={product.category_id == ''}>
            <option value="">Select Sub Category</option>
            {this.getSubCatgories().map((category, index) => {
              return (
                <option key={index} value={category.id}>{category.title}</option>
              )
            })}
          </select>
        </div>

        <div className="col-md-2">
          <div className="form-group remove_promote_product">
            <button type="button" className="btn btn-danger btn-sm"
              onClick={() => this.props.removeProduct(this.props.product)}>
              <i className="fa fa-trash"></i> Delete
          </button>
          </div>
        </div>
      </div>
    )
  }
}

PromotionRow.propTypes = {

}

export default PromotionRow
