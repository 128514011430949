import React from "react"
import I18n from 'i18n-js/index.js.erb'
import PromotionRow from './PromotionRow';

class Promotion extends React.Component {
  constructor(props) {
    super(props);
  }

  addNewProduct() {
    var products = this.props.userSetting.storefront_products
    products.push({
      id: Date.now(),
      platform_id: '',
      sku: '',
      category_id: '',
      sub_category_id: '',
      new: true,
      errors: { platform_id: false, category_id: false, sub_category_id: false }
    })
    this.props.handleStorefrontProductsChange(products)
  }

  removeProduct(product) {
    var products = this.props.userSetting.storefront_products
    var index = products.indexOf(product);
    products[index]._destroy = '1';
    this.props.handleStorefrontProductsChange(products)
  }

  changeProduct(product) {
    var products = this.props.userSetting.storefront_products
    var index = products.map((item) => { return item.id; }).indexOf(product.id);
    products[index] = product;
    this.props.handleStorefrontProductsChange(products)
  }

  render() {

    return (
      <div className="row mt-30">
        <div className="col-md-12">
          <h3>Do you want promotion for your Products?</h3>
          <p className="explanation">{I18n.t("admin.promotion.explanation")}</p>
          <p className="explanation">
            Don't find needed category? <a href="/feedbacks/new" target="_blank">Contact us.</a>
          </p>
          {this.props.userSetting.storefront_products.map(function (product, index) {
            if (product._destroy) {
              return false
            } else {
              return (
                <PromotionRow
                  key={index}
                  product={product}
                  categories={this.props.categories}
                  extraFeatures={this.props.extraFeatures}
                  flipperFlags={this.props.flipperFlags}
                  changeProduct={(p) => this.changeProduct(p)}
                  removeProduct={(p) => this.removeProduct(p)}
                />
              )
            }
          }.bind(this))}
        </div>
        <div className="col-md-12 mt-10">
          <a className="add-product-btn mt-10" onClick={() => this.addNewProduct()}>
            {I18n.t("admin.promotion.add_product")}
          </a>
        </div>
      </div>
    )
  }
}

Promotion.propTypes = {

}

export default Promotion
