import { h } from 'preact'
import React from "react"
import Header from '../Header'

class WelcomeStep extends React.Component {
  render () {
    return (
      <div className="content">
        <Header title="WELCOME!" greeting={true}/>
        <div className="step_content row step-one">
          <div className="col-sm-12">
            <div className="col-sm-11 col-sm-offset-1">
              <h4 className="ready_question">Are you ready to:</h4>
              <ul className="benefits">
                <li>
                  <span className="benefit_phrase">
                    Put your <span className="allotment">conversion rates</span> on steroids?
                  </span>
                </li>
                <li>
                  <span className="benefit_phrase">
                    Fight <span className="allotment">cart abandonment</span>?
                  </span>
                </li>
                <li>
                  <span className="benefit_phrase">
                    Build massive <span className="allotment">email leads</span>?
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomeStep
