import React from "react"
import PropTypes from "prop-types"

class AcceptPreview extends React.Component {
  constructor(props) {
    super(props);
  }
  
  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  content () {
    return this.props.themeSettings.data.auto_reject_content || this.props.defaultTexts.auto_reject_content;
  }

  greeting () {
    return this.props.themeSettings.data.auto_reject_greeting || this.props.defaultTexts.auto_reject_greeting;
  }



  render () {
    return (
      <div className="mf_modal">
        <div className="modal-content popup_with_notes">
          <div className="modal-header" style={this.divHeaderStyle()}>
            <button
              type="button"
              className="close"
              data-dismiss="mf_modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={this.secondaryHeadingTitleStyle()}>×</span>
            </button>
            <div className="modal-title">
            </div>
          </div>
          <div className="modal-body">
            <div className="success_container" style={{ display: 'block' }}>
              <div className="accepted">
                <h5>{this.greeting()}</h5>
                <p>{this.content()}</p>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default AcceptPreview
