import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import Offer from './Offer'
import Pagination from '../base/Pagination'
import AlertNotification from '../base/AlertNotification'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const status = [{id: 1, name: 'new'}, {id: 2, name: 'accepted'}, {id: 3, name: 'declined'}, {id: 4, name: 'countered'}]
function debounce (fn, time) {
  let timeoutId
  return wrapper
  function wrapper (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}

class OfferList extends React.Component {

  constructor(props) {
    let userSetting = props.userSetting
    if (typeof props.userSetting === 'string') {
      userSetting = JSON.parse(props.userSetting);
    }
    super(props);
    this.state = {
      offers: [],
      offerType: 'active',
      userSetting: userSetting,
      themeSetting: this.modifyBooleanThemeSetting(props.theme_setting),
      page: 1,
      pages: 0,
      drpStartDate: moment().subtract(1,'month').format("MM-DD-YYYY"),
      drpEndDate: moment().format("MM-DD-YYYY"),
      fsDate: moment().subtract(1,'month').format("DD-MM-YYYY"),
      feDate: moment().format("DD-MM-YYYY"),
      statusValue: '',
      offersFilterData: [],
      customerName: '',
      offerStatus: '',
      hover: '',
      sortEmail: 'ASC',
      sortAmount: 'ASC',
      sortStatus: 'ASC',
      sortName: 'ASC',
      sortDate: 'ASC'
    }
    this.searchFilter = this.searchFilter.bind(this)
  }

  modifyBooleanThemeSetting (setting) {
    Object.keys(setting.data).map(function (objectKey, index) {
      var value = setting.data[objectKey];
      if (value == 'true') {
        setting.data[objectKey] = true
      } else if (value == 'false') {
        setting.data[objectKey] = false
      }
    });
    return setting
  }

  handleArchiveOffer = (offer) => {
    var offers = this.state.offers.filter(function(filter_offer) {
      return offer.id !== filter_offer.id;
    });
    this.setState({ offers: offers });
  }

  loadOffers(filterParams, page = 1) {
    const filterObject = { startDate: this.state.fsDate, endDate: this.state.feDate, name: this.state.customerName, status: this.state.offerStatus};
    $.ajax({
      url: this.props.url,
      data: {
        offerType: filterParams.offerType,
        page: page,
        email: filterParams.email,
        search: filterObject
      },
      dataType: "json",
      success: (data) => {
        this.setState({ offers: data.offers, page: page, pages: data.pages, offersFilterData: data.offers });
      },
      error(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }
    });
  }

  handleChangePage(page) {
    this.loadOffers({ offerType: this.state.offerType }, page);
  }

  downlaodOffers(e) {
    this.showAlert('We start generating the csv file with your offers. It could take upto 2 minutes.', "info")
    window.location = this.props.download_url;

    e.preventDefault();
    e.stopPropagation();
  }

  downloadOffersEmails(e) {
    this.showAlert('We start generating the csv file with your offers. It could take upto 2 minutes.', "info")
    window.location = this.props.download_emails_url;

    e.preventDefault();
    e.stopPropagation();
  }

  setUserSettings(userSetting) {
    this.setState({ userSetting: JSON.parse(userSetting) });
  }

  componentDidMount() {
    $('#msg_bridge').on('new_user_settings', (e, userSetting) => this.setUserSettings(userSetting));
    this.loadOffers({ offerType: this.state.offerType });
    this.generateStylesForMobileTable()
    const header = document.getElementById("wrapper");
    header.classList.add("remove-scroll");
  }

  generateStylesForMobileTable() {
    var css = '@media only screen and(max-width: 760px), (max-device-width: 768px) {' +
              'td:nth-of-type(1):before { content: "Date"; }' +
              'td:nth-of-type(2):before { content: "Email"; }' +
              'td:nth-of-type(3):before { content: "Product"; }' +
              'td:nth-of-type(4):before { content: "Currency Code"; }' +
              'td:nth-of-type(5):before { content: "Price"; }' +
              'td:nth-of-type(6):before { content: "Offer Amount"; }' +
              'td:nth-of-type(7):before { content: "Counter"; }'
    var n = 8
    if (this.state.themeSetting.data.show_quantity) {
      css += 'td:nth-of-type(' + n + '):before { content: "Quantity"; }'
      n += 1
    }
    if (this.state.themeSetting.data.show_name) {
      css += 'td:nth-of-type(' + n + '):before { content: "Name"; }'
      n += 1
    }
    if (this.state.themeSetting.data.show_shipping_address) {
      css += 'td:nth-of-type(' + n + '):before { content: "Shipping Address"; }'
      n += 1
    }
    css += 'td:nth-of-type(' + n + '):before { content: "Sku"; }'
    n += 1
    if (this.state.themeSetting.data.show_phone_number) {
      css += 'td:nth-of-type(' + n + '):before { content: "Phone"; }'
      n += 1
    }
    css += 'td:nth-of-type(' + n + '):before { content: "Notes"; }'
    css += 'td:nth-of-type(' + (n + 1) + '):before { content: "Status"; } }'
    var head = document.head || document.getElementsByTagName('head')[0]
    var style = document.createElement('style');

    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
  }

  showActiveOffers() {
    this.setState({ offers: [], offerType: "active" });
    this.loadOffers({ offerType: "active" });
  }

  showAutoCheckoutOffers() {
    this.setState({offers: [], offerType: "auto_checkout"});
    this.loadOffers({ offerType: "auto_checkout" });
  }

  showArchivedOffers() {
    this.setState({ offers: [], offerType: "archive" });
    this.loadOffers({ offerType: "archive" });
  }

  showDeclineOffers() {
    this.setState({ offers: [], offerType: "auto_declined" });
    this.loadOffers({ offerType: "auto_declined" });
  }

  showAlert = (alertMsg, alertType = "danger") => {
    this.setState({ alertMsg: alertMsg, alertType: alertType });
  }

  hideAlert() {
    this.setState({ alertMsg: "" });
  }

  renderAutoCheckoutTab() {
    return (<span> <a
        href="#"
        className={this.state.offerType === "auto_checkout" ? "active" : ""}
        onClick={() => this.showAutoCheckoutOffers()}
      >
        <i className="fa fa-magic"></i> Auto Respond
      </a>
      &nbsp; <span className="separator">|</span> &nbsp; </span>)
  }

  hideModal() {
    this.setState({ showMultiselectModal: false });
  }

  submitForm() {
    this.loadOffers({ offerType: "active" });
    this.setState({ offerType: "active" })
    this.showAlert("The customer will be notified soon", "info")
  }

  updateOffers = (offers) => {
    this.state.offers.forEach(function (offer) {
      offers.forEach(function (off) {
        if (offer.id == off.id) {
          offer.status = off.action
        }
      })
    })
    this.setState({ offers: this.state.offers })
  }

  searchFilter = debounce(event => {
    this.setState({ customerName: event.target.value }, function() {
      this.loadOffers({ offerType: this.state.offerType});
    })
  }, 800)

  handleDateRangePicker = (event, picker) => {
    const StartDate = moment(picker.startDate).format("MM-DD-YYYY")
    const EndDate = moment(picker.endDate).format("MM-DD-YYYY")
    const FsDate = moment(picker.startDate).format("DD-MM-YYYY")
    const FeDate = moment(picker.endDate).format("DD-MM-YYYY")
    this.setState({ drpStartDate: StartDate, drpEndDate: EndDate, fsDate: FsDate, feDate: FeDate }, function() {
      this.loadOffers({ offerType: this.state.offerType});
    })
  }

  showDatePicker = () => {}

  handleStatusChange = (event) => {
    this.setState({ offerStatus: event.target.value, statusValue: event.target.value }, function() {
      this.loadOffers({ offerType: this.state.offerType});
    })
  }

  sortDate = (col) => {
    const { offers, sortDate } = this.state
    if(sortDate === "ASC") {
      const sortResult = [...offers].sort((a, b) => a[col].localeCompare(b[col]))
      this.setState({offers: sortResult, sortDate: 'DESC'})
    }else {
      const sortResult = [...offers].sort((a, b) => b[col].localeCompare(a[col]))
      this.setState({offers: sortResult, sortDate: 'ASC'})
    }
  }

  sortEmail = (col) => {
    const {offers, sortEmail } = this.state
    if(sortEmail === "ASC") {
      const sortResult = [...offers].sort((a, b) => a[col].localeCompare(b[col]))
      this.setState({offers: sortResult, sortEmail: 'DESC'})
    }else {
      const sortResult = [...offers].sort((a, b) => b[col].localeCompare(a[col]))
      this.setState({offers: sortResult, sortEmail: 'ASC'})
    }
  }

  sortAmount = (col) => {
    const {offers, sortAmount } = this.state
    if(sortAmount === "ASC") {
      const sortResult = offers.sort((a, b) => (a.amount > b.amount) ? 1 : -1)
      this.setState({offers: sortResult, sortAmount: 'DESC'})
    }else {
      const sortResult = offers.sort((a, b) => (b.amount > a.amount) ? 1 : -1)
      this.setState({offers: sortResult, sortAmount: 'ASC'})
    }
  }

  sortStatus = (col) => {
    const { offers, sortStatus } = this.state
    if(sortStatus === "ASC") {
      const sortResult = [...offers].sort((a, b) => a[col].localeCompare(b[col]))
      this.setState({offers: sortResult, sortStatus: 'DESC'})
    }else {
      const sortResult = [...offers].sort((a, b) => b[col].localeCompare(a[col]))
      this.setState({offers: sortResult, sortStatus: 'ASC'})
    }
  }

  sortName = (col) => {
    const { offers, sortName } = this.state
    if(sortName === "ASC") {
      const sortResult = [...offers].sort((a, b) => a[col].localeCompare(b[col]))
      this.setState({offers: sortResult, sortName: 'DESC'})
    }else {
      const sortResult = [...offers].sort((a, b) => b[col].localeCompare(a[col]))
      this.setState({offers: sortResult, sortName: 'ASC'})
    }
  }

  render() {
    var allOffers = this.state.offers;
    var alert = "";
    var offers = [];
    for (var key in allOffers) {
      offers.push(<Offer key={allOffers[key].id}
                         url={this.props.url}
                         combineOffersUrl={this.props.combineOffersUrl}
                         historyOffersUrl={this.props.historyOffersUrl}
                         userSetting={this.state.userSetting}
                         handleArchiveOffer={this.handleArchiveOffer}
                         showActiveOffers={() => this.showActiveOffers()}
                         hideArchiveButton={this.state.offerType === "archive"}
                         offer={allOffers[key]}
                         showAlert={this.showAlert}
                         updateOffers={this.updateOffers}
                         extraFeatures={this.props.extraFeatures}
                         themeSetting={this.state.themeSetting}
                         initialUserEmail={this.props.initialUserEmail}
                         initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                         flipperFlags={this.props.flipperFlags}/>);
    }

    if (this.state.alertMsg && this.state.alertMsg != "") {
      alert = (
        <AlertNotification
          handleClick={() => this.hideAlert()}
          alertType={this.state.alertType}
          alertMessage={this.state.alertMsg}
        />
      );
    }

    const downloadButton = supportsFeature(
      "export",
      this.props.extraFeatures,
      <div className="action-header-buttons">
        <a className="btn btn-primary download"
          onClick={() => this.downlaodOffers()}>
          Download All Offers
        </a>
      </div>
    );

    const downloadEmailButton = supportsFeature(
      "export",
      this.props.extraFeatures,
      <div className="action-header-buttons">
        <a className="btn btn-primary download"
          onClick={() => this.downloadOffersEmails()}>
          Download All Emails
        </a>
      </div>
    );
    let hover = false;
    return (
      <div className="offer-list">
        {alert}
        <div className="row offer-filter-row">
          <div className="col-md-4">
            <div className="flex_row">
              <DateRangePicker
                onApply={(event, picker) => this.handleDateRangePicker(event, picker)}
                onShow={() => this.showDatePicker()}
                initialSettings={{ startDate: this.state.drpStartDate, endDate: this.state.drpEndDate }}
              >
                <input 
                  type="text" 
                  className="form-control" 
                  id="fileinput" 
                />
              </DateRangePicker>
              <img 
                src="/assets/question_mark.png" 
                alt="" 
                width="20px"
                className="notification_div"
                onMouseOver={() => { hover = true;
                  setTimeout(() => {
                    if (hover) {
                      this.setState({hover: true});
                    }
                  }, 100);
                }}
                onMouseOut={() => {hover = false;
                  this.setState({hover: false});
                }}
              />
            </div>
            {this.state.hover && 
              <div className="hover_div">Date in UTC</div>
            }
          </div>
          
          <div className="col-md-4">
            <input
              type="text"
              placeholder="Search customer name and email address"
              className="form-control"
              onChange={() => this.searchFilter(event)}

            />
          </div>
          <div className="col-md-4">
            <select
              className="form-control statusSelect" 
              value={this.state.statusValue} 
              onChange={() => this.handleStatusChange(event)}
            >
              <option value="">Offers status</option>
              {status.map((option) => (
                <option value={option.name} key={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row offer-menu">
          <div className="col-md-5">
            <a
              href="#"
              className={this.state.offerType === "active" ? "active" : ""}
              onClick={() => this.showActiveOffers()}
            >
              <i className="fa fa-inbox"> </i> Inbox
            </a>{" "}
            &nbsp; <span className="separator">|</span> &nbsp;

            { this.renderAutoCheckoutTab() }
            <a
              href="#"
              id="archived-offers-switcher"
              className={this.state.offerType === "archive" ? "active" : ""}
              onClick={() => this.showArchivedOffers()}
            >
              <i className="fa fa-archive"> </i> Archive
            </a>{" "}
            &nbsp; <span className="separator">|</span> &nbsp;
            <a
              href="#"
              id="archived-offers-switcher"
              className={this.state.offerType === "auto_declined" ? "active" : ""}
              onClick={() => this.showDeclineOffers()}
            >
              <i className="fa fa-ban"> </i> Auto Declined
            </a>

          </div>
          <div className="col-md-7">
            {downloadButton}
            {downloadEmailButton}
          </div>
        </div>
        <div>
          <table className="table">
            <thead id="myHeader" className="header sticky_header">
              <tr>
                <th onClick={() => this.sortDate('c_date')} className="filter_th">Date (EST)</th>
                <th onClick={() => this.sortEmail('email')} className="filter_th">Email</th>
                <th>Product</th>
                <th>Currency Code</th>
                <th>Price</th>
                <th onClick={() => this.sortAmount('amount')} className="filter_th">Offer Amount</th>
                <th className="text-left">Counter</th>
                {this.state.themeSetting.data.show_quantity ? <th>Quantity</th> : null }
                {this.state.themeSetting.data.show_name ? <th onClick={() => this.sortName('c_name')} className="filter_th">Name</th> : null}
                <th>Sku</th>
                {this.state.themeSetting.data.show_phone_number ? <th>Phone</th> : null}
                <th>Notes</th>
                <th>Address</th>
                <th className="row_bg_color" onClick={() => this.sortStatus("status")} className="filter_th">Status</th>
              </tr>
            </thead>
            <tbody>
              {offers}
            </tbody>
          </table>
        </div>
        <Pagination page={this.state.page}
                    pages={this.state.pages}
                    handleChangePage={(page) => this.handleChangePage(page)} />
      </div>
    );
  }
}

OfferList.propTypes = {

};
export default OfferList
