import { h } from 'preact'
import React from "react"
import Header from '../Header'
import SwitchButton from '../../base/SwitchButton'

class ButtonStep extends React.Component {
  playVideo () {
    var myVideo = document.getElementById("video_1");
    if (myVideo.paused) {
      myVideo.play();
    }
    else {
      myVideo.pause();
    }
  }

  render () {
    return (
      <div className="content">
        <Header title="Choose Your Incentive Options" />
        <div className="step_content">
          <SwitchButton name='windowshopper-product_button'
            checked={this.props.userSetting.product_button}
            onChange={() => this.props.handleIncentiveConfiguration("product_button")}
            labelRight={'Button'}
          />
          <p className="description">
            Click to activate make an offer button on product pages. Don't worry, you get to pick exactly
            which products you want this displayed on in the next steps.
          </p>
          <div className="video_wrapper">
            <video width="100%" height="220" controls id="video_1" onClick={() => this.playVideo()}>
              <source src="/assets/onboarding/Button.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default ButtonStep
