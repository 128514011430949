import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

import ButtonForm from './base/ButtonForm'
import ButtonPreview from './base/ButtonPreview'
import TimedOverlayForm from './base/TimedOverlayForm'
import TimedOverlayPreview from './base/TimedOverlayPreview'
import CartPageNoticeForm from './base/CartPageNoticeForm'
import CartPageNoticePreview from './base/CartPageNoticePreview'
import AdvancedSettingsForm from './base/AdvancedSettingsForm'

import ClassicSetup from './classic/ClassicSetup'
import ClassicSuccessSetup from './classic/ClassicSuccessSetup'
import ClassicAcceptSetup from './classic/ClassicAcceptSetup'
import ClassicCounterSetup from './classic/ClassicCounterSetup'
import ClassicMixedSetup from './classic/ClassicMixedSetup'
import ClassicPreviousResultSetup from './classic/ClassicPreviousResultSetup'
import ClassicRejectSetup from './classic/ClassicRejectSetup'

import MinimalSetup from './minimal/MinimalSetup'
import MinimalSuccessSetup from './minimal/MinimalSuccessSetup'
import MinimalAcceptSetup from './minimal/MinimalAcceptSetup'
import MinimalCounterSetup from './minimal/MinimalCounterSetup'
import MinimalMixedSetup from './minimal/MinimalMixedSetup'
import MinimalPreviousSetup from './minimal/MinimalPreviousSetup'
import MinimalRejectSetup from './minimal/MinimalRejectSetup'

class LookAndFeel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themeComponents: {
        'Classic': ClassicSetup,
        'ClassicSuccess': ClassicSuccessSetup,
        'ClassicAccept': ClassicAcceptSetup,
        'ClassicCounter': ClassicCounterSetup,
        'ClassicMixed': ClassicMixedSetup,
        'ClassicPrevious' :ClassicPreviousResultSetup,
        'ClassicReject': ClassicRejectSetup,
        'Minimal': MinimalSetup,
        'MinimalSuccess': MinimalSuccessSetup,
        'MinimalAccept': MinimalAcceptSetup,
        'MinimalCounter': MinimalCounterSetup,
        'MinimalMixed': MinimalMixedSetup,
        'MinimalPrevious': MinimalPreviousSetup,
        'MinimalReject': MinimalRejectSetup,
      },
      defaultTexts: {
        'Classic': {
          main_heading: I18n.t('themes.classic.main_heading'),
          secondary_heading: I18n.t('themes.classic.secondary_heading'),
          how_it_works: I18n.t('themes.classic.how_it_works'),
          button_submit: I18n.t('themes.classic.button_submit'),
          hint_amount: I18n.t('themes.classic.hint_amount'),
          hint_amount_with_quantity: I18n.t('themes.classic.hint_amount_with_quantity'),
          hint_email: I18n.t('themes.classic.hint_email'),
          hint_phone_number: I18n.t('themes.classic.hint_phone_number'),
          hint_quantity: I18n.t('themes.classic.hint_quantity'),
          hint_confirm_email: I18n.t('themes.classic.hint_confirm_email'),
          hint_notes: I18n.t('themes.classic.hint_notes'),
          hint_customer_name: I18n.t('themes.classic.hint_customer_name'),
          hint_shipping_address: I18n.t('themes.classic.hint_shipping_address'),

          success_heading: I18n.t('themes.classic.success_heading'),
          success_content: I18n.t('themes.classic.success_content'),

          auto_accept_heading: I18n.t('themes.classic.auto_accept_heading'),
          auto_accept_content: I18n.t('themes.classic.auto_accept_content'),
          auto_accept_greeting: I18n.t('themes.classic.auto_accept_greeting'),
          auto_accept_button: I18n.t('themes.classic.auto_accept_button'),
          save_and_continue: I18n.t('themes.classic.save_and_continue'),

          auto_counter_heading: I18n.t('themes.classic.auto_counter_heading'),
          auto_counter_content: I18n.t('themes.classic.auto_counter_content'),
          auto_counter_content_2: I18n.t('themes.classic.auto_counter_content_2'),
          auto_counter_greeting: I18n.t('themes.classic.auto_counter_greeting'),
          auto_counter_button: I18n.t('themes.classic.auto_counter_button'),
          auto_preview_greeting: I18n.t('themes.classic.auto_preview_greeting'),
          auto_preview_button: I18n.t('themes.classic.auto_preview_button'),

          mixed_heading: I18n.t('themes.classic.mixed_heading'),
          mixed_content: I18n.t('themes.classic.mixed_content'),
          mixed_greeting: I18n.t('themes.classic.mixed_greeting'),
          mixed_button: I18n.t('themes.classic.mixed_button'),

          auto_reject_content: I18n.t('themes.classic.auto_reject_content'),
          auto_reject_greeting: I18n.t('themes.classic.auto_reject_greeting'),

        },
        'Minimal': {
          main_heading: I18n.t('themes.minimal.main_heading'),
          secondary_heading: I18n.t('themes.minimal.secondary_heading'),
          how_it_works: I18n.t('themes.minimal.how_it_works'),
          button_submit: I18n.t('themes.minimal.button_submit'),
          hint_amount: I18n.t('themes.minimal.hint_amount'),
          hint_amount_with_quantity: I18n.t('themes.minimal.hint_amount_with_quantity'),
          hint_email: I18n.t('themes.minimal.hint_email'),
          hint_phone_number: I18n.t('themes.minimal.hint_phone_number'),
          hint_quantity: I18n.t('themes.minimal.hint_quantity'),
          hint_confirm_email: I18n.t('themes.minimal.hint_confirm_email'),
          hint_notes: I18n.t('themes.minimal.hint_notes'),
          hint_customer_name: I18n.t('themes.minimal.hint_customer_name'),
          hint_shipping_address: I18n.t('themes.minimal.hint_shipping_address'),

          success_heading: I18n.t('themes.minimal.success_heading'),
          success_content: I18n.t('themes.minimal.success_content'),
          success_greeting: I18n.t('themes.minimal.success_greeting'),
          success_button: I18n.t('themes.minimal.success_button'),
          save_and_continue: I18n.t('themes.classic.save_and_continue'),

          auto_accept_heading: I18n.t('themes.minimal.auto_accept_heading'),
          auto_accept_content: I18n.t('themes.minimal.auto_accept_content'),
          auto_accept_greeting: I18n.t('themes.minimal.auto_accept_greeting'),
          auto_accept_button: I18n.t('themes.minimal.auto_accept_button'),

          auto_counter_heading: I18n.t('themes.minimal.auto_counter_heading'),
          auto_counter_content: I18n.t('themes.minimal.auto_counter_content'),
          auto_counter_content_2: I18n.t('themes.minimal.auto_counter_content_2'),
          auto_counter_greeting: I18n.t('themes.minimal.auto_counter_greeting'),
          auto_counter_button: I18n.t('themes.minimal.auto_counter_button'),
          auto_preview_greeting: I18n.t('themes.classic.auto_preview_greeting'),
          auto_preview_button: I18n.t('themes.classic.auto_preview_button'),

          mixed_heading: I18n.t('themes.minimal.mixed_heading'),
          mixed_content: I18n.t('themes.minimal.mixed_content'),
          mixed_greeting: I18n.t('themes.minimal.mixed_greeting'),
          mixed_button: I18n.t('themes.minimal.mixed_button'),

          auto_reject_content: I18n.t('themes.minimal.auto_reject_content'),
          auto_reject_greeting: I18n.t('themes.minimal.auto_reject_greeting'),
        }
      }
    }
  }

  popupSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName]
    return <ComponentName data={this.props}
      themeSettings={settings}
      defaultTexts={this.state.defaultTexts[themeName]}/>
  }

  themeSelector() {
    return (
      <div className="col-sm-8 col-md-6">
        <form className="form-inline theme_choose">
          <div className="form-group">
            <label>
              {I18n.t("admin.look_and_feel.choose_theme")}
            </label>
            <select className="form-control"
              value={this.props.userSetting.current_theme.id}
              onChange={(e) => this.props.handleThemeChange(e)}>
              {this.props.themes.map(function (theme) {
                return <option key={theme.id} value={theme.id}>{theme.name}</option>
              })}
            </select>
          </div>
        </form>
      </div>
    )
  }

  successSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Success']
    return <ComponentName data={this.props}
      themeSettings={settings}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  autoAcceptSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Accept']
    return <ComponentName data={this.props}
      themeSettings={settings}
      flipperFlags={this.props.flipperFlags}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  autoRejectSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Reject']
    return <ComponentName data={this.props}
      themeSettings={settings}
      flipperFlags={this.props.flipperFlags}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  counterSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Counter']
    return <ComponentName data={this.props}
      themeSettings={settings}
      flipperFlags={this.props.flipperFlags}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  previousSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Previous']
    return <ComponentName data={this.props}
      themeSettings={settings}
      flipperFlags={this.props.flipperFlags}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  mixedSection() {
    var currentTheme = this.props.userSetting.current_theme
    var themeName = currentTheme.name || 'Classic'
    let settings = this.props.themeSettings.find((s) => s.theme_id == currentTheme.id)
    var ComponentName = this.state.themeComponents[themeName + 'Mixed']
    return <ComponentName data={this.props}
      themeSettings={settings}
      defaultTexts={this.state.defaultTexts[themeName]} />
  }

  render() {
    return (
      <div className="row">
        {this.themeSelector()}
        <div className="col-sm-12 button-form">
          {this.popupSection()}
        </div>
        <div className="col-sm-12 button-form">
          {this.successSection()}
        </div>
        <div className="col-sm-12 button-form">
          {this.autoAcceptSection()}
        </div>
        <div className="col-sm-12 button-form">
          {this.counterSection()}
        </div>
        <div className="col-sm-12 button-form">
          {this.previousSection()}
        </div>
        <div className="col-sm-12 button-form">
          {this.mixedSection()}
        </div> 

        <div className="col-sm-12 button-form">
          {this.autoRejectSection()}
        </div>
       
        <div className="col-sm-12 button-form">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h1 id="customize-button">
                {I18n.t("admin.look_and_feel.button.heading")}
              </h1>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-3">
                  <ButtonForm userSetting             = {this.props.userSetting}
                    handleButtonFontColorChange       = {(color) => this.props.handleButtonFontColorChange(color)}
                    handleButtonBackgroundColorChange = {(color) => this.props.handleButtonBackgroundColorChange(color)}
                    handleButtonTextChange            = {(e) => this.props.handleButtonTextChange(e)} />
                </div>
                <div className="col-sm-4 col-md-offset-4 preview-pane" >
                  <ButtonPreview userSetting={this.props.userSetting} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 timed-overlay">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h1 id="customize-timed_overlay">
                {I18n.t("admin.look_and_feel.timed_overlay.heading")}
              </h1>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-3">
                  <TimedOverlayForm userSetting             = {this.props.userSetting}
                    flipperFlags                            = {this.props.flipperFlags}
                    timerDelayTypes                         = {this.props.timerDelayTypes}
                    handleTimedOverlayFontColorChange       = {(color) => this.props.handleTimedOverlayFontColorChange(color)}
                    handleTimedOverlayBackgroundColorChange = {(e) => this.props.handleTimedOverlayBackgroundColorChange(e)}
                    handleTimedOverlayOpacity               = {(e) => this.props.handleTimedOverlayOpacity(e)}
                    handleTimedOverlayPosition              = {(e) => this.props.handleTimedOverlayPosition(e)}
                    handleTimedButtonTextChange             = {(e) => this.props.handleTimedButtonTextChange(e)}
                    handleTimedOverlayTextChange            = {(e) => this.props.handleTimedOverlayTextChange(e)}
                    handleTimedOverlayDelay                 = {(e) => this.props.handleTimedOverlayDelay(e)} />
                </div>
                <div className="col-sm-4 col-md-offset-4 preview-pane" >
                  <TimedOverlayPreview userSetting={this.props.userSetting} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 look-and-feel__cart-page-notice">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h1 id="look_and_feel_cart_page_notice_heading">
                {I18n.t("admin.look_and_feel.cart_page_notice.heading")}
              </h1>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-3">
                  <CartPageNoticeForm userSetting             = {this.props.userSetting}
                    handleCartPageNoticeTextChange            = {(e) => this.props.handleCartPageNoticeTextChange(e)}
                    handleCartPageNoticeColorChange           = {(color) => this.props.handleCartPageNoticeColorChange(color)}
                    handleCartPageNoticeBackgroundColorChange = {(color) => this.props.handleCartPageNoticeBackgroundColorChange(color)}
                    handleCartPageNoticeCssChange             = {(e) => this.props.handleCartPageNoticeCssChange(e)} />
                </div>
                <div className="col-sm-4 col-md-offset-4 preview-pane" >
                  <CartPageNoticePreview userSetting={this.props.userSetting} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 button-form">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h1 id="customize-button">
                {I18n.t("admin.look_and_feel.advanced_settings.heading")}
              </h1>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-md-6">
                  <AdvancedSettingsForm userSetting   = {this.props.userSetting}
                    handleModalCssChange              = {(e) => this.props.handleModalCssChange(e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LookAndFeel.propTypes = {

}

export default LookAndFeel
