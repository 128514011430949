import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

import MinimalPreview from './MinimalPreview'
import MinimalPopupPreview from './MinimalPopupPreview'
import MinimalForm from './MinimalForm'

class MinimalSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultColors: {
        mainHeading: '#F4F4F4',
        secondaryHeading: '#F4F4F4',
        background: '#2F9683',
        buttonFont: '#5A5A5A',
        buttonBackground: '#FFCF64'
      }
    }
  }

  preview () {
    if (this.props.themeSettings.data.use_sidebar) {
      return <MinimalPreview defaultTexts={this.props.defaultTexts}
        userSetting={this.props.data.userSetting}
        themeSettings={this.props.themeSettings}
        extraFeatures={this.props.data.extraFeatures}
        colors={this.state.defaultColors}/>
    } else {
      return <MinimalPopupPreview defaultTexts={this.props.defaultTexts}
        userSetting={this.props.data.userSetting}
        themeSettings={this.props.themeSettings}
        extraFeatures={this.props.data.extraFeatures}
        colors={this.state.defaultColors}/>
    }
  }

  render() {
    return (
      <div className="ibox float-e-margins">
        <div className="ibox-title" >
          <h1 id="customize-popup">
            {I18n.t("admin.look_and_feel.exit_offer.heading")}
          </h1>
        </div>
        <div className="ibox-content">
          <div className="row">
            <div className="col-sm-3">
              <MinimalForm defaultTexts={this.props.defaultTexts}
                userSetting={this.props.data.userSetting}
                themeSettings={this.props.themeSettings}
                extraFeatures={this.props.data.extraFeatures}
                handleThemeSettingChange={(e, field) => this.props.data.handleThemeSettingChange(e, field)}
                handlePrivatePolicyLinkChange={(e) => this.props.data.handlePrivatePolicyLinkChange(e)}
                handleTermsOfServiceLinkChange={(e) => this.props.data.handleTermsOfServiceLinkChange(e)}
                handleCustomTermsTextChange={(e) => this.props.data.handleCustomTermsTextChange(e)}
                handleGoogleAnalyticeCode={(e) => this.props.data.handleGoogleAnalyticeCode(e)}
                handleOfferButtonFont={(e) => this.props.data.handleOfferButtonFont(e)}
                handleOfferTitleFont={(e) => this.props.data.handleOfferTitleFont(e)}
                handleOfferSubTitleFont={(e) => this.props.data.handleOfferSubTitleFont(e)}
                handleOfferLabelsFont={(e) => this.props.data.handleOfferLabelsFont(e)}
                colors={this.state.defaultColors}/>
            </div>
            <div className="col-sm-8 col-md-offset-1 preview-pane preview-pane-minimal-theme" >
              {this.preview()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MinimalSetup
