import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class TimedOverlayPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  overlayStyle() {
    let commonStyles = {
      backgroundColor: this.props.userSetting.timed_overlay_background_color,
      color: this.props.userSetting.timed_overlay_font_color,
      opacity: this.props.userSetting.timed_overlay_opacity
    }
    let position = this.props.userSetting.timed_overlay_position == 'top' ? { top: 0 } : { bottom: 0 }
    return Object.assign(commonStyles, position)
  }

  closeStyle() {
    return {
      color: this.props.userSetting.timed_overlay_font_color
    }
  }

  buttonStyle() {
    return {
      color: this.props.userSetting.timed_overlay_font_color,
      border: "1px solid " + this.props.userSetting.timed_overlay_font_color
    };
  }

  buttonText () {
    return this.props.userSetting.timed_overlay_button_text || I18n.t("offer_popup.timed_overlay_button")
  }

  render() {
    return (
      <div id="mf_timed_overlay" style={this.overlayStyle()}>
        <span className="mf_timed_overlay_text">
          {this.props.userSetting.timed_overlay_text}
        </span>
        <a className="timedOverlayTrigger" href="#" style={this.buttonStyle()}>{this.buttonText()}</a>
        <button type="button" className="close" style={this.closeStyle()} aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }
}

export default TimedOverlayPreview
