import { h } from 'preact'
import React from "react"

class Header extends React.Component {
  render () {
    return (
      <div className={this.props.greeting ? 'tour_header_greeting' : 'tour_header'}>
        <div className="title_box">
          <h1 className={this.props.additionalClass}>
            {this.props.title}
          </h1>
        </div>
      </div>
    );
  }
}

export default Header
