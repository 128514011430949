import { h } from 'preact'
import React from "react"
import AskForCombine from './AskForCombine'
import OffersHistory from './OffersHistory'
import OfferModal from './OfferModal'
import ReviewRequest from './ReviewRequest'

class Offer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      offer: this.props.offer,
      showCounterModal: false,
      showModal: false,
      askForCombine: false,
      offersHistory: false,
      showReviewRequest: false,
      combinedOffers: [],
      historyOffers: [],
      showMoreInfo: false,
      productInfo: null,
      moreInfoError: null,
      orderLink: null
    }
  }

  // Offer Actions

  acceptOffer = (params) => {
    this.setState({ isLoading: true });
    var path = this._baseUrl() + "/accept";
    this.hideModal();
    this._update(
      path,
      params,
      // Success
      resp => {
        if (resp.status === "success") {
          // API responds with updated offer
          this.props.showAlert("The customer will be notified soon", "info");
          this.setState({
            offer: resp.offer,
            isLoading: false,
            showModal: false
          });
          this.showReviewRequest();
        } else {
          this._showErrorMsg(resp.msg);
          this.setState({isLoading: false})
        }
      },
      // Error
      err => {
        this._showErrorMsg();
        this.setState({ isLoading: false });
      }
    );
  }

  counterOffer = (params) => {
    this.setState({ isLoading: true });
    var path = this._baseUrl() + "/counter";
    this.hideModal();
    this._update(
      path,
      params,
      // Success
      resp => {
        if (resp.status === "success") {
          // API responds with updated offer
          this.props.showAlert("The customer will be notified soon", "info");
          this.setState({offer: resp.offer, isLoading: false, showModal: false});
        } else {
          this._showErrorMsg(resp.msg);
          this.setState({isLoading: false})
        }
      },
      // Error
      err => {
        this._showErrorMsg();
        this.setState({ isLoading: false });
      }
    );
  }

  declineOffer = (params) => {
    this.setState({ isLoading: true });
    var path = this._baseUrl() + "/decline";
    this.hideModal();
    this._update(
      path,
      params,
      // Success
      resp => {
        // API responds with updated offer
        this.props.showAlert("The customer will be notified soon", "info");
        this.setState({ offer: resp, isLoading: false, showModal: false });
      },
      // Error
      err => {
        this._showErrorMsg();
        this.setState({ isLoading: false });
      }
    );
  }

  cancelOffer = () => {
    this.setState({ isLoading: true });
    var path = this._baseUrl() + "/cancel";
    this.hideModal();
    this._update(
      path,
      {},
      // Success
      resp => {
        // API responds with updated offer
        this.props.showAlert("The order was deleted", "info");
        this.setState({ offer: resp, isLoading: false, showModal: false });
      },
      // Error
      err => {
        this._showErrorMsg();
        this.setState({ isLoading: false });
      }
    );
  }

  hideOffer = () => {
    if(confirm("Are you sure you want to hide this offer?")) {
      this.setState({ isLoading: true });
      this._update(
        this._baseUrl(),
        { offer: { status: "deleted" } },
        resp => {
          this.props.showAlert("Offer Hidden From Archive Section", "danger");
          this.setState({ isLoading: false });
          this.props.handleArchiveOffer(this.state.offer);
        },
        error => {
          this.setState({ isLoading: false });
          this._showErrorMsg();
          console.error(path, status, error.toString());
        }
      );
    }
  }

  archiveOffer = () => {
    this.setState({ isLoading: true });
    this._update(
      this._baseUrl(),
      { offer: { archive: true, archived_at: new Date().toGMTString() } },
      resp => {
        this.setState({ isLoading: false });
        this.props.handleArchiveOffer(this.state.offer);
      },
      error => {
        this.setState({ isLoading: false });
        this._showErrorMsg();
      }
    );
  }
  
  unarchiveOffer = () => {
    this.setState({ isLoading: true });
    this._update(
      this._baseUrl(),
      { offer: { archive: false, archived_at: "" } },
      resp => {
        this.setState({ isLoading: false });
        this.props.handleArchiveOffer(this.state.offer);
      },
      error => {
        this.setState({ isLoading: false });
        this._showErrorMsg();
      }
    );
  }

  toManual = () => {
    this.setState({ isLoading: true });
    this._update(
      this._baseUrl(),
      { offer: { offer_type: 'manual', status: 'new' } },
      resp => {
        this.setState({ isLoading: false });
        this.props.showActiveOffers();
      },
      error => {
        this.setState({ isLoading: false });
        this._showErrorMsg();
      }
    );
  }

  _update(path = null, data, success = null, error = null) {
    var defaults = { _method: "PUT" };
    path = path === null ? this._baseUrl() : path;

    $.ajax({
      url: path,
      dataType: "json",
      type: "POST",
      data: Object.assign(defaults, data),
      success: success,
      error(xhr, status, err) {
        console.error(path, status, err.toString());
        if (error) {
          error();
        }
      }
    });
  }

  _baseUrl() {
    return this.props.url + "/" + this.state.offer.id;
  }

  getVariant (variantId) {
    let that = this
    $.ajax({
      url: '/variant/' + variantId,
      dataType: "json",
      type: "GET",
      data: { user_id: this.state.offer.product.user_id },
      success: (data) => {
        that.setState({ productInfo: data.variant })
        that.setState({ orderLink: data.link + that.state.offer.order_id })
      },
      error: (xhr, status, err) => {
        that.setState({ moreInfoError: xhr.responseJSON.msg })
        console.error(status, err.toString());
      }
    });
  }

  // View methods

  showCounterModal() {
    if (supportsFeature("combine_offers", this.props.extraFeatures, true, false)) {
      var currentOffer = this.state.offer
      this._getCombindeOffers(
        currentOffer.email,
        succ => {
          if (succ.offers.length > 1) {
            this.setState({ askForCombine: true, combinedOffers: succ.offers, modalType: "counter" });
          } else {
            this.setState({ showModal: true, modalType: "counter" });
          }
        }
      )
    } else {
      this.setState({ showModal: true, modalType: "counter" });
    }
  }

  showAcceptModal() {
    if (supportsFeature("combine_offers", this.props.extraFeatures, true, false)) {
      var currentOffer = this.state.offer
      this._getCombindeOffers(
        currentOffer.email,
        succ => {
          if (succ.offers.length > 1) {
            this.setState({ askForCombine: true, combinedOffers: succ.offers, modalType: "accept" });
          } else {
            this.setState({ showModal: true, modalType: "accept" });
          }
        }
      )
    } else {
      this.setState({ showModal: true, modalType: "accept" });
    }
  }

  showHistroyModal() {
    var currentOffer = this.state.offer
    this._getHistroyOffers(
      currentOffer.email,
      succ => {
        this.setState({ offersHistory: true, historyOffers: succ.offers });
      }
    )
  }

  _getHistroyOffers (email, success) {
    $.ajax({
      url: this.props.historyOffersUrl,
      data: {
        email: email
      },
      dataType: "json",
      success: (data) => {
        this.setState({ historyOffers: data.offers });
        success(data);
      },
      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString());
      }
    });
  }

  _getCombindeOffers (email, success) {
    $.ajax({
      url: this.props.combineOffersUrl,
      data: {
        offerType: 'new',
        email: email
      },
      dataType: "json",
      success: (data) => {
        this.setState({ combinedOffers: data.offers });
        success(data);
      },
      error: (xhr, status, err) => {
        console.error(this.props.url, status, err.toString());
      }
    });
  }

  showDeclineModal() {
    this.setState({ showModal: true, modalType: "decline" });
  }

  hideModal = () => {
    this.setState({ showModal: false, askForCombine: false , offersHistory: false});
  }

  _showErrorMsg(msg = "Something went wrong. Please try again.") {
    this.props.showAlert(msg, "danger");
  }

  _onSuccess(msg) {
    this.props.showAlert(msg, "info");
  }

  _hideModalAndShowError() {
    this.hideModal();
    this._showErrorMsg();
    this.setState({ isLoading: false });
  }

  modal() {
    var modalType = this.state.modalType;
    var submitForm = null;

    if (modalType === "accept") {
      submitForm = this.acceptOffer;
    } else if (modalType === "counter") {
      submitForm = this.counterOffer;
    } else if (modalType === "decline") {
      submitForm = this.declineOffer;
    }
    if (this.state.showModal) {
      return <OfferModal offer={this.state.offer}
                         userSetting={this.props.userSetting}
                         action={modalType}
                         onCloseModal={this.hideModal}
                         submitForm={submitForm}
                         initialUserEmail={this.props.initialUserEmail}
                         initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}/>
    } else {
      return "";
    }
  }

  declineCombine = () => {
    this.setState({ showModal: true, askForCombine: true, modalType: this.state.modalType });
  }

  askForCombine () {
    if (this.state.askForCombine) {
      return <AskForCombine offer={this.state.offer}
        offers={this.state.combinedOffers}
        userSetting={this.props.userSetting}
        declineCombine={this.declineCombine}
        updateOffers={this.props.updateOffers}
        initialUserEmail={this.props.initialUserEmail}
        initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
        onCloseModal={this.hideModal} />
    } else {
      return "";
    }
  }

  offersHistory () {
    if (this.state.offersHistory) {
      return <OffersHistory offer={this.state.offer}
        offers={this.state.historyOffers}
        onCloseModal={this.hideModal} />
    } else {
      return "";
    }
  }

  showReviewRequest = () => {
    var path = window.location.origin + "/review_request"
    const that = this;
    $.ajax({
      url: path,
      dataType: "json",
      type: "GET",
      success(data) {
        if (data['show_request'] === true) {
          that.setState({ showReviewRequest: true });
        }
      },
      error(xhr, status, err) {
        console.error(path, status, err.toString());
      }
    });
  }

  reviewRequest() {
    if (this.state.showReviewRequest) {
      return <ReviewRequest offerId = {this.props.offer.id}/>;
    } else {
      return "";
    }
  }

  multiplySelectMode(on, off) {
    return this.props.multiplySelect ? on : off
  }

  filterMultiplyOffers() {
    this.props.filterMultiplyOffers(this.state.offer);
  }

  labelStatusClass () {
    status = this.state.offer.status
    if (status == 'new') {
      return 'label label-succsses'
    } else if (status == 'accepted') {
      return 'label label-primary'
    } else if (status == 'countered') {
      return 'label label-warning'
    } else if (status == 'declined') {
      return 'label label-danger'
    }
  }

  showMoreInfoPopver () {
    this.setState({ showMoreInfo: !this.state.showMoreInfo })
    if (!this.state.productInfo) {
      this.getVariant(this.state.offer.product.variant)
    }
  }

  closeMoreInfo () {
    this.setState({ showMoreInfo: false })
  }

  orderLink () {
    return <li><b>Order: </b><a href={this.state.orderLink} target="_blank">{this.state.offer.order_id}</a></li>
  }

  moreInfoPopover () {
    if (this.state.showMoreInfo) {
      return (
        <div className="popover more-info-popover" onClick={() => this.closeMoreInfo}>
          <div className="arrow"></div>
          <h3 className="popover-header">More Info:</h3>
          <div className="popover-body">
            {this.state.productInfo ?
            <ul>
              {this.state.offer.order_id && this.orderLink()}
              <li><b>Variant id: </b>{this.state.productInfo.id}</li>
              <li><b>Sku: </b>{this.state.productInfo.sku}</li>
              <li><b>Taxable: </b>{this.state.productInfo.taxable.toString()}</li>
              <li><b>Inventory Quantity: </b>{this.state.productInfo.inventory_quantity}</li>
              {(this.state.productInfo.option1) ? <li><b>Option 1: </b>{this.state.productInfo.option1}</li> : null}
              {(this.state.productInfo.option2) ? <li><b>Option 2: </b>{this.state.productInfo.option2}</li> : null}
              {(this.state.productInfo.option3) ? <li><b>Option 3: </b>{this.state.productInfo.option3}</li> : null}
              {(this.state.productInfo.option4) ? <li><b>Option 4: </b>{this.state.productInfo.option4}</li> : null}
              {(this.state.productInfo.option5) ? <li><b>Option 5: </b>{this.state.productInfo.option5}</li> : null}
            </ul> :
            (this.state.moreInfoError ? <p className="popover-error">{this.state.moreInfoError}</p> : <i className="fa fa-circle-o-notch fa-spin loader"></i>) }
          </div>
        </div>
      )
    }
  }

  render() {
    var offer = this.state.offer;
    var product = "";

    if (offer.product) {
      var product = (
        <div className="product-cell" onMouseLeave={() => this.closeMoreInfo()}>
          <i className="fa fa-info-circle more-info" onMouseEnter={() => this.showMoreInfoPopver()}></i>
          {/* <span className="glyphicon glyphicon-info-sign more-info"
            onMouseEnter={() => this.showMoreInfoPopver()}></span> */}
          {this.moreInfoPopover()}
          <a target="_blank" href={offer.product.url}>
            {offer.product.title}
          </a>
        </div>
      );
    }

    return (
      <>
        { offer.status !== 'deleted' &&
          <tr key={offer.id} className="offer" id={'offer-' + offer.id}>
            <td>
              {offer.created_at}{" "}
            </td>
            <td>
              <a onClick={() => this.showHistroyModal()}>
              {offer.email}{" "}
              </a>
            </td>
            <td>
              {product}
            </td>
            <td className="text-left">
              {offer.currency_iso}
            </td>
            <td className="text-left">
              {offer.product.price}
            </td>
            <td className="text-left">
              {offer.amount_with_currency}
            </td>
            <td className="text-left">
              {offer.counter_price}
            </td>
            { this.props.themeSetting.data.show_quantity ?
              <td className="text-left">
                {offer.quantity}
              </td> : null
            }
            {this.props.themeSetting.data.show_name ?
              <td>
                {offer.customer_name}
              </td> : null
            }
            <td>
              {offer.product.sku}
            </td>
            {this.props.themeSetting.data.show_phone_number ?
              <td>
                {offer.phone_number}
              </td> : null
            }
            <td>
              {offer.notes_to_seller}{" "}
            </td>
            <td>{offer.shipping_address ? offer.shipping_address : "-"}</td>
            <td className="capitalize row_bg_color">
              {/* <span className={this.labelStatusClass()}>{offer.status}</span> */}
              {offer.status  == 'auto_declined' ? 'Auto Declined' : offer.status}
            </td>
            {this.multiplySelectMode(
              null,
              <td className="action-group-button">
                {this.buttons()}
                {this.modal()}
                {this.reviewRequest()}
                {this.askForCombine()}
                {this.offersHistory()}
              </td>)}
          </tr>
        }
      </>
    );
  }

  cancelButton(offer) {
    if (offer.order_status == 'pending') {
      return (
        <button
          type="button"
          className="btn btn-danger"
          onClick={this.cancelOffer}
        >
          Cancel
        </button>
      )
    }
  }

  toManualButton(offer) {
    if (offer.offer_type == "auto_checkout") {
      return (
        <button
          type="button"
          className="btn btn-warning"
          onClick={this.toManual}
        >
          To Manual
        </button>
      )
    }
  }

  buttons() {
    var offer = this.state.offer;
    if (this.state.isLoading === true) {
      return <i className="fa fa-circle-o-notch fa-spin loader"> </i>;
    } else if (offer.status == "new" && !offer.archive && (offer.product.variant || offer.product.product_id)) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary new"
            onClick={() => this.showAcceptModal()}
          >
            Accept
          </button>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => this.showCounterModal()}
          >
            Counter
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => this.showDeclineModal()}
          >
            Decline
          </button>
          <button
            type="button"
            className="btn btn-primary archive"
            onClick={() => this.archiveOffer()}
          >
            <i className="fa fa-archive" />
          </button>
        </div>
      );
    } else if (this.props.hideArchiveButton === false) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary archive"
            onClick={() => this.archiveOffer()}
          >
            Archive
          </button>
          {this.cancelButton(offer)}
          {this.toManualButton(offer)}
        </div>
      );
    } else {
       return (
        <div>
          <button
            type="button"
            className="btn btn-primary archive"
            onClick={() => this.unarchiveOffer()}
          >
            UnArchive
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => this.hideOffer()}
          >
            Hide
          </button>
        </div>
      );

    }
  }
}

export default Offer;
