import React from "react"

class AlertNotification extends React.Component {
  render() {
    return (
      <div className={"alert alert-" + this.props.alertType + " alert-dismissible"} role="alert">
        <button type="button" className="close" onClick={(e) => this.props.handleClick(e)} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {this.props.alertMessage}
      </div>
    );
  }
}

export default AlertNotification
