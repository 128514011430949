import React, { Component } from "react";

class SelectFontOption extends Component {
  render () {
    const {googleFonts, selectedValue} = this.props
    return (
      <>
        <select 
          className="form-control" 
          onChange={this.props.handleOnChange}
          value={selectedValue}
        >
          <option value={''}> Select font family</option>
          {googleFonts && googleFonts.sort((a, b) => a.name.localeCompare(b.name)).map(option => (
            <option key={option.name} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </>
    )
  }
}

export default SelectFontOption
