import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import Help from 'components/base/Help'

class MinimalMixedForm extends React.Component {
  constructor(props) {
    super(props);
  }

  content () {
    return (<div className="form-group">
      <label className="color-label">Content:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.mixed_content || this.props.defaultTexts.mixed_content}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'mixed_content')}
        className="form-control"
      />
    </div>)
  }

  heading () {
    return (<div className="form-group">
      <label className="color-label">Heading:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.mixed_heading || this.props.defaultTexts.mixed_heading}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'mixed_heading')}
        className="form-control"
      />
    </div>)
  }

  greeting () {
    return (<div className="form-group">
      <label className="color-label">Greeting:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.mixed_greeting || this.props.defaultTexts.mixed_greeting}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'mixed_greeting')}
        className="form-control"
      />
    </div>)
  }

  button () {
    return (<div className="form-group">
      <label className="color-label">Button:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.mixed_button || this.props.defaultTexts.mixed_button}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'mixed_button')}
        className="form-control"
      />
    </div>)
  }

  render () {
    return (
      <div className="user-setting-form">
        {this.greeting()}
        {this.heading()}
        {this.content()}
        {this.button()}
      </div>
    );
  }
}

export default MinimalMixedForm
