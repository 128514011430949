import React from "react"
import PropTypes from "prop-types"
import AlertNotification from '../base/AlertNotification'

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertType: "",
      subMenu: "configuration",
      setting: this.props.initialUser
    }
  }

  handleTextChange(e) {
    this.state.setting[e.target.name] = e.target.value;
    this.setState({ setting: this.state.setting });
  }

  handleClick(e) {
    this.setState({ alertMessage: "" })
  }

  handleSubmitButton() {
    $.ajax({
      url: this.props.url,
      dataType: 'json',
      type: "POST",
      data: {  _method: (this.state.setting.id ? 'PUT' : 'POST'), user: this.state.setting },
      success: (data) => {
        this.setState({alertMessage: "You've successfully saved the settings", alertType: "success"})
        if(!this.state.setting.id){
          $('.nav-tabs a[href="#settings"]').tab('show');
        }
        if(data)
          this.setState({setting: data});
      },
      error: (xhr, status, err) => {
        var resp = JSON.parse(xhr.responseText);
        this.setState({alertMessage: resp.errors.join(',\n'), alertType: "danger"})
        console.error(this.props.url, status, err.toString());
      }
    });
  }

  render() {
    var notification = <AlertNotification
      handleClick={() => this.handleClick()}
      alertMessage={this.state.alertMessage}
      alertType={this.state.alertType} />
     return (
      <div id="account-settings" className="setting row">

        <div className="col-sm-12">
          {this.state.alertMessage && this.state.alertMessage !== "" ? notification : ""}
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <label>Name:</label>
            <input type="text" className="form-control" name='name' value={this.state.setting.name} onChange={(e) => this.handleTextChange(e)} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" className="form-control" name='email' value={this.state.setting.email} onChange={(e) => this.handleTextChange(e)} />
          </div>
          <div className="form-group">
            <label>Secondary email:</label>
            <input type="email" className="form-control"
              name='secondary_email'
              value={this.state.setting.secondary_email || ''}
              onChange={(e) => this.handleTextChange(e)} />
          </div>
        </div>

        <div className="col-sm-12">
          <div className="form-group">
            <div className="actions">
              <button onClick={() => this.handleSubmitButton()} className="btn btn-primary">Save Settings</button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Settings
