import { h } from 'preact'
import React from "react"
import Header from '../Header'
import SwitchButton from '../../base/SwitchButton'

class ExitOfferCartStep extends React.Component {
  playVideo () {
    var myVideo = document.getElementById("video_2");
    if (myVideo.paused) {
      myVideo.play();
    }
    else {
      myVideo.pause();
    }
  }

  render () {
    return (
      <div className="content">
        <Header title="Choose Your Incentive Options" />
        <div className="step_content">
          <SwitchButton name='windowshopper-cart_exit_offer'
            checked={this.props.userSetting.cart_exit_offer}
            onChange={() => this.props.handleIncentiveConfiguration("cart_exit_offer") }
            labelRight={'Exit Offer - Cart page'}
          />
          <p className="description">
            This option will activate an exit popup on the cart page.
            It's activated only when the customer tries to leave the cart page (to fight cart abandonment).
          </p>
          <div className="video_wrapper">
            <video width="100%" height="220" controls autoPlay id="video_2" onClick={() => this.playVideo()}>
              <source src="/assets/onboarding/Exit_Offer_Cart_Page.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default ExitOfferCartStep
