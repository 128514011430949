import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import Help from 'components/base/Help'

class MinimalSuccessForm extends React.Component {
  constructor(props) {
    super(props);
  }

  successContent () {
    return (<div className="form-group">
      <label className="color-label">Success Content:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.success_content || this.props.defaultTexts.success_content}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'success_content')}
        className="form-control"
      />
    </div>)
  }

  successSubContent () {
    return (<div className="form-group">
      <label className="color-label">Success Sub Content:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.success_greeting || this.props.defaultTexts.success_greeting}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'success_greeting')}
        className="form-control"
      />
    </div>)
  }

  successHeading () {
    return (<div className="form-group">
      <label className="color-label">Success Heading:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.success_heading || this.props.defaultTexts.success_heading}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'success_heading')}
        className="form-control"
      />
    </div>)
  }

  successButton () {
    return (<div className="form-group">
      <label className="color-label">Success Button:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.success_button || this.props.defaultTexts.success_button}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'success_button')}
        className="form-control"
      />
    </div>)
  }

  render () {
    return (
      <div className="user-setting-form">
        {this.successHeading()}
        {this.successContent()}
        {this.successSubContent()}
        {this.successButton()}
      </div>
    );
  }
}

export default MinimalSuccessForm
