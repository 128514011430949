import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalAcceptPopupPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background,
      display: 'block',
      borderRadius: '10px'
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  heading () {
    return this.props.themeSettings.data.auto_accept_heading || this.props.defaultTexts.auto_accept_heading;
  }

  content () {
    return this.props.themeSettings.data.auto_accept_content || this.props.defaultTexts.auto_accept_content;
  }

  greeting () {
    return this.props.themeSettings.data.auto_accept_greeting || this.props.defaultTexts.auto_accept_greeting;
  }

  button () {
    return this.props.themeSettings.data.auto_accept_button || this.props.defaultTexts.auto_accept_button;
  }

  buttonSaveAndContinue () {
    return this.props.themeSettings.data.save_and_continue || this.props.defaultTexts.save_and_continue;
  }

  buttonGroup () {
    if (this.props.flipperFlags.customer_combine) {
      return (<div className="button-block">
        <a style={this.divButtonStyle()}>
          {this.buttonSaveAndContinue()}
        </a>
        <a style={this.divButtonStyle()}>
          {this.button()}
        </a>
      </div>)
    } else {
      return (<a className="submit-button" style={this.divButtonStyle()}>
        {this.button()}
      </a>)
    }
  }

  render () {
    return (
      <div className="container mf_popup_container minimal">
        <div className="popup">
          <a className="close close_iframe" style={{ opacity: '1' }}>×</a>
          <div className="mf_response_container" style={this.divHeaderStyle()}>
            <div className="success_container" style={this.divHeaderStyle()}>
              <div className="success response_block" style={this.divHeaderStyle()}>
                <h4>{this.greeting()}</h4>
                <h4>{this.heading()}</h4>
                <h4>{this.content()}</h4>
                {this.buttonGroup()}
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalAcceptPopupPreview
