import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalPreviousPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  greeting () {
    return this.props.themeSettings.data.auto_preview_greeting || this.props.defaultTexts.auto_preview_greeting;
  }

  button () {
    return this.props.themeSettings.data.auto_preview_button || this.props.defaultTexts.auto_preview_button;
  }

  

  previousResultText () {
    return this.props.themeSettings.data.previous_result_text ;
       
  }

  render () {
    return (
      <div className="container mf_sidebar_container minimal">
        <div className="mf_container">
          <div className="success_container" style={{ display: 'block' }}>
            <a className="close close_iframe" style={{ opacity: '1', color: '#F7F7F7' }}>×</a>
            <div className="auto_preview response_block" style={this.divHeaderStyle()}>
              <h4>{this.greeting()}</h4>
              <h4>{this.previousResultText()}</h4>
              <a className="submit-button close_iframe" style={this.divButtonStyle()}>
                {this.button()}
              </a>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalPreviousPreview