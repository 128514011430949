import React from "react"
import PropTypes from "prop-types"

class AcceptPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  heading () {
    return this.props.themeSettings.data.auto_accept_heading || this.props.defaultTexts.auto_accept_heading;
  }

  content () {
    return this.props.themeSettings.data.auto_accept_content || this.props.defaultTexts.auto_accept_content;
  }

  greeting () {
    return this.props.themeSettings.data.auto_accept_greeting || this.props.defaultTexts.auto_accept_greeting;
  }

  button () {
    return this.props.themeSettings.data.auto_accept_button || this.props.defaultTexts.auto_accept_button;
  }

  buttonSaveAndContinue () {
    return this.props.themeSettings.data.save_and_continue || this.props.defaultTexts.save_and_continue;
  }

  buttonGroup() {
    if (this.props.flipperFlags.customer_combine) {
      return (<div className="button-block">
        <a style={this.divButtonStyle()}>
          {this.buttonSaveAndContinue()}
        </a>
        <a style={this.divButtonStyle()}>
          {this.button()}
        </a>
      </div>)
    } else {
      return (<a className="submit-button" style={this.divButtonStyle()}>
        {this.button()}
      </a>)
    }
  }

  render () {
    return (
      <div className="mf_modal">
        <div className="modal-content popup_with_notes">
          <div className="modal-header" style={this.divHeaderStyle()}>
            <button
              type="button"
              className="close"
              data-dismiss="mf_modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={this.secondaryHeadingTitleStyle()}>×</span>
            </button>
            <div className="modal-title">
            </div>
          </div>
          <div className="modal-body">
            <div className="success_container" style={{ display: 'block' }}>
              <div className="accepted">
                <h5>{this.greeting()}</h5>
                <p>{this.heading()}</p>
                <p>{this.content()}</p>
                {this.buttonGroup()}
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default AcceptPreview
