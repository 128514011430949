import React from "react"
import PropTypes from "prop-types"

class CounterForm extends React.Component {
  constructor(props) {
    super(props);
  }

  content () {
    return (<div className="form-group">
      <label className="color-label">Content:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_counter_content || this.props.defaultTexts.auto_counter_content}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_counter_content')}
        className="form-control"
      />
    </div>)
  }

  content_2 () {
    return (<div className="form-group">
      <label className="color-label">Additional Content:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_counter_content_2 || this.props.defaultTexts.auto_counter_content_2}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_counter_content_2')}
        className="form-control"
      />
    </div>)
  }

  heading () {
    return (<div className="form-group">
      <label className="color-label">Heading:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_counter_heading || this.props.defaultTexts.auto_counter_heading}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_counter_heading')}
        className="form-control"
      />
    </div>)
  }

  greeting () {
    return (<div className="form-group">
      <label className="color-label">Greeting:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_counter_greeting || this.props.defaultTexts.auto_counter_greeting}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_counter_greeting')}
        className="form-control"
      />
    </div>)
  }

  button () {
    return (<div className="form-group">
      <label className="color-label">Button:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_counter_button || this.props.defaultTexts.auto_counter_button}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_counter_button')}
        className="form-control"
      />
    </div>)
  }

  buttonSaveAndShopping () {
    if (this.props.flipperFlags.customer_combine) {
      return (<div className="form-group">
        <label className="color-label">Save Button:</label>
        <input
          type="text"
          value={this.props.themeSettings.data.save_and_continue || this.props.defaultTexts.save_and_continue}
          onInput={(e) => this.props.handleThemeSettingChange(e, 'save_and_continue')}
          className="form-control"
        />
      </div>)
    }
  }

  render () {
    return (
      <div className="user-setting-form">
        {this.greeting()}
        {this.heading()}
        {this.content()}
        {this.content_2()}
        {this.button()}
        {this.buttonSaveAndShopping()}
      </div>
    );
  }
}

export default CounterForm
