import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalRejectForm extends React.Component {
  constructor(props) {
    super(props);
  }

  content () {
    return (<div className="form-group">
      <label className="color-label">Content:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.auto_reject_content || this.props.defaultTexts.auto_reject_content}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_reject_content')}
        className="form-control"
      />
    </div>)
  }

  greeting () {
    return (<div className="form-group">
      <label className="color-label">Greeting:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.auto_reject_greeting || this.props.defaultTexts.auto_reject_greeting}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_reject_greeting')}
        className="form-control"
      />
    </div>)
  }

  render () {
    return (
      <div className="user-setting-form">
        {this.greeting()}
        {this.content()}
      </div>
    );
  }
}

export default MinimalRejectForm
