import React from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom';

class TimedOverlayForm extends React.Component {
  constructor(props) {
    super(props);
    this.timedOverlayFontColor = React.createRef();
    this.timedOverlayBackgroundColor = React.createRef();
  }

  componentDidMount() {

    var defaultOptions = {
      allowEmpty:true,
      showInput: true,
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "hex"
    }

    $(ReactDOM.findDOMNode(this.timedOverlayFontColor.current)).spectrum($.extend({}, defaultOptions, {
      color: this.props.userSetting.timed_overlay_font_color,
      change: (color) => {
        $('.dial').trigger(
            'configure',
            {
              fgColor: color,
              inputColor: color
            }
        );
        this.props.handleTimedOverlayFontColorChange(color);
      },
      hide: this.props.handleTimedOverlayFontColorChange,
      move: this.props.handleTimedOverlayFontColorChange
    } ));

     $(ReactDOM.findDOMNode(this.timedOverlayBackgroundColor.current)).spectrum($.extend({}, defaultOptions, {
      color: this.props.userSetting.timed_overlay_background_color,
      change: this.props.handleTimedOverlayBackgroundColorChange,
      hide: this.props.handleTimedOverlayBackgroundColorChange,
      move: this.props.handleTimedOverlayBackgroundColorChange
    }));
  }

  additionalSettings () {
    const delayTypes = this.props.timerDelayTypes;
    return (
      <div>
        <div className="form-group">
          <label className="color-label">Position:</label>
          <select className="form-control"
            value={this.props.userSetting.timed_overlay_position}
            onChange={(e) => this.props.handleTimedOverlayPosition(e)}>
            <option key={0} value={'top'}>Top</option>
            <option key={1} value={'bottom'}>Bottom</option>
          </select>
        </div>

        <div className="form-group">
          <label className="color-label">Opacity:</label>
          <input type="number" step="0.1" max="1" min="0.3"
            value={this.props.userSetting.timed_overlay_opacity}
            onInput={(e) => this.props.handleTimedOverlayOpacity(e)}
            className="form-control" />
        </div>

        <div className="form-group">
          <label className="color-label">Timer Delay:</label>
          <select className="form-control"
            value={this.props.userSetting.timed_overlay_timer_type}
            onChange={(e) => this.props.handleTimedOverlayDelay(e)}>
            {Object.keys(delayTypes).map((type) => {
              return (
                <option key={type} value={type}>{delayTypes[type]} seconds</option>
              )
            })}
          </select>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='user-setting-form'>
        <div className="form-group">
          <label className="color-label">Overlay Text:</label>
          <input type="text"
            value={this.props.userSetting.timed_overlay_text}
            onInput={(e) => this.props.handleTimedOverlayTextChange(e)}
            className="form-control"/>
        </div>

        <div className="form-group">
          <label className="color-label">Button Text:</label>
          <input type="text"
            value={this.props.userSetting.timed_overlay_button_text}
            placeholder="Make an Offer"
            onInput={(e) => this.props.handleTimedButtonTextChange(e)} className="form-control" />
        </div>

        <div className="form-group">
          <label className="color-label">Font Color:</label>
          <input type="text" ref={this.timedOverlayFontColor}
          defaultValue={this.props.userSetting.timed_overlay_background_color}
          className="form-control"/>
        </div>

        <div className="form-group">
          <label  className="color-label">Background Color:</label>
          <input type="text" ref={this.timedOverlayBackgroundColor}
            defaultValue={this.props.userSetting.timed_overlay_font_color}
            className="form-control"/>
        </div>
        {this.additionalSettings()}
      </div>
    )
  }
}

export default TimedOverlayForm
