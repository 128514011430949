import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class EmailCopyForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEmailCopySubjectChange(e) {
    this.props.handleEmailCopySubjectChange(this.props.type, e.target.value);
  }

  handleEmailCopyBodyChange(e) {
    this.props.handleEmailCopyBodyChange(this.props.type, e.target.value);
  }

  render() {
    var subject = this.props.userSetting.email_copies[this.props.type].subject;
    var body = this.props.userSetting.email_copies[this.props.type].body;
    return (
      <div className='user-setting-form'>
        <div className="form-group">
          <label className="color-label">Subject:</label>
          <input type="text"
            value={subject}
            className="form-control"
            onChange={(e) => this.handleEmailCopySubjectChange(e)}/>
        </div>
        <div className="form-group">
          <label className="color-label">Body:</label>
          <textarea type="text"
            value={body}
            className="form-control" rows="10"
            onChange={(e) => this.handleEmailCopyBodyChange(e)}/>
        </div>
        {this.props.type == 'multiple' ?
          <div>
            <p>Everything between <b>[[A]]</b> and <b>[[A]]</b> will be replaced by every accepted offer</p>
            <p>Everything between <b>[[C]]</b> and <b>[[C]]</b> will be replaced by every countered offer</p>
          </div> : null
        }
      </div>
    )
  }

}

EmailCopyForm.propTypes = {

}

export default EmailCopyForm
