import React, { Component } from "react";
import Switch from "react-switch";

class SwitchButton extends Component {
  constructor(props) {
    super(props);
  }

  label(side) {
    if (typeof this.props[`label${side}`] == 'string') {
      return this.props[`label${side}`]
    } else if (typeof this.props[`label${side}`] == 'function') {
      return this.props[`label${side}`]()
    }
  }

  render () {
    return (
      <label htmlFor={this.props.name} >
        {this.label('Left')}
        <Switch
          onColor="#9cd2cf"
          name={this.props.name}
          onHandleColor="#2db7ae"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          style={{ verticalAlign: 'middle' }}
          height={20}
          width={48}
          className="react-switch"
          id={this.props.css_id}
          checked={this.props.checked}
          onChange={() => this.props.onChange()}
          id={this.props.css_id}
        />
        <span>{this.label('Right')}</span>
      </label>
    )
  }
}

export default SwitchButton
