import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class OfferEmailPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  accept() {
    return (
      <div className="email">
        <div className="email_body">
          <p>
            Hello,
          </p>

          <p>
            Congratulations! Your offer on {this.props.offer.product.title} for {this.props.offer.amount_with_currency} has been accepted. <strong>Please click <a href="#">here</a> to buy.</strong>
          </p>

          <p>
            Please contact us if you have any further questions :)
            <br></br>
            <br></br>
            Thanks
          </p>
        </div>
      </div>
    );
  }

  counter() {
    return (
      <div className="email">
        <div className="email_body">
          <p>
            Hello
          </p>

          <p>
            Thank you for your offer on {this.props.offer.product.title} for {this.props.offer.amount_with_currency}.  Unfortunately, we cannot accept your offer.  However, we have crafted an exclusive offer for you. <strong>Please click <a href="#">here</a> to buy.</strong>
          </p>

          <p>
            Please contact us if you have any further questions :)
            <br></br>
            <br></br>
            Thanks
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.emailType === "accept") {
      return this.accept();
    } else if (this.props.emailType === "counter") {
      return this.counter();
    }
  }
}


OfferEmailPreview.propTypes = {

}

export default OfferEmailPreview
