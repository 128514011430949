import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class CartPageNoticePreview extends React.Component {
  constructor(props) {
    super(props);
  }

  wrapperStyle() {
    return {
      color: this.props.userSetting.cart_page_notice_color,
      backgroundColor: this.props.userSetting.cart_page_notice_background_color
    }
  }

  customCss() {
    return this.props.userSetting.cart_page_notice_css
  }

  noticeText() {
    return this.props.userSetting.cart_page_notice_text || I18n.t("cart_page_notice.text")
  }

  render() {
    return (
      <div>
        <style dangerouslySetInnerHTML={{__html:
          this.customCss()
        }} />

        <div className="mf-cart-page-notice" style={this.wrapperStyle()}>
          <span className="mf-cart-page-notice__text">
            {this.noticeText()}
          </span>
          <span className="close">×</span>
        </div>
      </div>
    )
  }
}

export default CartPageNoticePreview
