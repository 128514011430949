import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import MinimalSuccessPreview from './MinimalSuccessPreview'
import MinimalSuccessPopupPreview from './MinimalSuccessPopupPreview'
import MinimalSuccessForm from './MinimalSuccessForm'

class MinimalSuccessSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultColors: {
        mainHeading: '#F4F4F4',
        secondaryHeading: '#F4F4F4',
        background: '#2F9683',
        buttonFont: '#5A5A5A',
        buttonBackground: '#FFCF64'
      }
    }
  }

  preview () {
    if (this.props.themeSettings.data.use_sidebar) {
      return <MinimalSuccessPreview defaultTexts={this.props.defaultTexts}
        themeSettings={this.props.themeSettings}
        extraFeatures={this.props.data.extraFeatures}
        colors={this.state.defaultColors}/>
    } else {
      return <MinimalSuccessPopupPreview defaultTexts={this.props.defaultTexts}
        themeSettings={this.props.themeSettings}
        extraFeatures={this.props.data.extraFeatures}
        colors={this.state.defaultColors}/>
    }
  }

  heightPreviewPanel () {
    if (!this.props.themeSettings.data.use_sidebar) {
      return { height: '500px' }
    }
  }

  render() {
    return (
      <div className="ibox float-e-margins">
        <div className="ibox-title" >
          <h1 id="customize-popup">
            {I18n.t("admin.look_and_feel.success.heading")}
          </h1>
        </div>
        <div className="ibox-content">
          <div className="row">
            <div className="col-sm-3">
              <MinimalSuccessForm defaultTexts={this.props.defaultTexts}
                themeSettings={this.props.themeSettings}
                extraFeatures={this.props.data.extraFeatures}
                handleThemeSettingChange={(e, field) => this.props.data.handleThemeSettingChange(e, field)}
                colors={this.state.defaultColors}/>
            </div>
            <div className="col-sm-8 col-md-offset-1 preview-pane preview-pane-minimal-theme" style={this.heightPreviewPanel()}>
              {this.preview()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MinimalSuccessSetup
