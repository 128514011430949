import { h } from 'preact'
import React from "react"
// import PropTypes from "prop-types"

class ReviewRequest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalId: this.props.offerId + "_review_request"
    }
  }

  componentDidMount() {
    $("#" + this.state.modalId).modal("show");
  }

  hide() {
    $("#" + this.state.modalId).modal('hide');
  }

  render() {
    return (
      <div id={this.state.modalId} className="modal review-request" >
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-body">
              <a className="glyphicon glyphicon-remove" onClick={() => this.hide()}></a>

              <div className="offer-success">
                <h2><i className="fa fa-check" aria-hidden="true"></i> Your offer has been successfully sent!</h2>
              </div>
              <h2> Like the app? Leave a review by clicking <a target="_blank" onClick={() => this.hide()} href="/review_request/completed?place=2">here</a></h2>
              <div className="stars">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
              </div>
              <div className='note'>Help us continue to build awesome features for you!</div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default ReviewRequest;
