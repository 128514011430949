import React from "react"
import ExitOfferForm from '../base/ExitOfferForm'

class ClassicForm extends ExitOfferForm {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="user-setting-form">
        {this.buttonFontColor()}
        {this.buttonBackgroundColor()}
        {this.mainHeadingColorField()}
        {this.secondaryHeadingColorField()}
        {this.headerBackgroundColorField()}

        {this.mainHeadingTitle()}
        {this.offerTitleGoogleFont()}
        {this.secondaryHeadingTitle()}
        {this.offerSubTitleGoogleFont()}
        {this.offerAmountField()}
        {this.offerEmailField()}
        {this.quantityFieldSelector()}
        {this.phoneNumberSwitcher()}
        {this.nameSwitcher()}
        {this.shippingSwitcher()}
        {this.noteSwitcher()}
        {this.offerLabelsFont()}
        {this.sendOfferButton()}
        {this.offerButtonGoogleFont()}
        {this.howItWorksField()}
        {this.lockExplanation()}
        {this.termsSwitcher()}
        {this.gdprSwitcher()}
        {this.googleAnalyticSwitcher()}
      </div>
    );
  }
}

export default ClassicForm

