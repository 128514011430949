import React from 'react';
import reactSt from 'components/base/smart-table.js';
import { smartTable as st } from 'smart-table-core';

// your row component
const Row = ({ value }) => {
  const { img, title, url, made_offers, completed, declined, average } = value;
  return (<tr>
    <td className="product_image">
      <img src={img} className="img-responsive img-rounded " />
    </td>
    <td><a target="_blank" href={url}>{title}</a></td>
    <td>{made_offers}</td>
    <td>{completed}</td>
    <td>{declined}</td>
    <td>{Number(Number(average).toFixed(2))}</td>
  </tr>);
};

// your body component (connected to the smart table)
// note the usage of the table hoc
const TableBody = reactSt.table((props) => {
  const data = props.smartTable.getMatchingItems()
  const displayed = data.length ? data : [];
  return (<tbody>
    {displayed.map((value, index) => {
      return <Row key={index} value={value} />
    })}
  </tbody>);
});


class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: this.props.products
    }
  }

  render() {
    const columns = ['', 'Title', 'Made Offers', 'Completed', 'Declined', 'Average Offer']
    const table = st({ data: this.props.products });
    return (
      <div className="col-sm-12">
        <h2>Popular Products</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              {columns.map((col, index) => <th key={index}>{col}</th>)}
            </tr>
          </thead>
          <TableBody smartTable={table} />
        </table>
      </div>
    );
  }
}

export default ProductTable
