import { h } from 'preact'
import React from "react"
import AlertNotification from '../base/AlertNotification'
import PopupPagination from '../base/PopupPagination'

class MultiselectModal extends React.Component {

  constructor(props) {
    super(props);
    let userSetting = this.props.userSetting;
    if (typeof this.props.userSetting === 'string') {
      userSetting = JSON.parse(this.props.userSetting);
    }
    this.state = {
      isLoading: false,
      selectedOffers: this.props.selectedOffers,
      userSetting: userSetting,
      emailBody: userSetting.email_copies.multiple['body'],
      processedOffers: null,
      page: 0,
      rowsPerPage: 10,
      selectedActions: {
        accept: false,
        counter: false,
        decline: false
      }
    }
  }

  hide() {
    $("#multiselect-modal").modal('hide');
  }

  selectAction(action, offer) {
    var selectedActions = this.state.selectedActions
    selectedActions[action] = true
    this.setState({ selectedActions: selectedActions })
    offer.action = action
  }

  setCounterPrice(offer, e) {
    offer.counter_price = parseFloat(e.target.value)
    this.forceUpdate()
  }

  componentDidMount() {
    $('.modal').modal('handleUpdate');
  }

  submitForm() {
    if (this._validate()) {
      var emailCopy = this._getEmailCopy();
      var button = $("#multi-select-submit");
      $.ajax({
        url: '/offers/multi_select',
        dataType: "json",
        type: "PUT",
        data: {
          offers: this.state.selectedOffers,
          email_subject: emailCopy["subject"],
          email_body: emailCopy["body"]
        },
        success: (data) => {
          this.setState({ isLoading: false })
          button.prop('disabled', false);
          this.props.onCloseModal();
          this.props.updateOffers(this.state.selectedOffers)
        },
        error: (xhr, status, err) => {
          this.setState({ isLoading: false })
          button.prop('disabled', false);
          this.setState({ error: xhr.responseJSON.message || err });
          button.prop('disabled', false);
        }
      });
    }
  }

  _validate() {
    var button = $("#multi-select-submit");
    button.prop('disabled', true);
    this.setState({ isLoading: true })

    var selectedActions = this.state.selectedActions
    if (this.state.selectedOffers.filter((offer) => offer.action == undefined).length) {
      this.setState({ error: 'Please, select actions for all offers.' });
      button.prop('disabled', false);
      return false
    }
    let offersWithoutCounter = this.state.selectedOffers.filter((offer) => {
      return offer.action == 'countered' && offer.counter_price == '0.0'
    })
    if (offersWithoutCounter.length) {
      this.setState({ error: 'Please enter counter offer.' });
      button.prop('disabled', false);
      return false
    }

    if (selectedActions.counter) {
      var errors = "";
      this.state.selectedOffers.forEach((offer) => {
        if (offer.action == 'counter' && !this._isValidOffer(offer)) {
          errors += `Counter offer for ${offer.product.title} should be less than product price.\n`
        }
      })

      if (errors) {
        this.setState({ error: errors });
        button.prop('disabled', false);
        return false
      }
    }

    var emailCopy = this._getEmailCopy();

    if (!selectedActions.accept && !selectedActions.counter) {
      var v = this._validateEmail(emailCopy, ["[[CHECKOUT]]"]);
    } else {
      var v = this._validateEmail(emailCopy, []);
    }

    if (v.valid === false) {
      this.setState({error: v.errors.join("\n")});
      button.prop('disabled', false);
      return false;
    }
    return true
  }

  _getEmailCopy() {
    return {
      "subject": $(".multiselect-form [name=email_subject]").val(),
      "body": $(".multiselect-form [name=email_body]").val()
    }
  }

  _validateEmail(email, requiredVariables = []) {
    var subject = email["subject"];
    var body = email["body"];
    var res = {valid: true, errors: []};

    if (!subject || subject.length === 0) {
      res.valid = false;
      res.errors.push("Subject can't be empty");
    }

    if (!body || body.length === 0) {
      res.valid = false;
      res.errors.push("Body can't be empty");
    }

    // Check for presence of required variables
    for (let v in requiredVariables) {
      var variable = requiredVariables[v];
      if (body && !body.includes(variable)) {
        res.valid = false;
        res.errors.push(`${variable} is missing in body`);
      }
    }

    return res;
  }

  _isValidOffer(offer) {
    return parseFloat(offer.counter_price) < parseFloat(offer.product.price);
  }

  hideError(e) {
    this.setState({ error: "" });
    e.preventDefault();
    e.stopPropagation();
  }

  changeBody = (e) => {
    let compose = this.composeBody(e.target.value);
    this.setState({ emailBody: compose.body, processedOffers: compose.realList })
  }

  handleChangeOfferPage = (data) => {
    this.setState({page: data.selected})
  }

  form() {
    var formError = '';
    if (this.state.error && this.state.error != "") {
      formError = <AlertNotification handleClick={(e) => this.hideError(e)} alertType="danger" alertMessage={this.state.error} />
    }

    const footer = (<div>
      [[CHECKOUT]] will be replaced with the actual checkout link
    </div>)

    var subject = this.state.userSetting.email_copies.multiple['subject'];
    var body = this.composeBody(this.state.emailBody).body;
    return (
      <div className="multiselect-form">
        {formError}
        <form action="offer_accept" method="post" className="modal-form">
          <h3>Email to customer</h3>
          <label>Subject:</label>
          <input name="email_subject" type="text" defaultValue={subject}></input><br></br>
          <label>Body:</label>
          <textarea name="email_body" value={body}
                    wrap="off" onChange={this.changeBody}></textarea>
          {footer}
        </form>
      </div>
    );
  }

  prepareRow(offer, template) {
    var row = template.replace(/\[\[(A|C)\]\]/g, '')
    row = row.replace(/\[\[PRODUCT_TITLE\]\]/, `${offer.product.title}${offer.product.variant_title ? `(${offer.product.variant_title})` : ''}`);
    row = row.replace(/\[\[OFFER_AMOUNT\]\]/, offer.amount_with_currency);
    row = row.replace(/\[\[COUNTER\]\]/, this.parseCounterWithCurrency(offer));
    row = row.replace(/\[\[QUANTITY\]\]/, offer.quantity);
    return row
  }

  parseCounterWithCurrency(offer) {
    const template = offer.amount_with_currency
    return template.replace(/\d+(.|,)\d+/, offer.counter_price)
  }

  composeBody(body) {
    const originalBody = this.state.userSetting.email_copies.multiple['body']
    var accepted_template = originalBody.match(/\[\[A\]\].+\[\[A\]\]/)[0]
    var countered_template = originalBody.match(/\[\[C\]\].+\[\[C\]\]/)[0]

    var realList = this.state.selectedOffers.map((offer, i) => {
      if (offer.action == 'accepted') {
        return this.prepareRow(offer, accepted_template)
      } else if (offer.action == 'countered') {
        return this.prepareRow(offer, countered_template)
      }
    })
    if (realList.join('').length) {
      body = body.replace(accepted_template, realList.join('\n'));
      body = body.replace(countered_template, '');
    }
    if (this.state.processedOffers != null) {
      body = body.replace(this.state.processedOffers, realList.join('\n'));
    }
    body = body.replace(/\[\[STORE_EMAIL_ADDRESS\]\]/, `<a href='mailto:${this.props.initialUserEmail}'>${this.props.initialUserEmail}</a>`);
    body = body.replace(/\[\[STORE_SECONDARY_EMAIL_ADDRESS\]\]/, `<a href='mailto:${this.props.initialUserSecondaryEmail}'>${this.props.initialUserSecondaryEmail}</a>`);
    return { body: body, realList: realList.join('\n') };
  }

  listOffers() {
    const {page, rowsPerPage} = this.state
    var offers = []
    var self = this
    this.state.selectedOffers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach(function(offer, index) {
      var counterInput = ""
      if (offer.action == 'countered') {
        counterInput = (
          <input className="form-control multi-counter"
          placeholder="Counter Offer"
          onChange={self.setCounterPrice.bind(self, offer)}></input>
        )
      }
      offers.push(
        <tr key={index}>
          <td>
            <a target="_blank" href={offer.product.url}>
              {offer.product.title}
            </a>
          </td>
          <td>{offer.product.price}</td>
          <td>{offer.amount_with_currency}</td>
          <td>
            <div className="pull-right">
              {counterInput}
              <div className="btn-group">
                <a className={`btn btn-sm ${offer.action == 'accepted' ? 'btn-info' : 'btn-default'}`}
                  onClick={self.selectAction.bind(self, 'accepted', offer)}>Accept</a>
                <a className={`btn btn-sm ${offer.action == 'countered' ? 'btn-warning' : 'btn-default'}`}
                  onClick={self.selectAction.bind(self, 'countered', offer)}>Counter</a>
              </div>
            </div>
          </td>
        </tr>
      )
    })
    return offers
  }

  render() {
    const {selectedOffers, rowsPerPage} = this.state
    const pageCount = selectedOffers.length / rowsPerPage;
    return (
      <div className="modal-content">
        <div className="modal-header ">
          <a className="glyphicon glyphicon-remove" onClick={() => this.props.onCloseModal()}></a>
          <h4 className="modal-title capitalize">Multiply Select</h4>
        </div>
        <div className="modal-body">
          <div>
            <div className="row">
              <table className="table combine-offer-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Offer Amount</th>
                    <th className="center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.listOffers()}
                </tbody>
              </table>
            </div>
            <div className="text-center combined-paginate">
              <PopupPagination 
                pageCount={pageCount}
                handleChangeOfferPage={this.handleChangeOfferPage}
              />
            </div>
            {this.form()}
          </div>
        </div>
        <div className="modal-footer">
          {
            this.state.isLoading ? <i className="fa fa-circle-o-notch fa-spin loader" style={{left: 0}}> </i> : null
          }
          <button id="multi-select-submit"
            type="button"
            className="btn btn-primary"
            onClick={() => this.submitForm()}>Done</button>
        </div>
      </div>
    )
  }
}

export default MultiselectModal;
