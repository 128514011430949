import React from "react"
import PropTypes from "prop-types"

class ExitOfferPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  howItWorks () {
    return <span dangerouslySetInnerHTML={{ __html: this.props.themeSettings.data.how_it_works || this.props.defaultTexts.how_it_works }} />
  }

  offerAmountField () {
    var defaultAmountFieldPlaceholder = this.props.themeSettings.data.show_quantity ?
      this.props.defaultTexts.hint_amount_with_quantity : this.props.defaultTexts.hint_amount
    return this.props.themeSettings.data.hint_amount || defaultAmountFieldPlaceholder
  }

  quantityField () {
    return this.props.themeSettings.data.hint_quantity || this.props.defaultTexts.hint_quantity;
  }

  emailField () {
    return this.props.themeSettings.data.hint_email || this.props.defaultTexts.hint_email;
  }

  phoneNumberField () {
    return this.props.themeSettings.data.hint_phone_number || this.props.defaultTexts.hint_phone_number;
  }

  customerNameField () {
    return this.props.themeSettings.data.hint_customer_name || this.props.defaultTexts.hint_customer_name;
  }

  noteField () {
    return this.props.themeSettings.data.hint_notes || this.props.defaultTexts.hint_notes;
  }

  shippingField () {
    return this.props.themeSettings.data.hint_shipping_address || this.props.defaultTexts.hint_shipping_address;
  }

  sendOfferButton () {
    return this.props.themeSettings.data.button_submit || this.props.defaultTexts.button_submit;
  }

   privatePolicyCheckbox() {
    if (this.props.themeSettings.data.show_gdpr && this.props.themeSettings.data.show_terms ) {
      return (
        <div className="col-xs-12 col-md-4 pull-right classic">
          <div className="private_policy text-align-left">
            <input type="checkbox" />
            { !!this.props.userSetting.custom_terms_text ?
                <span dangerouslySetInnerHTML={{ __html: this.props.userSetting.custom_terms_text }}/>
              :
                <span>
                  <span>I agree to </span>
                  <a href="/static/terms_of_service" target='_blank'>Terms Of Service</a>
                  <span className="space-left-right">&</span>
                  <a href="/static/private_policy" target='_blank'>Private Policy</a>
                </span>
            }
          </div>
        </div>
      )
    }else if(this.props.themeSettings.data.show_gdpr) {
      return (
        <div className="col-xs-12 col-md-4 pull-right classic">
          <div className="private_policy">
            <input type="checkbox" />
            <span>I agree to </span>
            <a href="/static/private_policy" target='_blank'>Private Policy</a>
          </div>
        </div>
      )
    }else if(this.props.themeSettings.data.show_terms) {
      return (
        <div className="col-xs-12 col-md-4 pull-right classic">
          <div className="private_policy">
            <input type="checkbox" />
            { !!this.props.userSetting.custom_terms_text ?
                <span dangerouslySetInnerHTML={{ __html: this.props.userSetting.custom_terms_text }}/>
              :
                <span>
                  <span>I agree to </span>
                  <a href="/static/terms_of_service" target='_blank'>Terms Of Service</a>
                </span>
            }
          </div>
        </div>
      )
    }
  }

  render (){
    const poweredBy = supportsFeature(
      "mf_ad",
      this.props.extraFeatures,
      <div className="powered-by">
        Powered by{" "}
        <a href="https://apps.shopify.com/make-an-offer">Make an Offer</a>{" "}
        Shopify app
      </div>
    );

    const quantity = supportsFeature(
      "quantity",
      this.props.extraFeatures,
      <input
        className="mf_quantity"
        id="mf_quantity"
        name="quantity"
        placeholder={this.quantityField()}
        type="number"
      />
    )

    const { userSetting } = this.props
    var newArrayFont = [userSetting.offer_title_font,userSetting.offer_subtitle_font, userSetting.offer_labels_font, userSetting.offer_btn_font].filter(Boolean);
    let filter_font = [...new Set(newArrayFont && newArrayFont)]
    let append_font = null;
    filter_font && filter_font.map((font, i) => {
      if(font !== "Twentieth Century") {
        const elem = document.getElementById(i);
        if(elem) { elem.remove()}
        append_font = document.createElement('link');
        append_font.rel='stylesheet'
        append_font.id = i
        document.head.appendChild(append_font)
        append_font.href = `https://fonts.googleapis.com/css2?family=${font}:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap`
      }
    })
    return (
      <div className="mf_modal">
        <div className="modal-content popup_with_notes">
          <div className="modal-header" style={this.divHeaderStyle()}>
            <button
              type="button"
              className="close"
              data-dismiss="mf_modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style={this.secondaryHeadingTitleStyle()}>×</span>
            </button>
            <div className="modal-title">
              <h1 style={this.mainHeadingTitleStyle()}
                ref={(el) => {
                  if (el) {el.style.setProperty('font-family', userSetting.offer_title_font, 'important')}
                }}>
                {this.props.themeSettings.data.main_heading || this.props.defaultTexts.main_heading}
              </h1>
              <h3 style={this.secondaryHeadingTitleStyle()} 
                ref={(el) => {
                  if (el) {el.style.setProperty('font-family', userSetting.offer_subtitle_font, 'important')}
                }}>
                {this.props.themeSettings.data.secondary_heading || this.props.defaultTexts.secondary_heading}
              </h3>
            </div>
          </div>
          <div className="modal-body">
            <form
              acceptCharset="UTF-8"
              action="#"
              className="test"
              id="mf_offer_form"
              method="post"
            >
              <div className="offers single-offer">
                <div className="row offer">
                  <div className="col-xs-3">
                    <div className="img-container">
                      <img
                        alt="Product"
                        src="/assets/product.png"
                        className="img-responsive"
                      />
                    </div>
                  </div>
                  <div className="fields col-xs-9">
                    <h3> Product - <span className="offer_price">$100.00 USD</span></h3>
                    <div className={
                      this.props.themeSettings.data.show_quantity
                        ? "field field_pr col-xs-9"
                        : "field col-xs-12"
                    }>
                      <input
                        id="offers_amount"
                        name="offers[][amount]"
                        placeholder={this.offerAmountField()}
                        type="number"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>
                    <div
                      className={
                        this.props.themeSettings.data.show_quantity
                          ? "field col-xs-3"
                          : "field col-xs-3 hidden"
                      }
                    >
                      {quantity}
                    </div>
                    <div className="field">
                      <input
                        className="mf_email"
                        id="mf_email"
                        name="email"
                        placeholder={this.emailField()}
                        type="text"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>
                    <div
                      className={
                        this.props.themeSettings.data.show_phone_number
                          ? "field"
                          : "field hidden"
                      }
                    >
                      <input
                        id="mf_phone_number"
                        name="phone"
                        placeholder={this.phoneNumberField()}
                        type="number"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>

                    <div
                      className={
                        this.props.themeSettings.data.show_name
                          ? "field"
                          : "field hidden"
                      }
                    >
                      <input
                        id="mf_name"
                        name="name"
                        placeholder={this.customerNameField()}
                        type="text"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>

                    <div
                      className={
                        this.props.themeSettings.data.show_shipping_address
                          ? "field"
                          : "field hidden"
                      }
                    >
                      <textarea
                        id="shipping_address"
                        name="shipping_address"
                        placeholder={this.shippingField()}
                        rows="3"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>
                    <div
                      className={
                        this.props.themeSettings.data.show_notes
                          ? "field"
                          : "field hidden"
                      }
                    >
                      <textarea
                        id="notes_to_seller"
                        name="notes_to_seller"
                        placeholder={this.noteField()}
                        rows="3"
                        ref={(el) => {
                          if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <div className="col-xs-12 col-md-9 text">
                  {this.howItWorks()}
                </div>

                <div className="col-xs-12 col-md-3">
                  <input
                    className="btn"
                    name="commit"
                    type="submit"
                    defaultValue={this.sendOfferButton()}
                    style={this.divButtonStyle()}
                    ref={(el) => {
                      if (el) {el.style.setProperty('font-family', userSetting.offer_btn_font, 'important')}
                    }}
                  />
                </div>
                {this.privatePolicyCheckbox()}
              </div>
              {poweredBy}
            </form>
          </div>
        </div>
      </div >
    );
  }
}

export default ExitOfferPreview
