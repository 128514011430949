import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

import SuccessPreview from '../base/SuccessPreview'
import SuccessForm from '../base/SuccessForm'

class ClassicSuccessSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultColors: {
        mainHeading: '#F4F4F4',
        secondaryHeading: '#F4F4F4',
        background: '#000000',
        buttonFont: '#F4F4F4',
        buttonBackground: '#2db7ae'
      }
    }
  }

  render() {
    return (
      <div className="ibox float-e-margins">
        <div className="ibox-title" >
          <h1 id="customize-popup">
            {I18n.t("admin.look_and_feel.success.heading")}
          </h1>
        </div>
        <div className="ibox-content">
          <div className="row">
            <div className="col-sm-3">
              <SuccessForm defaultTexts={this.props.defaultTexts}
                themeSettings={this.props.themeSettings}
                extraFeatures={this.props.data.extraFeatures}
                handleThemeSettingChange={(e, field) => this.props.data.handleThemeSettingChange(e, field)}
                colors={this.state.defaultColors}/>
            </div>
            <div className="col-sm-8 col-md-offset-1 preview-pane" style={{height: '100%'}}>
              <SuccessPreview defaultTexts={this.props.defaultTexts}
                themeSettings={this.props.themeSettings}
                extraFeatures={this.props.data.extraFeatures}
                colors={this.state.defaultColors}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassicSuccessSetup
