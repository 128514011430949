import { h } from 'preact'
import React from "react"

class Footer extends React.Component {
  skipTour () {
    if (this.props.currentStep != this.props.lastStep && this.props.currentStep != 7) {
      return (
        <div className="skip_tour">
          <span data-dismiss="modal" id="skip_tour" onClick={() => this.props.skipTour()}>SKIP TOUR</span>
        </div>
      )
    }
    if (this.props.currentStep == 7) {
      return (
        <div className="skip_tour">
          <span onClick={() => this.props.skipCurrentStep()}>SKIP THIS STEP</span>
        </div>
      )
    }
  }
  // remove if will not use setting value
  setSkipTourInCookies () {
    Cookies.set('tour_disable', true)
  }

  previousButton () {
    if (this.props.currentStep == 1) {
      return (
        <div onClick={() => this.props.handleNextStep()} className="previous_box_with_text">
          <span className="start">LETS GET STARTED</span>
          <i className="fa fa-angle-right"></i>
        </div>
      )
    } else {
      return (
        <div onClick={() => this.props.handlePreviousStep()} className="previous_box">
          <i className="fa fa-angle-left" ></i>
        </div>
      )
    }
  }

  nextButton () {
    if (this.props.currentStep > 1 && this.props.currentStep != this.props.lastStep) {
      return (
        <div className="next_box">
          <i className="fa fa-angle-right" onClick={() => this.props.handleNextStep()}></i>
        </div>
      )
    } else if (this.props.currentStep == this.props.lastStep) {
      return (
        <div className="next_box_with_text" >
          <i className="fa fa-angle-right" onClick={() => this.props.handleNextStep()}></i>
          <span className="start" onClick={() => this.props.handleNextStep()}>Proceed to settings page</span>
        </div>
      )
    }
  }

  render () {
    const progress = this.props.currentStep / this.props.lastStep * 100

    return (
      <div className="modal-footer tour_footer">
        <div className="progress_box">
          {this.previousButton()}
          <div className="progress">
            <div className="complete-progress" style={{ width: progress + '%' }}>
            </div>
            <span className="progress_text">{this.props.currentStep} OF {this.props.lastStep}</span>
          </div>
          {this.nextButton()}
        </div>

        {this.skipTour()}
      </div>
    );
  }
}

export default Footer
