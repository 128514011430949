import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalRejectPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divHeaderStyle() {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }


  content() {
    return this.props.themeSettings.data.auto_reject_content || this.props.defaultTexts.auto_reject_content;
  }

  greeting() {
    return this.props.themeSettings.data.auto_reject_greeting || this.props.defaultTexts.auto_reject_greeting;
  }

  render() {
    return (
      <div className="container mf_sidebar_container minimal">
        <div className="mf_container">
          <div className="success_container" style={{ display: 'block' }}>
            <a className="close close_iframe" style={{ opacity: '1', color: '#F7F7F7' }}>×</a>
            <div className="auto_accepted response_block" style={this.divHeaderStyle()}>
              <h4>{this.greeting()}</h4>
              <h4>{this.content()}</h4>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalRejectPreview
