import React from "react"
import PropTypes from "prop-types"
import AlertNotification from 'components/base/AlertNotification'
import IncentiveConfiguration from './incentive_configuration/IncentiveConfiguration'
import LookAndFeel from './look_and_feel/LookAndFeel'
import EmailCopy from './email_copies/EmailCopy'


class UserSetting extends React.Component {
  constructor(props) {
    super(props);
    var userSetting = props.initialUserSetting;
    if (typeof props.initialUserSetting === 'string') {
      userSetting = JSON.parse(props.initialUserSetting);
    }
    // as we save setting as json, we get all booleans as string, so we need
    // to lead the String to the Boolaen format
    if (props.theme_settings.length) {
      var themeSettings = this.modifyBooleanThemeSetting(props.theme_settings)
    } else {
      var themeSettings = [{
        id: null,
        theme_id: userSetting.current_theme.id,
        user_setting_id: userSetting.id,
        data: { show_notes: true, show_shipping_address: true }
      }]
    }
    this.state = {
      alertMessage: "",
      alertType: "",
      autoRespondSectionErrors: "",
      subMenu: "configuration",
      userSetting: userSetting,
      rules: props.rules.map((rule) => { rule.error = {}; return rule; }),
      themeSettings: themeSettings
    };
  }

  componentDidMount() {
    document.addEventListener('triggerOnboarding', (e) => {
      e.detail.rules.forEach(function (rule) { rule.error = {} })
      this.setState({ userSetting: e.detail, rules: e.detail.rules })
    }, true);
  }

  modifyBooleanThemeSetting(themeSettings) {
    return themeSettings.map((setting) => {
      Object.keys(setting.data).map(function (objectKey, index) {
        var value = setting.data[objectKey];
        if (value == 'true') {
          setting.data[objectKey] = true
        } else if (value == 'false') {
          setting.data[objectKey] = false
        }
      });
      return setting
    })
  }

  handleClick (e) {
    this.setState({ alertMessage: "" });
  }

  handleSKUChange (e) {
    this.state.userSetting.skus = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCollectionsChange (e) {
    this.state.userSetting.collections = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTagsChange (e) {
    this.state.userSetting.tags = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleDisplayRuleType (e) {
    this.state.userSetting.display_rule_type = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleIncentiveCofiguration (incentive) {
    if (incentive == 'product_overlay_message') {
      this.state.userSetting.product_exit_offer = false
    } else if (incentive == 'product_exit_offer') {
      this.state.userSetting.product_overlay_message = false
    } else if (incentive == 'cart_overlay_message') {
      this.state.userSetting.cart_exit_offer = false
    } else if (incentive == 'cart_exit_offer') {
      this.state.userSetting.cart_overlay_message = false
    }else if (incentive == 'enable_auto_declined') {
      this.state.userSetting.enable_auto_declined 
    }
    this.state.userSetting[incentive] = !this.state.userSetting[incentive];
    this.setState({ userSetting: this.state.userSetting });
  }

  handleButtonFontColorChange (color) {
    this.state.userSetting.button_font_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleButtonBackgroundColorChange (color) {
    this.state.userSetting.button_background_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleButtonTextChange (e) {
    this.state.userSetting.button_text = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handlePrivatePolicyLinkChange(e) {
    this.state.userSetting.private_policy = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTermsOfServiceLinkChange(e) {
    this.state.userSetting.terms_of_service = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCustomTermsTextChange(e) {
    this.state.userSetting.custom_terms_text = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleGoogleAnalyticeCode(e) {
    this.state.userSetting.google_analytics_code = e.target.value;
    this.setState({userSetting: this.state.userSetting})
  }

  handleOfferButtonFont(value) {
    this.state.userSetting.offer_btn_font = value;
    this.setState({userSetting: this.state.userSetting})
  }

  handleOfferTitleFont(value) {
    this.state.userSetting.offer_title_font = value;
    this.setState({userSetting: this.state.userSetting})
  }

  handleOfferSubTitleFont(value) {
    this.state.userSetting.offer_subtitle_font = value;
    this.setState({userSetting: this.state.userSetting})
  }

  handleOfferLabelsFont(value) {
    this.state.userSetting.offer_labels_font = value;
    this.setState({userSetting: this.state.userSetting})
  }

  handleTimedOverlayFontColorChange (color) {
    this.state.userSetting.timed_overlay_font_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedOverlayBackgroundColorChange (color) {
    this.state.userSetting.timed_overlay_background_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedOverlayTextChange (e) {
    this.state.userSetting.timed_overlay_text = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedButtonTextChange (e) {
    this.state.userSetting.timed_overlay_button_text = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCartPageNoticeColorChange(color) {
    this.state.userSetting.cart_page_notice_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCartPageNoticeBackgroundColorChange(color) {
    this.state.userSetting.cart_page_notice_background_color = color.toHexString();
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCartPageNoticeTextChange(e) {
    this.state.userSetting.cart_page_notice_text = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCartPageNoticeCssChange(e) {
    this.state.userSetting.cart_page_notice_css = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleSenderEmailChange (e) {
    this.state.userSetting.sender_email = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleModalCssChange(e) {
    this.state.userSetting.modal_css = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleCustomerEmailsCssChange(e) {
    this.state.userSetting.customer_emails_css = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleStorefrontProductsChange(products) {
    this.state.userSetting.storefront_products = products;
    this.setState({ userSetting: this.state.userSetting });
  }

  addErrorsAndScrollUp(errors) {
    this.setState({ alertMessage: errors, alertType: "danger" })
    window.scrollTo(0, 0);
  }

  addErrorsToEmailCopies (resp) {
    var errors = resp.errors.email_copies.join('\n');
    if (!errors) {
      Object.keys(resp.errors).forEach(function (key, value) {
        if (resp.errors[key].length) {
          errors += `${key.replace(/[\._]/g, ' ')} ${resp.errors[key]}; `
        }
      })
    }
    this.addErrorsAndScrollUp(errors)
  }

  handleSubmitButton () {
    var settings = $.extend({}, this.state.userSetting);
    settings.email_copies = JSON.stringify(settings.email_copies);
    settings.rules_attributes = this.state.rules.map((rule) => {
      if (rule.new) delete rule['id']
      return rule
    });
    settings.theme_settings_attributes = this.state.themeSettings
    settings.storefront_products_attributes = settings.storefront_products.map((p) => {
      if (p.new) delete p['id']
      return p
    });
    this.setState({ alertMessage: "", alertType: "success", autoRespondSectionErrors: '', autoDeclinedSectionErrors: ''});
    $.ajax({
      url: this.props.url + "/" + (this.state.userSetting.id || ""),
      dataType: "json",
      type: "POST",
      data: {
        _method: (this.state.userSetting.id ? 'PUT' : 'POST'),
        user_setting: settings
      },
      success: (data) => {
        this.setState({
          alertMessage: "You've successfully saved the settings",
          alertType: "success"
        });
        if (!this.state.userSetting.id) {
          $('.nav-tabs a[href="#install"]').tab("show");
        }
        if (data) {
          data.rules.forEach(function (rule) { rule.error = {} })
          var themeSettings = this.modifyBooleanThemeSetting(data.theme_settings)
          this.setState({ userSetting: data, rules: data.rules, themeSettings: themeSettings });
        }
        this.broadcastUserSettings();
        window.scrollTo(0,0);
      },
      error: (xhr, status, err) => {
        if (xhr.status === 422) {
          var resp = JSON.parse(xhr.responseText);
          if (resp.errors.email_copies.length) {
            this.addErrorsToEmailCopies(resp)
          } else if (resp.errors.sms_notification) {
            this.addErrorsAndScrollUp(resp.errors.sms_notification.join('\n'));
          } else if(resp.errors.max_offer_discount) {
            this.setState({ autoDeclinedSectionErrors: resp.errors.max_offer_discount })
          } else {
            this.addErrorsForRules();
            this.addErrorsForProducts(resp.errors);
            this.setState({ autoRespondSectionErrors: 'Fix errors bellow' })
            let section = document.getElementById('auto_respond_section');
            if (section) {
              section.scrollIntoView({
                behavior: 'smooth'
              });
            }
          }
        } else {
          this.addErrorsAndScrollUp("Something went wrong. Please try again")
        }
      }
    });
  }

  addErrorsForRules  () {
    this.state.rules.forEach(function (rule) {
      rule.error = {
        condition: rule.condition == '' && (rule.filter_type == '1' || rule.filter_type == '3'),
        discount: rule.discount == '',
        counter_offer: rule.counter_offer == ''
      }
    })
    this.setState({ rules: this.state.rules })
  }

  addErrorsForProducts(errors) {
    this.state.userSetting.storefront_products.forEach(function (product) {
      if (product.new) {
        product.errors = {
          category_id: !(errors['storefront_products.category_id'] == undefined),
          sub_category_id: !(errors['storefront_products.sub_category_id'] == undefined),
          platform_id: !(errors['storefront_products.platform_id'] == undefined),
        }
      }
    })
    this.setState({ userSetting: this.state.userSetting })
  }

  broadcastUserSettings () {
    let userSetting = JSON.stringify(this.state.userSetting);
    $('#msg_bridge').trigger('new_user_settings', [userSetting]);
  }

  handleSubMenuClick (value) {
    window.scrollTo(0, 0);
    this.setState({ subMenu: value });
  }

  handleEmailCopySubjectChange (type, value) {
    this.state.userSetting.email_copies[type].subject = value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleEmailCopyBodyChange (type, value) {
    this.state.userSetting.email_copies[type].body = value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleEmailCopyBccMyselfChnage (show_bcc_myself) {
    this.state.userSetting[show_bcc_myself] = !this.state.userSetting[
      show_bcc_myself
    ];
    this.setState({ userSetting: this.state.userSetting });
  }

  handleSendManualOfferReviewMailChnage (show_offer_email) {
    this.state.userSetting[show_offer_email] = !this.state.userSetting[
    show_offer_email
    ]
    this.setState({ userSetting: this.state.userSetting });
  }


  handleRulesChange (rules) {
    this.setState({ rules });
  }

  handleExpirationChange (e) {
    this.state.userSetting.expiration = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleMaximumOfferDiscount (e) {
    this.state.userSetting.max_offer_discount = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  changeOfferDiscountType (e) {
    this.state.userSetting.max_offer_discount_type = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedOverlayPosition (e) {
    this.state.userSetting.timed_overlay_position = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedOverlayOpacity (e) {
    this.state.userSetting.timed_overlay_opacity = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleTimedOverlayDelay(e) {
    this.state.userSetting.timed_overlay_timer_type = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handlePhoneNumberChange (e) {
    this.state.userSetting.phone_number = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleSmsNotificationChange () {
    this.state.userSetting.sms_notification = !this.state.userSetting.sms_notification;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleThemeChange (e) {
    var currentTheme = this.props.themes.find((theme) => theme.id == e.target.value)
    this.state.userSetting.current_theme = currentTheme;
    this.state.userSetting.current_theme_id = currentTheme.id;
    var themeSettings = this.state.themeSettings.find((s) => s.theme_id == currentTheme.id)
    this.state.userSetting.theme_setting_id = themeSettings && themeSettings.id
    if (!themeSettings) {
      this.state.themeSettings.push(
        {
          id: null,
          theme_id: currentTheme.id,
          user_setting_id: this.state.userSetting.id,
          data: { show_notes: true, show_shipping_address: true }
        }
      )
    }
    this.setState({ userSetting: this.state.userSetting, themeSettings: this.state.themeSettings });
  }

  handleThemeSettingChange  (e, field) {
    let currentTheme = this.state.userSetting.current_theme.id
    this.state.themeSettings.forEach((settings) => {
      if (settings.theme_id == currentTheme) {
        if (field.includes('color')) {
          settings.data[field] = e.toHexString();
        } else if (field.includes('show_') || field.includes('use_sidebar')) {
          settings.data[field] = !settings.data[field]
        } else {
          settings.data[field] = e.target.value;
        }
      }
    })
    this.setState({ themeSettings: this.state.themeSettings });
  }

  closeAutoRespondErrors () {
    this.setState({ autoRespondSectionErrors: '', autoDeclinedSectionErrors: '' })
  }

  render () {
    var notification = <AlertNotification handleClick={() => this.handleClick()} alertMessage={this.state.alertMessage} alertType={this.state.alertType} />
    if (this.state.subMenu === "configuration") {
      var body = <IncentiveConfiguration
        userSetting={this.state.userSetting}
        categories={this.props.categories}
        currency={this.props.currency}
        flipperFlags={this.props.flipper_flags}
        extraFeatures={this.props.extraFeatures}
        rules={this.state.rules}
        autoRespondSectionErrors={this.state.autoRespondSectionErrors}
        autoDeclinedSectionErrors={this.state.autoDeclinedSectionErrors}
        bicommercePlatform={this.props.bicommercePlatform}
        closeAutoRespondErrors={() => this.closeAutoRespondErrors()}
        handleIncentiveCofiguration={(incentive) => this.handleIncentiveCofiguration(incentive)}
        handleSKUChange={(e) => this.handleSKUChange(e)}
        handleCollectionsChange={(e) =>this.handleCollectionsChange(e)}
        handleTagsChange={(e) => this.handleTagsChange(e)}
        handleDisplayRuleType={(e) => this.handleDisplayRuleType(e)}
        handleRulesChange={(e) => this.handleRulesChange(e)}
        handlePhoneNumberChange={(e) => this.handlePhoneNumberChange(e)}
        handleSmsNotificationChange={(e) => this.handleSmsNotificationChange(e)}
        handleStorefrontProductsChange={(products) => this.handleStorefrontProductsChange(products)}
        handleExpirationChange={(e) =>this.handleExpirationChange(e)}
        handleMaximumOfferDiscount={(e) =>this.handleMaximumOfferDiscount(e)} 
        changeOfferDiscountType={(e) => this.changeOfferDiscountType(e)}/>
      var nextPage = <a onClick={() => this.handleSubMenuClick("look_and_feel")}
        className="continue"> Continue to customize the look and feel &raquo;</a>
    } else if (this.state.subMenu === "look_and_feel") {
      var body = <LookAndFeel
        userSetting={this.state.userSetting}
        flipperFlags={this.props.flipper_flags}
        themes={this.props.themes}
        themeSettings={this.state.themeSettings}
        extraFeatures={this.props.extraFeatures}
        timerDelayTypes={this.props.timerDelayTypes}
        handleThemeSettingChange={(e, field) => this.handleThemeSettingChange(e, field)}
        handleButtonTextChange={(e) => this.handleButtonTextChange(e)}
        handleButtonFontColorChange={(color) => this.handleButtonFontColorChange(color)}
        handleButtonBackgroundColorChange={(color) => this.handleButtonBackgroundColorChange(color)}
        handleTimedOverlayFontColorChange={(color) => this.handleTimedOverlayFontColorChange(color)}
        handleTimedOverlayBackgroundColorChange={(color) => this.handleTimedOverlayBackgroundColorChange(color)}
        handleTimedOverlayTextChange={(e) => this.handleTimedOverlayTextChange(e)}
        handleTimedButtonTextChange={(e) => this.handleTimedButtonTextChange(e)}
        handleTimedOverlayPosition={(e) => this.handleTimedOverlayPosition(e)}
        handleTimedOverlayOpacity={(e) => this.handleTimedOverlayOpacity(e)}
        handleTimedOverlayDelay={(e) => this.handleTimedOverlayDelay(e)}
        handleCartPageNoticeTextChange={(e) => this.handleCartPageNoticeTextChange(e)}
        handleCartPageNoticeColorChange={(color) => this.handleCartPageNoticeColorChange(color)}
        handleCartPageNoticeBackgroundColorChange={(color) => this.handleCartPageNoticeBackgroundColorChange(color)}
        handleCartPageNoticeCssChange={(e) => this.handleCartPageNoticeCssChange(e)}
        handleModalCssChange={(e) => this.handleModalCssChange(e)}
        handleThemeChange={(e) => this.handleThemeChange(e)}
        handlePrivatePolicyLinkChange={(e) => this.handlePrivatePolicyLinkChange(e)}
        handleTermsOfServiceLinkChange={(e) => this.handleTermsOfServiceLinkChange(e)}
        handleCustomTermsTextChange={(e) => this.handleCustomTermsTextChange(e)}
        handleGoogleAnalyticeCode={(e) => this.handleGoogleAnalyticeCode(e)} 
        handleOfferButtonFont={(e) => this.handleOfferButtonFont(e)}
        handleOfferTitleFont={(e) => this.handleOfferTitleFont(e)}
        handleOfferSubTitleFont={(e) => this.handleOfferSubTitleFont(e)}
        handleOfferLabelsFont={(e) => this.handleOfferLabelsFont(e)}/>
      var nextPage = <a onClick={() => this.handleSubMenuClick("email_copies")} className="continue"> Continue to customize the emails sent to customers &raquo;</a>
    } else if (this.state.subMenu === "email_copies") {
      var body = <EmailCopy
        userSetting={this.state.userSetting}
        flipperFlags={this.props.flipper_flags}
        initialUserEmail={this.props.initialUserEmail}
        initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
        bicommercePlatform={this.props.bicommercePlatform}
        handleEmailCopySubjectChange={(type, value) => this.handleEmailCopySubjectChange(type, value)}
        handleEmailCopyBodyChange={(type, value) => this.handleEmailCopyBodyChange(type, value)}
        handleEmailCopyBccMyselfChnage={(show_bcc_myself) => this.handleEmailCopyBccMyselfChnage(show_bcc_myself)}
        handleSenderEmailChange={(e) => this.handleSenderEmailChange(e)}
        handleSendManualOfferReviewMailChnage={(show_offer_email) => this.handleSendManualOfferReviewMailChnage(show_offer_email)}
        handleCustomerEmailsCssChange={(e) => this.handleCustomerEmailsCssChange(e)} />
      var nextPage = ""

    }
    return (
      <div className="user_setting row">
        <ul className="col-sm-12 sub-menu">
          <li>
            <a
              href="#"
              className={this.state.subMenu === "configuration" ? "active" : ""}
              onClick={() => this.handleSubMenuClick("configuration")}
            >
              Incentive Configuration
              </a>
          </li>
          <li>
            <a
              href="#"
              id="look_and_feel_tab"
              className={this.state.subMenu === "look_and_feel" ? "active" : ""}
              onClick={() => this.handleSubMenuClick("look_and_feel")}
            >
              Look & Feel
              </a>
          </li>
          <li>
            <a href="#"
              id="email_copies"
              className={this.state.subMenu === "email_copies" ? 'active' : ''}
              onClick={() => this.handleSubMenuClick("email_copies")} >
              Email Copies
              </a>
          </li>
        </ul>

        <div className="col-sm-12">
          {this.state.alertMessage && this.state.alertMessage !== ""
            ? notification
            : ""}
        </div>

        <div className="col-sm-12">
          {body}
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <div className="actions">
              <button onClick={() => this.handleSubmitButton()} className="btn btn-primary">Save Settings</button>
              {nextPage}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserSetting.propTypes = {
  initialUserSetting: PropTypes.string,
  theme_settings: PropTypes.array,
  themes: PropTypes.array,
}

export default UserSetting
