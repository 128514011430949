import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalSuccessPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background,
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  successHeading () {
    return this.props.themeSettings.data.success_heading || this.props.defaultTexts.success_heading;
  }

  successContent () {
    return this.props.themeSettings.data.success_content || this.props.defaultTexts.success_content;
  }

  successGreeting () {
    return this.props.themeSettings.data.success_greeting || this.props.defaultTexts.success_greeting;
  }

  successButton () {
    return this.props.themeSettings.data.success_button || this.props.defaultTexts.success_button;
  }

  render () {
    return (
      <div className="container mf_sidebar_container minimal">
        <div className="mf_container">
          <div className="success_container" style={{ display: 'block' }}>
            <a className="close close_iframe" style={{ opacity: '1', color: '#F7F7F7' }}>×</a>
            <div className="success response_block" style={this.divHeaderStyle()}>
              <h4>{this.successHeading()}</h4>
              <h4>{this.successContent()}</h4>
              <h4>{this.successGreeting()}</h4>
              <a className="submit-button close_iframe" style={this.divButtonStyle()}>
                {this.successButton()}
              </a>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalSuccessPreview
