import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

class MinimalSuccessPopupPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background,
      display: 'block',
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  successHeading () {
    return this.props.themeSettings.data.success_heading || this.props.defaultTexts.success_heading;
  }

  successContent () {
    return this.props.themeSettings.data.success_content || this.props.defaultTexts.success_content;
  }

  successGreeting () {
    return this.props.themeSettings.data.success_greeting || this.props.defaultTexts.success_greeting;
  }

  successButton () {
    return this.props.themeSettings.data.success_button || this.props.defaultTexts.success_button;
  }

  render () {
    return (
      <div className="container mf_popup_container minimal">
        <div className="popup">
          <a className="close close_iframe" style={{ opacity: '1' }}>×</a>
          <div className="mf_response_container" style={this.divHeaderStyle()}>
            <div className="success_container" style={{ display: 'block' }}>
              <div className="success response_block" style={this.divHeaderStyle()}>
                <h4>{this.successHeading()}</h4>
                <h4>{this.successContent()}</h4>
                <h4>{this.successGreeting()}</h4>
                <a className="submit-button close_iframe" style={this.divButtonStyle()}>
                  {this.successButton()}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalSuccessPopupPreview
