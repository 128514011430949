import { h } from 'preact'
import React from "react"
import PopupPagination from '../base/PopupPagination'

class OffersHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offers: this.props.offers,
      page: 0,
      rowsPerPage: 10,
    }
  }

  hide () {
    $("#" + this.modal_id()).modal('hide');
  }

  componentDidMount () {
    $("#" + this.modal_id()).modal('show');

    $("#" + this.modal_id()).on('hidden.bs.modal', () => {
      if (this.props.onCloseModal) {
        this.props.onCloseModal();
      }
    });
  }

  modal_id () {
    return this.props.offer.id + "_offer";
  }
 
  handleChangeOfferPage = (data) => {
    this.setState({page: data.selected})
  }
  
  hideError(e) {
    this.setState({ error: "" });
    e.preventDefault();
  }


  modalContent () {
    const {page, rowsPerPage } = this.state
    const pageCount = this.props.offers.length / rowsPerPage;
    var self = this
    var offers = [];
    this.props.offers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach(function(offer, index) {
      offers.push(
        <tr key={index}>
          <td>{offer.created_at}</td>
          <td>
            {offer.email}{" "}
          </td>
          <td>
            <a target="_blank" href={offer.product.url}>
              {offer.product.title}
            </a>
          </td>
          <td>{offer.product.price}</td>
          <td>{offer.amount_with_currency}</td>
          <td className="capitalize">
            {offer.status}
          </td>
        </tr>
      )
    })

    return (
      <div className="modal-content">
        <div className="modal-header ">
          <a className="glyphicon glyphicon-remove" onClick={() => this.hide()}></a>
          <h4 className="modal-title capitalize">Offers History</h4>
        </div>
        <div className="modal-body">
          <h3 className="center">You have several offers from this user.</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Date (EST)</th>
                <th>Email</th>
                <th>Product</th>
                <th>Price</th>
                <th>Offer Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {offers}
            </tbody>
          </table>
          <div className="text-center combined-paginate">
            <PopupPagination 
              pageCount={pageCount}
              handleChangeOfferPage={this.handleChangeOfferPage}
            />
          </div>
        </div>
        <div className="modal-footer combine-footer">
        </div>
      </div>
    )
  }

  

  render () {
    return (
      <div id={this.modal_id()} className="modal" >
        <div className="modal-dialog modal-lg">
          {this.modalContent()}
        </div>
      </div>
    )
  }
}

export default OffersHistory;
