export const googleFonts = [
  {name: 'Open Sans'},
  {name: 'Times New Roman'},
  {name: 'Libre Baskerville'},
  {name: 'Montserrat'},
  {name: 'Nunito Sans'},
  {name: 'Kalam'},
  {name: 'Source Sans Pro'},
  {name: 'Poppins'},
  {name: 'Playfair Display'},
  {name: 'Fjalla One'},
  {name: 'Italiana'},
  {name: 'Twentieth Century'},
  {name: 'Roboto'},
  {name: 'Lato'},
  {name: 'Oswald'},
  {name: 'Raleway'},
  {name: 'PT Sans'},
  {name: 'Cantata One'},
  {name: 'Merriweather'},
  {name: 'Lora'},
  {name: 'Domine'},
  {name: 'Ubuntu'},
  {name: 'Rubik'},
  {name: 'Inconsolata'},
  {name: 'Rancho'},
  {name: 'Abril Fatface'},
  {name: 'Bitter'},
  {name: 'Istok Web'},
  {name: 'Libre Franklin'},
  {name: 'Barlow'},
  {name: 'Exo'},
  {name: 'Overpass'},
  {name: 'Cutive Mono'},
  {name: 'Cabin'},
  {name: 'Arvo'},
  {name: 'Notable'},
  {name: 'Mukta'},
  {name: 'Cousine'},
  {name: 'PT Mono'},
  {name: 'Nanum Gothic'},
  {name: 'Quicksand'},
  {name: 'Comfortaa'},
  {name: 'Pacifico'},
  {name: 'Dancing Script'},
  {name: 'Heebo'},
  {name: 'Asap'},
  {name: 'Shadows Into Light'},
  {name: 'Architects Daughter'},
  {name: 'Space Mono'},
  {name: 'Varela Round'},
  {name: 'Sansita One'},
];


