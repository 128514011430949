import React, { Component } from "react";
import ReactPaginate from 'react-paginate';

class PopupPagination extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    const {pageCount, handleChangeOfferPage} = this.props
    return (
      <ReactPaginate
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={3}
        onPageChange={handleChangeOfferPage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'prev-pagination'}
        nextClassName={'next-pagination'}
      />
    )
  }
}

export default PopupPagination
