import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import ExitOfferPreview from '../base/ExitOfferPreview'

class MinimalPopupPreview extends ExitOfferPreview {
  constructor(props) {
    super(props);
  }

  offerAmountField() {
    return this.props.themeSettings.data.hint_amount || this.props.defaultTexts.hint_amount
  }

  divButtonStyle() {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  backgroundStyle() {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainColorStyle() {
    return {
      color: this.props.themeSettings.data.header_background_color || this.props.colors.background
    };
  }

  mainHeadingTitleStyle() {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle() {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  closeStyles() {
    return {
      opacity: 1,
      textShadow: 'none'
    }
  }

  privatePolicyCheckboxMinimal() {
    if (this.props.themeSettings.data.show_gdpr && this.props.themeSettings.data.show_terms ) {
      return (
        <div className="private_policy minimal_policy">
          <input type="checkbox" />
          { !!this.props.userSetting.custom_terms_text ?
              <span dangerouslySetInnerHTML={{ __html: this.props.userSetting.custom_terms_text}}/>
            :
              <span>
                <span>I agree to </span>
                <a href={this.props.userSetting.terms_of_service} target='_blank'>Terms Of Service</a>
                <span className="space-left-right">&</span>
                <a href={this.props.userSetting.private_policy} target='_blank'>Private Policy</a>
              </span>
          }
        </div>
      )
    }else if(this.props.themeSettings.data.show_gdpr) {
      return (
        <div className="private_policy minimal_policy">
          <input type="checkbox" />
          <span>I agree to </span>
          <a href={this.props.userSetting.private_policy} target='_blank'>Private Policy</a>
        </div>
      )
    }else if(this.props.themeSettings.data.show_terms) {
      return (
        <div className="private_policy minimal_policy">
          <input type="checkbox" />
          { !!this.props.userSetting.custom_terms_text ?
              <span dangerouslySetInnerHTML={{ __html: this.props.userSetting.custom_terms_text}}/>
            :
              <span>
                <span>I agree to </span>
                <a href={this.props.userSetting.terms_of_service} target='_blank'>Terms Of Service</a>
              </span>
          }
        </div>
      )
    }
  }

  render() {
    const { userSetting } = this.props
    var newArrayFont = [userSetting.offer_title_font,userSetting.offer_subtitle_font, userSetting.offer_labels_font, userSetting.offer_btn_font].filter(Boolean);
    let filter_font = [...new Set(newArrayFont && newArrayFont)]
    let append_font = null;
    filter_font && filter_font.map((font, i) => {
      if(font !== "Twentieth Century") {
        const elem = document.getElementById(i);
        if(elem) { elem.remove()}
        append_font = document.createElement('link');
        append_font.rel='stylesheet'
        append_font.id = i
        document.head.appendChild(append_font)
        append_font.href = `https://fonts.googleapis.com/css2?family=${font}:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap`
      }
    })
    return (
        <div className="container mf_popup_container">
          <div className="popup">
            <a href="" className="close close-iframe" style={this.closeStyles()}>×</a>
            <div className="column up_section">
              <div className="screen up_screen active">
                <img src="/assets/product.png" alt="" className="variant-image" />
                <div className="title">
                  <h5>Product -
                    <span className="offer_price" style={this.mainColorStyle()}> $100.00 USD</span>
                  </h5>
                </div>
                <div className={
                  this.props.themeSettings.data.show_quantity
                    ? "field-new"
                    : "hidden"
                }>
                  <input
                    className="quantity_field"
                    name="quantity"
                    defaultValue="1"
                    type="number"
                    ref={(el) => {
                      if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                    }}
                  />
                  <div className="quantity_label">{this.quantityField()}</div>
                </div>
              </div>
            </div>
            <div className="column form_section" style={this.backgroundStyle()}>
              <form>
                <div className="screen down_screen active">
                    <h4 style={this.mainHeadingTitleStyle()} ref={(el) => {
                      if (el) {el.style.setProperty('font-family', userSetting.offer_title_font, 'important')}
                    }}>
                      {this.props.themeSettings.data.main_heading || this.props.defaultTexts.main_heading}
                    </h4>
                  <h5 style={this.secondaryHeadingTitleStyle()} ref={(el) => {
                      if (el) {el.style.setProperty('font-family', userSetting.offer_subtitle_font, 'important')}
                    }}>
                    {this.props.themeSettings.data.secondary_heading || this.props.defaultTexts.secondary_heading}
                  </h5>
                 
                  <div className="field amount_field">
                    <input
                      className="mf_offer_amount"
                      id="offers_amount"
                      name="offers[][amount]"
                      placeholder="$100.00 USD"
                      type="number"
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                    <div className="amount_label">
                      <span className="label_text" ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}>{this.offerAmountField()}</span>
                    </div>
                  </div>
                  <div className="field">
                    <input
                      className="mf_email"
                      id="mf_email"
                      name="email"
                      placeholder={this.emailField()}
                      type="text"
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                  </div>
                  <div
                    className={
                      this.props.themeSettings.data.show_phone_number
                        ? "field"
                        : "field hidden"
                    }
                  >
                    <input
                      id="mf_phone_number"
                      name="phone"
                      placeholder={this.phoneNumberField()}
                      type="number"
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                  </div>

                  <div
                    className={
                      this.props.themeSettings.data.show_name
                        ? "field"
                        : "field hidden"
                    }
                  >
                    <input
                      id="mf_name"
                      name="name"
                      placeholder={this.customerNameField()}
                      type="text"
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                  </div>

                  <div
                    className={
                      this.props.themeSettings.data.show_shipping_address
                        ? "field"
                        : "field hidden"
                    }
                  >
                    <input
                      id="shipping_address"
                      name="shipping_address"
                      placeholder={this.shippingField()}
                      rows="3"
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                  </div>
                  <div
                    className={
                      this.props.themeSettings.data.show_notes
                        ? "field"
                        : "field hidden"
                    }
                  >
                    <input
                      className="notes-field"
                      id="notes_to_seller"
                      name="notes_to_seller"
                      placeholder={this.noteField()}
                      style={this.backgroundStyle()}
                      ref={(el) => {
                        if (el) {el.style.setProperty('font-family', userSetting.offer_labels_font, 'important')}
                      }}
                    />
                  </div>
                  <button
                    className="submit-button"
                    name="commit"
                    type="submit"
                    defaultValue={this.sendOfferButton()}
                    style={this.divButtonStyle()}
                    ref={(el) => {
                      if (el) {el.style.setProperty('font-family', userSetting.offer_btn_font, 'important')}
                    }}
                  >
                    {this.sendOfferButton()}
                  </button>
                  {this.privatePolicyCheckboxMinimal()}
                </div>
              </form>
            </div>
          </div>
        </div>
    );
  }
}

export default MinimalPopupPreview
