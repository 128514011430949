import React from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom';

class CartPageNoticeForm extends React.Component {
  constructor(props) {
    super(props);
    this.cartPageNoticeColor = React.createRef();
    this.cartPageNoticeBackgroundColor = React.createRef();
  }

  componentDidMount() {
    var spectrumDefaultOptions = {
      allowEmpty:true,
      showInput: true,
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "hex"
    }

    $(ReactDOM.findDOMNode(this.cartPageNoticeColor.current)).spectrum($.extend({}, spectrumDefaultOptions, {
      color: this.props.userSetting.cart_page_notice_color,
      change: this.props.handleCartPageNoticeColorChange,
      hide: this.props.handleCartPageNoticeColorChange,
      move: this.props.handleCartPageNoticeColorChange
    } ));

     $(ReactDOM.findDOMNode(this.cartPageNoticeBackgroundColor.current)).spectrum($.extend({}, spectrumDefaultOptions, {
      color: this.props.userSetting.cart_page_notice_background_color,
      change: this.props.handleCartPageNoticeBackgroundColorChange,
      hide: this.props.handleCartPageNoticeBackgroundColorChange,
      move: this.props.handleCartPageNoticeBackgroundColorChange
    }));
  }

  render() {
    return (
      <div className='user-setting-form'>
        <div className="form-group">
          <label className="color-label">Notice Text:</label>
          <input type="text"
            value={this.props.userSetting.cart_page_notice_text}
            onInput={(e) => this.props.handleCartPageNoticeTextChange(e)}
            className="form-control"/>
        </div>

        <div className="form-group">
          <label className="color-label">Font Color:</label>
          <input type="text" ref={this.cartPageNoticeColor}
          defaultValue={this.props.userSetting.cart_page_notice_color}
          className="form-control"/>
        </div>

        <div className="form-group">
          <label className="color-label">Background Color:</label>
          <input type="text" ref={this.cartPageNoticeBackgroundColor}
            defaultValue={this.props.userSetting.cart_page_notice_background_color}
            className="form-control"/>
        </div>

        <div className="form-group">
          <label className="color-label">Custom CSS:</label>
          <textarea className="form-control"
            defaultValue={this.props.userSetting.cart_page_notice_css}
            onChange={(e) => this.props.handleCartPageNoticeCssChange(e)}
            placeholder=""></textarea>
        </div>
      </div>
    )
  }
}

export default CartPageNoticeForm
