import React from "react"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import Help from 'components/base/Help'
import AutoCheckoutRule from './AutoCheckoutRule'
import SmsNotification from './SmsNotification'
import Promotion from './Promotion'
import AlertNotification from 'components/base/AlertNotification'

class IncentiveConfiguration extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLabelForWindowShopperExitOffer() {
    return (
       <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.exit_offer.label")}
        <Help text={I18n.t("admin.window_shopper.exit_offer.help")}/>
      </label>
    )
  }

  renderLabelForWindowShopperTimedOverlay() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.timed_overlay.label")}
        <Help text={I18n.t("admin.window_shopper.timed_overlay.help")} />
      </label>
    )
  }

  renderLabelForWindowMessageOverlay() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.overlay_message.label")}
        <Help text={I18n.t("admin.window_shopper.overlay_message.help")} />
      </label>
    )
  }

 renderLabelForWindowShopperButton() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.button.label")}
        <Help text={I18n.t("admin.window_shopper.button.help")} />
      </label>
    )
  }

  renderLabelForCheckVariantQuantity() {
    return (
      <label style={{display: "inline"}}>
        {I18n.t("admin.window_shopper.check_variant_quantity.label")}
        <Help text={I18n.t("admin.window_shopper.check_variant_quantity.help")} />
      </label>
    )
  }

  renderLabelForCartAbondmentExitOffer() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.exit_offer.label")}
        <Help text={I18n.t("admin.window_shopper.exit_offer.help")} />
      </label>
    )
  }

  renderLabelForCartOverlayMessage() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.overlay_message.label")}
        <Help text={I18n.t("admin.window_shopper.overlay_message.help")} />
      </label>
    )
  }

  renderLabelForCartAbondmentTimedOverlay() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.timed_overlay.label")}
        <Help text={I18n.t("admin.window_shopper.timed_overlay.help")} />
      </label>
    )
  }

  renderLabelForWindowShopperCombineOverlay() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.combine_overlay.label")}
        <Help text={I18n.t("admin.window_shopper.combine_overlay.help")} />
      </label>
    )
  }

  renderLabelForEnableAutoDecline() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.window_shopper.enable_auto_declined.label")}
      </label>
    )
  }

  addNewRule() {
    var rules = this.props.rules
    rules.push({
      id: Date.now(),
      filter_type: 1,
      discount_type: "percentage",
      condition: '',
      discount: '',
      counter_offer: '',
      action: 1,
      new: true,
      auto_fill_counter: true,
      error: {}
    })
    this.props.handleRulesChange(rules)
  }

  removeRule(rule) {
    var rules = this.props.rules;
    var index = rules.indexOf(rule);
    rules[index]._destroy = '1';
    this.props.handleRulesChange(rules)
    if (rules.filter(r => r._destroy !== '1').length == 0) {
      var products = this.props.userSetting.storefront_products
      products.forEach(p => p._destroy = '1')
      this.props.handleStorefrontProductsChange(products)
    }
  }

  changeRule(rule) {
    var rules = this.props.rules;
    var index = rules.map(function(item) { return item.id; }).indexOf(rule.id);
    rules[index] = rule;
    this.props.handleRulesChange(rules)
  }

  combineOverlaySwitcher () {
    if (this.props.flipperFlags.customer_combine) {
      return (
        <div className="incentive-selection">
          <div className="form-group">
            <div className="checkbox">
              <SwitchButton name="windowshopper-combine_overlay"
                checked={this.props.userSetting.show_combine_overlay}
                onChange={() => this.props.handleIncentiveCofiguration("show_combine_overlay")}
                labelRight={this.renderLabelForWindowShopperCombineOverlay}
                css_id="show_combine_overlay"/>
            </div>
          </div>
        </div>
      )
    }
  }

  rulesSection () {
    var notification = <AlertNotification
      handleClick={() => this.props.closeAutoRespondErrors()}
      alertMessage={this.props.autoRespondSectionErrors}
      alertType="danger" />
    return (
      <div className="col-sm-12 auto-checkout" id="auto_respond_section">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h1 id="auto_checkout_heading">{I18n.t("admin.auto_checkout.heading", { step: this.props.bicommercePlatform ? 2 : 3 })}</h1>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                {this.props.autoRespondSectionErrors && this.props.autoRespondSectionErrors !== ""
                  ? notification
                  : ""}
              </div>
            </div>
            {this.combineOverlaySwitcher()}
            <div className="incentive-configuration">
              <div className="incentive-selection">
                <h3 className="explanation">{I18n.t("admin.auto_checkout.explanation")}</h3>
                {this.props.rules.map(function (rule, index) {
                  if (rule._destroy) {
                    return false
                  } else {
                    return (
                      <AutoCheckoutRule
                        key={index}
                        rule={rule}
                        currency={this.props.currency}
                        extraFeatures={this.props.extraFeatures}
                        flipperFlags={this.props.flipperFlags}
                        changeRule={(rule) => this.changeRule(rule)}
                        removeRule={(rule) => this.removeRule(rule)}
                      />
                    )
                  }
                }.bind(this))}
                <a className="add-rule-btn" onClick={() => this.addNewRule()}>
                  {I18n.t("admin.auto_checkout.add_rule")}
                </a>
                <p className="note">
                  <i className="fa fa-info-circle help-icon"></i>
                  {/* <span className="glyphicon glyphicon-info-sign help-icon"></span> */}
                  {I18n.t("admin.auto_checkout.note")}
                </p>
                {
                  this.props.flipperFlags.promotion && this.props.rules.filter((r) => r._destroy != '1').length ?
                    <Promotion
                      userSetting={this.props.userSetting}
                      categories={this.props.categories}
                      handleStorefrontProductsChange={(products) => this.props.handleStorefrontProductsChange(products)}
                    /> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  maximumOfferDiscount () {
    var notification = <AlertNotification
      handleClick={() => this.props.closeAutoRespondErrors()}
      alertMessage={this.props.autoDeclinedSectionErrors}
      alertType="danger" />
    return (
      <div className="col-sm-12 auto-checkout" id="auto_respond_section">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h1 id="auto_checkout_heading">{I18n.t("admin.auto_declined.heading", { step: this.props.bicommercePlatform ? 3 : 4 })}</h1>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                {this.props.autoDeclinedSectionErrors && this.props.autoDeclinedSectionErrors !== ""
                  ? notification
                  : ""}
              </div>
            </div>
            <div className="incentive-configuration">
              <div className="incentive-selection">
                <h3 className="explanation">{I18n.t("admin.auto_declined.explanation")}</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-inline">
                      <div className="form-group">
                        <div className="checkbox">
                          <SwitchButton name='windowshopper-autodecline'
                            checked={this.props.userSetting.enable_auto_declined}
                            onChange={() => this.props.handleIncentiveCofiguration("enable_auto_declined")}
                            labelRight={this.renderLabelForEnableAutoDecline}
                            css_id="autodecline"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Max Offer Discount type:</label>
                        <input className="form-control filter-type"
                          disabled
                          value="Percentage"
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label rule-label"
                          dangerouslySetInnerHTML={{ __html: I18n.t("admin.auto_declined.rule.declined_before") }}>
                        </label>
                        <input
                          name="max_offer_discount"
                          type="text"
                          defaultValue={this.props.userSetting.max_offer_discount}
                          placeholder="Maximum Offer Discount"
                          onInput={(e) => this.props.handleMaximumOfferDiscount(e)}
                          className={this.props.autoDeclinedSectionErrors ? "form-control rule-price-condition error-field" : "form-control rule-price-condition"}
                        />
                        <label className="col-form-label rule-label">
                          {I18n.t("admin.auto_declined.rule.declined_after")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>       
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showTriggerOverlayMessage () {
    if (this.props.flipperFlags.overlay_message) {
      return (<div className="form-group">
        <div className="checkbox">
          <SwitchButton name='windowshopper-overlaymessage'
            checked={this.props.userSetting.product_overlay_message}
            onChange={() => this.props.handleIncentiveCofiguration("product_overlay_message")}
            labelRight={this.renderLabelForWindowMessageOverlay}
            css_id="overlay_message"
          />
        </div>
      </div>)
    }
  }

  showTriggerCartOverlayMessage () {
    if (this.props.flipperFlags.overlay_message) {
      return (<div className="form-group">
        <div className="checkbox">
          <SwitchButton name='cartabondment-overlaymessage'
            checked={this.props.userSetting.cart_overlay_message}
            onChange={() => this.props.handleIncentiveCofiguration("cart_overlay_message")}
            css_id="cart_overlay_message"
            labelRight={this.renderLabelForCartOverlayMessage}
          />
        </div>
      </div>)
    }
  }

  showUpgradePlan () {
    $('#settings-btn').click();
    $('html, body').animate({
      scrollTop: $(".plan").offset().top
    }, 1000);
  }

  renderExpirationSelect() {
    if (!this.props.bicommercePlatform) {
      return(
        <div className="offers-expires">
          <h3>{I18n.t("admin.window_shopper.explanation3")}</h3>
          <select className="form-control"
            value={this.props.userSetting.expiration}
            onChange={(e) => this.props.handleExpirationChange(e)}>
            <option value="0">no expiration date</option>
            <option value="15">15 minutes</option>
            <option value="120">2 hours</option>
            <option value="360">6 hours</option>
            <option value="720">12 hours</option>
            <option value="1">1 day</option>
            <option value="2">2 days</option>
            <option value="3">3 days</option>
            <option value="5">5 days</option>
            <option value="7">7 days</option>
          </select>
          <div class="expiration_div">
            <p>
              <i className="fa fa-info-circle help-icon"></i>
              Please note that the discount code generated on an offer you make will apply to the item until the offer has expired or has been cancelled, even if the underlying price of the item changes (ie. an offer at $200 on a $400 product with no expiration date, will allow a customer to purchase it for $150 should you change the item price to $300).
            </p>
            <p>
              So please be mindful of outstanding offers that have not expired on an item before changing the price. Outstanding accepted offers can always be cancelled from the offers tab.
            </p>
          </div>
        </div>
      )
    }
  }

  renderCartStep() {
    if (this.props.bicommercePlatform) return;
    return(
      <div className="col-sm-12 cart-abondoner">
        <div className="ibox float-e-margins ">
          <div className="ibox-title">
            <h1> {I18n.t("admin.cart_abondment.heading")}</h1>
          </div>
          <div className="ibox-content">
            <div className="incentive-configuration">
              <div className="incentive-selection">
                <h3>{I18n.t("admin.cart_abondment.explanation")}</h3>
                <div className="form-group">
                  <div className="checkbox">
                    <SwitchButton name='cartabondment-exitoffer'
                      checked={this.props.userSetting.cart_exit_offer}
                      onChange={() => this.props.handleIncentiveCofiguration("cart_exit_offer")}
                      css_id="cart_exit_offer"
                      labelRight={this.renderLabelForCartAbondmentExitOffer}
                    />
                  </div>
                </div>
                {this.showTriggerCartOverlayMessage()}
                <div className="form-group">
                  <div className="checkbox">
                    <SwitchButton name='cartabondment-timedoverlay'
                      checked={this.props.userSetting.cart_timed_overlay}
                      onChange={() => this.props.handleIncentiveCofiguration("cart_timed_overlay")}
                      css_id="cart_timed_overlay"
                      labelRight={this.renderLabelForCartAbondmentTimedOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const tagsInput = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      <div> <input type="text"
             placeholder={I18n.t("admin.window_shopper.product_selection.tags_hint")} className="form-control"
             value={this.props.userSetting.tags}
             disabled={this.props.userSetting.display_rule_type == 1}
             onChange={(e) => this.props.handleTagsChange(e)}/></div>,

      <div className="lock-basic">
        <input type="text"
            className="form-control"
            placeholder={I18n.t("admin.window_shopper.product_selection.tags_hint")}
            disabled={true}/>
        <i className="fa fa-lock"></i>
      </div>
    );

    const collectionsInput = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      <input type="text"
             placeholder={I18n.t("admin.window_shopper.product_selection.collections_hint")}
             className="form-control"
             disabled={this.props.userSetting.display_rule_type == 1}
             value={this.props.userSetting.collections}
             onChange={(e) => this.props.handleCollectionsChange(e)}/>,
      <div className="lock-basic">
        <input type="text"
              placeholder={I18n.t("admin.window_shopper.product_selection.collections_hint")}
              className="form-control"
              disabled={true} />
        <i className="fa fa-lock"></i>
      </div>
    );

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="explanation">
            {I18n.t("admin.explanation_incentive_config")}
          </div>
        </div>
        {/* Step 1 - Product page options */}
        <div className="col-sm-12 window-shopper">
          <div className="ibox float-e-margins ">
            <div className="ibox-title">
              <h1>{I18n.t("admin.window_shopper.heading")}</h1>
            </div>
            <div className="ibox-content">
              <div className="incentive-configuration">

                <div className="incentive-selection">
                  <h3>{I18n.t("admin.window_shopper.explanation1")}</h3>

                  <div className="form-group">
                    <div className="checkbox">
                      <SwitchButton name="windowshopper-exitoffer"
                        checked={this.props.userSetting.product_exit_offer}
                        onChange={() => this.props.handleIncentiveCofiguration("product_exit_offer")}
                        labelRight={this.renderLabelForWindowShopperExitOffer}
                        css_id="product_exit_offer"
                        />
                    </div>
                  </div>

                  {this.showTriggerOverlayMessage()}

                  <div className="form-group">
                    <div className="checkbox">
                      <SwitchButton name='windowshopper-timedoverlay'
                        checked={this.props.userSetting.product_timed_overlay}
                        onChange={() => this.props.handleIncentiveCofiguration("product_timed_overlay")}
                        labelRight={this.renderLabelForWindowShopperTimedOverlay}
                        css_id="timedoverlay"
                      />
                    </div>
                  </div>


                  <div className="form-group">
                   <div className="checkbox">
                      <SwitchButton name='windowshopper-button'
                        checked={this.props.userSetting.product_button}
                        onChange={() => this.props.handleIncentiveCofiguration("product_button")}
                        labelRight={this.renderLabelForWindowShopperButton}
                        css_id="offer-button"
                      />
                    </div>
                    <div className="form-group">
                      <div className="checkbox">
                        <SwitchButton name='windowshopper-check-variant'
                          checked={this.props.userSetting.check_variant_quantity}
                          onChange={() => this.props.handleIncentiveCofiguration("check_variant_quantity")}
                          labelRight={this.renderLabelForCheckVariantQuantity}
                          css_id="check_variant_quantity"
                        />
                      </div>
                      <div className="explanation" id="button-position-explanation">
                        <i dangerouslySetInnerHTML={{ __html: I18n.t("admin.window_shopper.button.explanation_2", { link_url: '/static/button_install_instructions' }) }} >
                        </i>
                      </div>
                    </div>
                    <div className="explanation" id="button-position-explanation">
                      <i dangerouslySetInnerHTML={{ __html: I18n.t("admin.window_shopper.button.explanation_2", { link_url: '/static/button_install_instructions' }) }} >
                      </i>
                    </div>
                  </div>


                </div>


                <div className="product-selection">
                  <div className="form-group" id="select-products">
                    <h3>{I18n.t("admin.window_shopper.explanation2")}</h3>
                    <select className="form-control"
                            value={this.props.userSetting.display_rule_type}
                            onChange={(e) => this.props.handleDisplayRuleType(e)}>
                      <option value="1">All Products</option>
                      <option value="2">
                        {supportsFeature("incentives", this.props.extraFeatures, "Show only on product skus, collections or tags selected below", "Show only on product skus")}
                      </option>
                      <option value="3">
                        {supportsFeature("incentives", this.props.extraFeatures, "Don't show on product skus, collections or tags selected below", "Don't show on product skus")}
                      </option>
                    </select>
                    <input type="text" placeholder={I18n.t("admin.window_shopper.product_selection.sku_hint")} className="form-control"
                    value={this.props.userSetting.skus} onChange={(e) => this.props.handleSKUChange(e)} disabled={this.props.userSetting.display_rule_type == 1} />
                    {collectionsInput}
                    {tagsInput}

                    <div className="explanation">
                      <i>{I18n.t("admin.window_shopper.product_selection.explanation")}
                        <Help text={I18n.t("admin.window_shopper.product_selection.help")}/>
                      </i>
                    </div>
                    {supportsFeature("incentives", this.props.extraFeatures, null,
                      <div className="explanation-lock">
                        <i className="fa fa-lock"></i> - {I18n.t("admin.window_shopper.product_selection.explanation_lock")} <span className="link_for_upgrade" onClick={() => this.showUpgradePlan()}>Click here</span> to upgrade
                      </div>)}

                  </div>
                </div>
                {this.renderExpirationSelect()}
              </div>
            </div>
          </div>
        </div>
        {/* Step 2 - Cart page options */}
        {this.renderCartStep()}
        {/* Step 3 - Auto checkout */}
        {this.rulesSection()}
        {this.maximumOfferDiscount()}
        {/* Step 4 - SMS notification */}
        { this.props.flipperFlags.sms_notification ?
          <SmsNotification
            userSetting={this.props.userSetting}
            handlePhoneNumberChange={(e) => this.props.handlePhoneNumberChange(e)}
            handleSmsNotificationChange={(e) => this.props.handleSmsNotificationChange(e)}/> : null }
    </div>
    )
  }
}

IncentiveConfiguration.propTypes = {

}

export default IncentiveConfiguration
