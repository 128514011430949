import React from "react"
import Header from '../Header'

class AutoCheckoutStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rule: {
        filter_type: '1',
        condition: '',
        discount: '',
        counter_offer: '',
        action: 1,
        new: true,
        error: {}
      },
      error: {
        discount: false,
        counter_offer: false,
        condition: false
      }
    }
  }

  changeRule (property, e) {
    this.state.rule[property] = e.target.value
    this.state.error[property] = false
    if (property == 'filter_type') {
      this.state.error.condition = false
    }
    this.setState({ error: this.state.error });
    this.props.handleChangeRule(this.state.rule)
  }

  renderCounterSettings () {
    return this.state.rule.action == 1 ?
      (<span>
        <label className="col-form-label rule-label">with</label>
        <input
          type="number"
          step="0.01"
          min="0.01"
          max="100"
          className={this.state.error.counter_offer ? "form-control rule-counter-offer error-field" : "form-control rule-counter-offer"}
          placeholder="enter %"
          defaultValue={this.state.rule.counter_offer}
          onInput={(e) => this.changeRule('counter_offer', e)} />
        <label className="col-form-label rule-label">
          off discount
        </label>
      </span>) : ""
  }

  addErrors () {
    var rule = this.state.rule
    if (rule.discount == '') {
      this.state.error.discount = true
    }
    if (rule.counter_offer == '') {
      this.state.error.counter_offer = true
    }
    if (rule.condition == '' && rule.filter_type == '1') {
      this.state.error.condition = true
    }
  }

  error () {
    if (this.props.showError) {
      this.addErrors();
      return(
        <div className="alert alert-danger" role="alert">
          Please fill the rule or
          skip this step click <strong onClick={() => this.props.skipCurrentStep()}>here</strong>.
        </div>
      )
    }
  }

  render () {
    var placeholer_for_condition = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      "SKU, Collections or Tags",
      "SKU>"
    )

    return (
      <div className="content">
        <Header title="Set up Auto checkout" />
        <div className="step_content row rules">
          <div className="col-sm-12 col-md-12 col-ld-12 mt-30">
            {this.error()}
            <div>
              <h3>What is Auto-checkout?</h3>
              <p>
                Auto-checkout allows you to automatically respond to customer offers based on discount parameters you set.  The customer receives
                an instant response to their offer and allows them to checkout the order immediately thereby significantly increasing conversion rates.
              </p>
            </div>
            <p className="items">1. Set your discount threshold.</p>
            <div className="rule_conditions form-inline">
              <div className="form-group">
                <label className="col-form-label rule-label">
                  <b>If</b> customer offer is
                </label>
                <input
                  type="number"
                  step="0.01"
                  min="0.01"
                  max="100"
                  className={this.state.error.discount ? "form-control rule-price-condition error-field" : "form-control rule-price-condition"}
                  defaultValue={this.state.rule.discount}
                  placeholder="enter %"
                  onInput={(e) => this.changeRule('discount', e)} />
                <label className="col-form-label rule-label">
                  less than product price then accept offer.
                </label>
              </div>
              <div className="form-group">
                <label className="col-form-label rule-label">
                    Otherwise,
                </label>
                <select className="form-control filter-type"
                  value={this.state.rule.action}
                  onChange={(e) => this.changeRule('action', e)}>
                  <option value="1">counter offer</option>
                  <option value="2">send email</option>
                </select>
                {this.renderCounterSettings()}
              </div>
            </div>
            <p className="items">2. Select which products you want the above discount threshold to apply on.</p>
            <div className="form-inline">
              <div className="form-group">
                <select className="form-control filter-type"
                  value={this.state.rule.filter_type}
                  onChange={(e) => this.changeRule('filter_type', e)}>
                  <option value="1">Auto-accept on select products</option>
                  <option value="2">Auto-accept on all products</option>
                </select>
                <input
                  type="text"
                  disabled={this.state.rule.filter_type == 2}
                  className={this.state.error.condition ? "form-control rule-condition error-field" : "form-control rule-condition"}
                  defaultValue={this.state.rule.condition}
                  onInput={(e) => this.changeRule('condition', e)}
                  placeholder={placeholer_for_condition}/>
              </div>
            </div>
            <p className="tip">
              You can create multiple discount threshold rules for different product types, in the
              "settings" page.  There are no limits!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoCheckoutStep
