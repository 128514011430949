import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import Help from 'components/base/Help'

import EmailCopyForm from './EmailCopyForm'
import EmailCopyPreview from './EmailCopyPreview'

class EmailCopy extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLabelForBCCMyself() {
    return (
      <label style={{display:"inline"}} className="bcc_myself_label">
        {I18n.t("admin.email_copy.bcc_myself_label")}
        <Help text={I18n.t("admin.email_copy.bcc_myself_hint")} />
      </label>
    )
  }

  renderLabelForReminderEmail() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.email_copy.reminder_email_label")}
        <Help text={I18n.t("admin.email_copy.reminder_email_hint")} />
      </label>
    )
  }

  renderLabelForSendManualOfferMail() {
    return (
      <label style={{display:"inline"}}>
        {I18n.t("admin.email_copy.send_manual_offer_label")}
      </label>
    )
  }

  senderInput () {
    return(
      <div className="col-sm-6">
        <label>Sender Email:</label>
        <input className="form-control"
          value={this.props.userSetting.sender_email}
          onChange={(e) => this.props.handleSenderEmailChange(e)}
          placeholder="Make an Offer <support@makeofferapp.com>"></input>
      </div>
    )
  }

  customerEmailsCssInput() {
    return(
      <div className="col-sm-6">
        <div className="form-group">
          <label>Custom CSS for Customer Emails:</label>
          <textarea className="form-control"
            defaultValue={this.props.userSetting.customer_emails_css}
            onChange={(e) => this.props.handleCustomerEmailsCssChange(e)}
            placeholder=".customer-emails__wrapper { };&#10;.customer-emails__container { };"></textarea>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 email-copy">
          <div className="ibox float-e-margins">
            <div id="placeholders-box" className="ibox-title">
              <h1>{I18n.t("admin.email_copy.heading")}</h1>
              <p>
                Write the subject of the emails with the following placeholder: <strong data-toggle="tooltip" title="The title/name of the product">[[PRODUCT_TITLE]]</strong>,
              </p>
              <p>
                Write the body of the emails with the following placeholders: <strong data-toggle="tooltip" title="The title/name of the product">[[PRODUCT_TITLE]]</strong>, <strong data-toggle="tooltip" title="The sku of the product">[[PRODUCT_SKU]]</strong>, <strong data-toggle="tooltip" title="The offer made by the customer">[[OFFER_AMOUNT]]</strong>, <strong data-toggle="tooltip" title="Checkout link to purchase the item">[[CHECKOUT]]</strong>, <strong data-toggle="tooltip" title="The counter offer">[[COUNTER]]</strong>, <strong data-toggle="tooltip" title="The quantity offer">[[QUANTITY]]</strong>
              </p>
              <p>
                These placeholders will be replaced with actual values before the email is sent to customers.
              </p>
            </div>
            {this.props.userSetting.allow_sender_email && this.senderInput()}
            <div className="col-sm-12">
              <div className="form-group">
                <div className="checkbox">
                  <SwitchButton name='bcc_myself'
                    checked={this.props.userSetting.bcc_myself}
                    onChange={() => this.props.handleEmailCopyBccMyselfChnage("bcc_myself")}
                    labelRight={this.renderLabelForBCCMyself}
                  />
                  {this.props.bicommercePlatform ? null :
                  <SwitchButton name='reminder_email'
                    checked={this.props.userSetting.reminder_email}
                    onChange={() => this.props.handleEmailCopyBccMyselfChnage("reminder_email")}
                    labelRight={this.renderLabelForReminderEmail}
                  />
                  }
                  <SwitchButton name='send_manual_offer_review_mail'
                    checked={this.props.userSetting.send_manual_offer_review_mail}
                    onChange={() => this.props.handleSendManualOfferReviewMailChnage("send_manual_offer_review_mail")}
                    labelRight={this.renderLabelForSendManualOfferMail}
                  />
                </div>
              </div>

            </div>
            {this.customerEmailsCssInput()}
            <div className="ibox-content">
              <div className="row">
                <h2>Email sent to customers when an offer is accepted</h2>
                <div className="col-sm-6">
                  <EmailCopyForm
                    type                         = 'accept'
                    userSetting                  = {this.props.userSetting}
                    handleEmailCopySubjectChange = {(type, value) => this.props.handleEmailCopySubjectChange(type, value)}
                    handleEmailCopyBodyChange    = {(type, value) => this.props.handleEmailCopyBodyChange(type, value)} />
                </div>
                <div className="col-sm-6">
                  <EmailCopyPreview
                    type        = 'accept'
                    initialUserEmail={this.props.initialUserEmail}
                    initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                    userSetting = {this.props.userSetting}
                  />
                </div>
              </div>
              <div className="row">
                <h2>Email sent to customers when an offer is countered</h2>
                <div className="col-sm-6">
                  <EmailCopyForm
                    type                         = 'counter'
                    userSetting                  = {this.props.userSetting}
                    handleEmailCopySubjectChange = {(type, value) => this.props.handleEmailCopySubjectChange(type, value)}
                    handleEmailCopyBodyChange    = {(type, value) => this.props.handleEmailCopyBodyChange(type, value)} />
                </div>
                <div className="col-sm-6">
                  <EmailCopyPreview
                    type        = 'counter'
                    initialUserEmail={this.props.initialUserEmail}
                    initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                    userSetting = {this.props.userSetting}
                  />
                </div>
              </div>
              <div className="row">
                <h2>Email sent to customers when an offer is declined</h2>
                <div className="col-sm-6">
                  <EmailCopyForm
                    type                         = 'decline'
                    userSetting                  = {this.props.userSetting}
                    handleEmailCopySubjectChange = {(type, value) => this.props.handleEmailCopySubjectChange(type, value)}
                    handleEmailCopyBodyChange    = {(type, value) => this.props.handleEmailCopyBodyChange(type, value)} />
                </div>
                <div className="col-sm-6">
                  <EmailCopyPreview
                    type        = 'decline'
                    initialUserEmail={this.props.initialUserEmail}
                    initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                    userSetting = {this.props.userSetting}
                  />
                </div>
              </div>
              <div className="row">
                <h2>Email sent to customers when offers are combine</h2>
                <div className="col-sm-6">
                  <EmailCopyForm
                    type                         = 'multiple'
                    userSetting                  = {this.props.userSetting}
                    handleEmailCopySubjectChange = {(type, value) => this.props.handleEmailCopySubjectChange(type, value)}
                    handleEmailCopyBodyChange    = {(type, value) => this.props.handleEmailCopyBodyChange(type, value)} />
                </div>
                <div className="col-sm-6">
                  <EmailCopyPreview
                    type        = 'multiple'
                    initialUserEmail={this.props.initialUserEmail}
                    initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                    userSetting = {this.props.userSetting}
                  />
                </div>
              </div>
              <div className="row">
                <h2>Email sent to customers when offer is manual approval</h2>
                <div className="col-sm-6">
                  <EmailCopyForm
                    type                         = 'new'
                    userSetting                  = {this.props.userSetting}
                    handleEmailCopySubjectChange = {(type, value) => this.props.handleEmailCopySubjectChange(type, value)}
                    handleEmailCopyBodyChange    = {(type, value) => this.props.handleEmailCopyBodyChange(type, value)} />
                </div>
                <div className="col-sm-6">
                  <EmailCopyPreview
                    type        = 'new'
                    initialUserEmail={this.props.initialUserEmail}
                    initialUserSecondaryEmail={this.props.initialUserSecondaryEmail}
                    userSetting = {this.props.userSetting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailCopy.propTypes = {

}

export default EmailCopy
