import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import Help from 'components/base/Help'

class SmsNotification extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLabelForSmsNotification() {
    return (
      <label style={{ display: "inline" }}>
        SMS Notification
      </label>
    )
  }

  render () {
    return (
      <div className="col-sm-12 auto-checkout">
        <div className="ibox float-e-margins ">
          <div className="ibox-title">
            <h1 id="sms_notification_heading">{I18n.t("admin.sms_notification.heading")}</h1>
          </div>
          <div className="ibox-content">
            <div className="incentive-configuration">
              <div className="incentive-selection">
                <h3 className="explanation">{I18n.t("admin.sms_notification.explanation")}</h3>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number:</label>
                      <input type="tel"
                        className="form-control" name='phone_number'
                        value={this.props.userSetting.phone_number}
                        onChange={(e) => this.props.handlePhoneNumberChange(e)} />
                    </div>
                    <div className="form-group">
                      <div className="checkbox">
                        <SwitchButton name="sms_notifcation"
                          checked={this.props.userSetting.sms_notification}
                          onChange={(e) => this.props.handleSmsNotificationChange(e)}
                          labelRight={this.renderLabelForSmsNotification}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SmsNotification.propTypes = {

}

export default SmsNotification
