import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'
import SwitchButton from 'components/base/SwitchButton'
import ExitOfferForm from '../base/ExitOfferForm'

class MinimalForm extends ExitOfferForm {
  constructor(props) {
    super(props);
  }

  useSidebarSwitcher () {
    return (
      <div className="form-group">
        <label className="color-label">Use Sidebar:</label>
        <SwitchButton
          name="use_sidebar"
          checked={this.props.themeSettings.data.use_sidebar}
          onChange={(e) => this.props.handleThemeSettingChange(e, 'use_sidebar')}
          css_id="use_sidebar_button"
        />
      </div>
    )
  }

  render() {
    return (
      <div className="user-setting-form">
        {this.useSidebarSwitcher()}
        {this.buttonFontColor()}
        {this.buttonBackgroundColor()}
        {this.mainHeadingColorField()}
        {this.secondaryHeadingColorField()}
        {this.headerBackgroundColorField('Background Color:')}


        {this.mainHeadingTitle()}
        {this.offerTitleGoogleFont()}
        {this.secondaryHeadingTitle()}
        {this.offerSubTitleGoogleFont()}
        {this.offerAmountField()}
        {this.offerEmailField()}
        {this.quantityFieldSelector()}
        {this.phoneNumberSwitcher()}
        {this.nameSwitcher()}
        {this.shippingSwitcher()}
        {this.noteSwitcher()}
        {this.offerLabelsFont()}
        {this.sendOfferButton()}
        {this.offerButtonGoogleFont()}
        {this.lockExplanation()}
        {this.termsSwitcher()}
        {this.gdprSwitcher()}
        {this.googleAnalyticSwitcher()}
      </div>
    );
  }
}

export default MinimalForm

