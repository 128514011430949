import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"

import Footer from './Footer'
import WelcomeStep from './steps/WelcomeStep'
import ButtonStep from './steps/ButtonStep'
import ExitOfferProductStep from './steps/ExitOfferProductStep'
import ExitOfferCartStep from './steps/ExitOfferCartStep'
import TimedOverlayStep from './steps/TimedOverlayStep'
import DisplayingStep from './steps/DisplayingStep'
import AutoCheckoutStep from './steps/AutoCheckoutStep'
import EndStep from './steps/EndStep'

class Onboarding extends React.Component {

  constructor(props) {
    super(props);
    var userSetting = this.props.initialUserSetting;
    if (typeof this.props.userSetting === 'string') {
      userSetting = JSON.parse(this.props.userSetting);
    }
    var currentStep = Number(Cookies.get('tour_current_step')) || 1
    this.state = {
      currentStep: currentStep,
      lastStep: 8,
      userSetting: userSetting,
      showError: false,
      steps: [
        WelcomeStep,
        ButtonStep,
        ExitOfferProductStep,
        ExitOfferCartStep,
        TimedOverlayStep,
        DisplayingStep,
        AutoCheckoutStep,
        EndStep
      ]
    };
  }

  componentDidMount() {
    // if (!Cookies.get('tour_disable')) {
    //   $('#onboarding_modal').modal('show');
    // }
    if (this.state.userSetting.face == 'onboarding') {
      $('#onboarding_modal').modal('show');
    }
    if (this.state.currentStep > 1) {
      var shift = '-=' + (this.state.currentStep * 100 - 100).toString() + '%'
      $('#modal-container').animate({
        'margin-left': shift
      }, 200);
    }
  }

  ruleInvalid() {
    var rule = this.state.userSetting.rules.find((rule) => rule.new)
    return !rule || rule.discount == '' || (rule.counter_offer == '' && rule.action == '1') || (rule.condition == '' && rule.filter_type == '1')
  }

  skipCurrentStep() {
    this.setState({ currentStep: this.state.currentStep + 1, showError: false })
    $('#modal-container').animate({
      'margin-left': '-=100%'
    }, 500);
    Cookies.set('tour_current_step', this.state.currentStep)
  }

  nextStep() {
    if (this.state.currentStep == 7 && this.ruleInvalid()) {
      this.setState({ showError: true });
      return
    }
    if (this.state.currentStep < this.state.lastStep) {
      this.setState({ currentStep: this.state.currentStep + 1 })
      $('#modal-container').animate({
        'margin-left': '-=100%'
      }, 500);
      Cookies.set('tour_current_step', this.state.currentStep)
    } else {
      this.state.userSetting.face = 'post_onboarding';
      this.setState({ userSetting: this.state.userSetting });
      $('#onboarding_modal').modal('hide');
    }
    this.saveSettings();
  }

  previousStep() {
    if (this.state.currentStep > 1) {
      this.setState({ currentStep: this.state.currentStep - 1, showError: false })
    }
    $('#modal-container').animate({
      'margin-left': '+=100%'
    }, 500);
    Cookies.set('tour_current_step', this.state.currentStep)
  }

  stepComponent(index) {
    var Step = this.state.steps[index]
    return <Step userSetting={this.state.userSetting}
      showError={this.state.showError}
      extraFeatures={this.props.extraFeatures}
      handleIncentiveConfiguration={(incentive) => this.handleIncentiveConfiguration(incentive)}
      handleConfiguration={(property, e) => this.handleConfiguration(property, e)}
      handleChangeRule={(rule) => this.handleChangeRule(rule)}
      skipCurrentStep={() => this.skipCurrentStep()}/>;
  }

  handleIncentiveConfiguration(incentive) {
    this.state.userSetting[incentive] = !this.state.userSetting[incentive];
    this.setState({ userSetting: this.state.userSetting });
  }

  handleConfiguration(property, e) {
    this.state.userSetting[property] = e.target.value;
    this.setState({ userSetting: this.state.userSetting });
  }

  handleChangeRule(newRule) {
    var rule = this.state.userSetting.rules.find((rule) => rule.new)
    if (!rule) {
      this.state.userSetting.rules.push(newRule)
    }
    rule = newRule
    this.setState({ userSetting: this.state.userSetting });
  }

  skipTour() {
    this.state.userSetting.face = 'post_onboarding';
    this.setState({ userSetting: this.state.userSetting });
    this.saveSettings(true)
  }

  saveSettings(skip = false) {
    var settings = $.extend({}, this.state.userSetting);
    settings.rules_attributes = this.state.userSetting.rules;
    settings.onboarding_tracking_attributes = {
      id: settings.onboarding_tracking && settings.onboarding_tracking.id,
      step: this.state.currentStep,
      finished: this.state.currentStep == 6 || this.state.currentStep == 8 ? new Date() : null,
      skip: skip ? new Date() : null
    };
    $.ajax({
      url: this.props.url + "/" + (this.state.userSetting.id || ""),
      dataType: "json",
      type: "POST",
      data: {
        _method: (this.state.userSetting.id ? 'PUT' : 'POST'),
        user_setting: settings
      },
      success: (data) => {
        this.setState({ userSetting: data });
        var event = new CustomEvent('triggerOnboarding', { 'detail': data });
        document.dispatchEvent(event);
      },
      error: (xhr, status, err) => {

      }
    })
  }

  footer() {
    return <Footer currentStep={this.state.currentStep}
                   lastStep={this.state.lastStep}
                   handleNextStep={() => this.nextStep()}
                   handlePreviousStep={() => this.previousStep()}
                   skipTour={() => this.skipTour()}
                   skipCurrentStep={() => this.skipCurrentStep()} />;
  }

  contentModal(index) {
    return(
      <div className="modal-dialog modal-lg" key={index}>
        <div className="modal-content">
          <div className={[2,3,4,5].includes(this.state.currentStep) ? 'modal-body padding-thero' : 'modal-body'}>
            {this.stepComponent(index)}
          </div>
          {this.footer()}
        </div>
      </div>
    )
  }

  render() {
    var content = [];
    for (var index in this.state.steps) {
      content.push(this.contentModal(index));
    }
    return (
      <div id="modal-container">
        {content}
      </div>
    );
  }
}

// Onboarding.propTypes = {
//   initialUserSetting: PropTypes.string
// };
export default Onboarding
