import { h } from 'preact'
import React from "react"
import Header from '../Header'
import SwitchButton from '../../base/SwitchButton'

class TimedOverlayStep extends React.Component {
  playVideo () {
    var myVideo = document.getElementById("video");
    if (myVideo.paused) {
      myVideo.play();
    }
    else {
      myVideo.pause();
    }
  }

  render () {
    return (
      <div className="content">
        <Header title="Choose Your Incentive Options" />
        <div className="step_content">
          <SwitchButton name='windowshopper-product_timed_overlay'
            checked={this.props.userSetting.product_timed_overlay}
            onChange={() => this.props.handleIncentiveConfiguration("product_timed_overlay")}
            labelRight={'Timed Overlay'}
          />
          <p className="description">
            This will show an overlay message if the customer is on the product page
            for a few seconds without purchasing the item.
          </p>
          <div className="video_wrapper">
            <video width="100%" height="220" controls id="video_4" onClick={() => this.playVideo()}>
              <source src="/assets/onboarding/Overlay.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default TimedOverlayStep
