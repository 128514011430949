import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class MinimalMixedPopupPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  divButtonStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
      color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont
    };
  }

  divHeaderStyle () {
    return {
      backgroundColor: this.props.themeSettings.data.header_background_color || this.props.colors.background,
      display: 'block',
      borderRadius: '10px'
    };
  }

  mainHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading
    };
  }

  secondaryHeadingTitleStyle () {
    return {
      color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading
    };
  }

  heading () {
    return this.props.themeSettings.data.mixed_heading || this.props.defaultTexts.mixed_heading;
  }

  content () {
    return this.props.themeSettings.data.mixed_content || this.props.defaultTexts.mixed_content;
  }

  greeting () {
    return this.props.themeSettings.data.mixed_greeting || this.props.defaultTexts.mixed_greeting;
  }

  button () {
    return this.props.themeSettings.data.mixed_button || this.props.defaultTexts.mixed_button;
  }

  render () {
    return (
      <div className="container mf_popup_container minimal">
        <div className="popup">
          <a className="close close_iframe" style={{ opacity: '1' }}>×</a>
          <div className="mf_response_container" style={this.divHeaderStyle()}>
            <div className="success_container" style={{ display: 'block' }}>
              <div className="success response_block" style={this.divHeaderStyle()}>
                <h4>{this.greeting()}</h4>
                <h4>{this.heading()}</h4>
                <h4>{this.content()}</h4>
                <a className="submit-button" style={this.divButtonStyle()}>
                  {this.button()}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MinimalMixedPopupPreview
