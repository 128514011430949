import React from "react"
import PropTypes from "prop-types"

class RequestLike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voted: props.voted
    };
  }

  handleClick(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/feature_requests/' + this.props.requestId + '/likes',
      success: () => {
        this.setState({ voted: true });
        const likeCountEl = $("#likes-count-" + this.props.requestId);
        let count = parseInt(likeCountEl.text());
        likeCountEl.text(count + 1);
      },
      error(xhr, status, err) {
        console.error('/feature_requests/', status, err.toString());
      }
    });
  }

  render() {
    return (
      <div className="">
        {this.state.voted ?
          <p className="text-success" style={{ float: 'right' }}>Thanks for vote!</p>
          : <a style={{ fontSize: '24px', float: 'right' }} onClick={(e) => this.handleClick(e)} >
            <i className="fa fa-thumbs-up"></i>
          </a>
        }
      </div>
    );
  }
}

RequestLike.propTypes = {
  voted: PropTypes.bool,
  requestId: PropTypes.number
}

export default RequestLike
