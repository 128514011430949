import React from "react"
import PropTypes from "prop-types"

class PreviousResultForm extends React.Component {
  constructor(props) {
    super(props);
  }

 previousResultText () {
    return (
      <div className="form-group">
        <label className="color-label">Previous Result Text:</label>
        <input
          type="text"
          defaultValue={this.props.themeSettings.data.previous_result_text }
          onInput={(e) => this.props.handleThemeSettingChange(e, 'previous_result_text')}
          className="form-control"
        />
      </div>
    )
  }
  
  greeting () {
    return (<div className="form-group">
      <label className="color-label">Greeting:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_preview_greeting || this.props.defaultTexts.auto_preview_greeting}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_preview_greeting')}
        className="form-control"
      />
    </div>)
  }

  button () {
    return (<div className="form-group">
      <label className="color-label">Button:</label>
      <input
        type="text"
        value={this.props.themeSettings.data.auto_preview_button || this.props.defaultTexts.auto_preview_button}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'auto_preview_button')}
        className="form-control"
      />
    </div>)
  }

 
  render () {
    return (
      <div className="user-setting-form">
        {this.greeting()}
        {this.previousResultText()}
        {this.button()}
      </div>
    );
  }
}

export default PreviousResultForm;