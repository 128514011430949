import React from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom';

class ButtonForm extends React.Component {

  constructor(props) {
    super(props);
    this.buttonFontColor = React.createRef();
    this.buttonBackgroundColor = React.createRef();
  }

   componentDidMount() {
    var defaultOptions = {
      allowEmpty:true,
      showInput: true,
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "hex"
    }

    $(ReactDOM.findDOMNode(this.buttonFontColor.current)).spectrum($.extend({}, defaultOptions, {
      color: this.props.userSetting.button_font_color,
      change: this.props.handleButtonFontColorChange,
      hide: this.props.handleButtonFontColorChange,
      move: this.props.handleButtonFontColorChange
    } ));

     $(ReactDOM.findDOMNode(this.buttonBackgroundColor.current)).spectrum($.extend({}, defaultOptions, {
      color: this.props.userSetting.button_background_color,
      change: this.props.handleButtonBackgroundColorChange,
      hide: this.props.handleButtonBackgroundColorChange,
      move: this.props.handleButtonBackgroundColorChange
    }));
  }

  setupButtonColors() {
    return <div>
      <div className="form-group">
        <label className="color-label">Button Font Color:</label>
        <input type="text" ref={this.buttonFontColor}
          defaultValue={this.props.userSetting.button_font_color} className="form-control" />
      </div>

      <div className="form-group">
        <label className="color-label">Button Background Color:</label>
        <input type="text" ref={this.buttonBackgroundColor}
          defaultValue={this.props.userSetting.button_background_color} className="form-control" />
      </div>
      <p>Want more customization? Our support team would be delighted to assist you with complete customization completely free of charge.
          Submit<a className="ml-5 text-" data-toggle="modal" data-target="#request_submit"><strong>this</strong></a> short form to get started.
      </p>
    </div>
  }

  render() {
     return (
      <div className='user-setting-form'>
        <div className="form-group">
          <label className="color-label">Button Text:</label>
          <input type="text"
            value={this.props.userSetting.button_text}
            onInput={(e) => this.props.handleButtonTextChange(e)}
            className="form-control"/>
        </div>
        <div>
           <div className="form-group">
             <label className="color-label">Button Font Color:</label>
             <input type="text" ref={this.buttonFontColor}
               defaultValue={this.props.userSetting.button_font_color} className="form-control" />
           </div>

           <div className="form-group">
             <label className="color-label">Button Background Color:</label>
             <input type="text" ref={this.buttonBackgroundColor}
               defaultValue={this.props.userSetting.button_background_color} className="form-control" />
           </div>
           <p>Want more customization? Our support team would be delighted to assist you with complete customization completely free of charge.
            Submit<a className="ml-5 text-" data-toggle="modal" data-target="#request_submit"><strong>this</strong></a> short form to get started.
            </p>
         </div>
      </div>
    );
  }
}

export default ButtonForm
