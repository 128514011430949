import React from "react"
import PropTypes from "prop-types"
import SwitchButton from 'components/base/SwitchButton'
import SelectFontOption from 'components/base/SelectFontOption'
import { googleFonts } from "components/base/fonts";
import ReactDOM from 'react-dom';

class ExitOfferForm extends React.Component {

  constructor(props) {
    super(props);
    this.linkColor = React.createRef();
    this.linkBackgroundColor = React.createRef();
    this.mainHeadingFontColor = React.createRef();
    this.secondaryHeadingFontColor = React.createRef();
    this.headerBackgroundColor = React.createRef();
    this.state = {
      offerBtnFont: "",
      offerTitleFont: "",
      offerSubtitleFont: "",
      offerLabelsFont: "",
    }
  }

  handleOfferButtonFont = (e) => {
    this.setState({offerBtnFont: e.target.value})
    this.props.handleOfferButtonFont(e.target.value)
  }

  handleOfferTitleFont = (e) => {
    this.setState({offerTitleFont: e.target.value})
    this.props.handleOfferTitleFont(e.target.value)
  }

   handleOfferSubTitleFont = (e) => {
    this.setState({offerSubtitleFont: e.target.value})
    this.props.handleOfferSubTitleFont(e.target.value)
  }

   handleOfferLabelsFont = (e) => {
    this.setState({offerLabelsFont: e.target.value})
    this.props.handleOfferLabelsFont(e.target.value)
  }

  componentDidMount () {
    var defaultOptions = {
      allowEmpty: true,
      showInput: true,
      showInitial: true,
      showPalette: true,
      showSelectionPalette: true,
      showAlpha: true,
      maxPaletteSize: 10,
      preferredFormat: "hex"
    };

    $(ReactDOM.findDOMNode(this.linkColor.current)).spectrum(
      $.extend({}, defaultOptions, {
        color: this.props.themeSettings.data.submit_button_color || this.props.colors.buttonFont,
        change: (e) => this.props.handleThemeSettingChange(e, 'submit_button_color'),
        hide: (e) => this.props.handleThemeSettingChange(e, 'submit_button_color'),
        move: (e) => this.props.handleThemeSettingChange(e, 'submit_button_color'),
      })
    );

    $(ReactDOM.findDOMNode(this.linkBackgroundColor.current)).spectrum(
      $.extend({}, defaultOptions, {
        color: this.props.themeSettings.data.submit_button_background_color || this.props.colors.buttonBackground,
        change: (e) => this.props.handleThemeSettingChange(e, 'submit_button_background_color'),
        hide: (e) => this.props.handleThemeSettingChange(e, 'submit_button_background_color'),
        move: (e) => this.props.handleThemeSettingChange(e, 'submit_button_background_color'),
      })
    );

    $(ReactDOM.findDOMNode(this.mainHeadingFontColor.current)).spectrum(
      $.extend({}, defaultOptions, {
        color: this.props.themeSettings.data.main_heading_font_color || this.props.colors.mainHeading,
        change: (e) => this.props.handleThemeSettingChange(e, 'main_heading_font_color'),
        hide: (e) => this.props.handleThemeSettingChange(e, 'main_heading_font_color'),
        move: (e) => this.props.handleThemeSettingChange(e, 'main_heading_font_color'),
      })
    );

    $(ReactDOM.findDOMNode(this.secondaryHeadingFontColor.current)).spectrum(
      $.extend({}, defaultOptions, {
        color: this.props.themeSettings.data.secondary_heading_font_color || this.props.colors.secondaryHeading,
        change: (e) => this.props.handleThemeSettingChange(e, 'secondary_heading_font_color'),
        hide: (e) => this.props.handleThemeSettingChange(e, 'secondary_heading_font_color'),
        move: (e) => this.props.handleThemeSettingChange(e, 'secondary_heading_font_color'),
      })
    );

    $(ReactDOM.findDOMNode(this.headerBackgroundColor.current)).spectrum(
      $.extend({}, defaultOptions, {
        color: this.props.themeSettings.data.header_background_color || this.props.colors.background,
        change: (e) => this.props.handleThemeSettingChange(e, 'header_background_color'),
        hide: (e) => this.props.handleThemeSettingChange(e, 'header_background_color'),
        move: (e) => this.props.handleThemeSettingChange(e, 'header_background_color'),
      }) 
    );

  }


  mainHeadingTitle () {
    return(<div className="form-group">
      <label className="color-label">Main Heading Title:</label>
      <input
        type="text"
        defaultValue={this.props.themeSettings.data.main_heading || this.props.defaultTexts.main_heading}
        onInput={(e) => this.props.handleThemeSettingChange(e, 'main_heading')}
        className="form-control"
      />
    </div>)
  }

  mainHeadingColorField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">Main Heading Color:</label>
          <input
            type="text"
            ref={this.mainHeadingFontColor}
            defaultValue={this.props.themeSettings.data.main_heading_font_color}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic-with-label">
          <label className="color-label">Main Heading Color:</label>
          <i className="fa fa-lock"></i>
          <input
            type="text"
            ref={this.mainHeadingFontColor}
            disabled={true}
            defaultValue={this.props.themeSettings.data.main_heading_font_color}
            className="form-control"
          />
        </div>
      )
    )
  }

  secondaryHeadingTitle () {
    return (
      <div className="form-group">
        <label className="color-label">Secondary Heading Title:</label>
        <input
          type="text"
          defaultValue={this.props.themeSettings.data.secondary_heading || this.props.defaultTexts.secondary_heading}
          onInput={(e) => this.props.handleThemeSettingChange(e, 'secondary_heading')}
          className="form-control"
        />
      </div>
    )
  }

  secondaryHeadingColorField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">Secondary Heading Color:</label>
          <input
            type="text"
            ref={this.secondaryHeadingFontColor}
            defaultValue={this.props.themeSettings.data.secondary_heading_font_color}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic-with-label">
          <label className="color-label">Secondary Heading Color:</label>
          <i className="fa fa-lock"></i>
          <input
            type="text"
            disabled={true}
            ref={this.secondaryHeadingFontColor}
            defaultValue={this.props.themeSettings.data.secondary_heading_font_color}
            className="form-control"
          />
        </div>
      )
    )
  }

  headerBackgroundColorField (label = 'Header Background Color:') {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">{label}</label>
          <input
            type="text"
            ref={this.headerBackgroundColor}
            defaultValue={this.props.themeSettings.data.header_background_color}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic-with-label">
          <label className="color-label">{label}</label>
          <i className="fa fa-lock"></i>
          <input
            type="text"
            ref={this.headerBackgroundColor}
            disabled={true}
            defaultValue={this.props.themeSettings.data.header_background_color}
            className="form-control"
          />
        </div>
      )
    )
  }


  offerAmountField () {
    var defaultAmountFieldPlaceholder = this.props.themeSettings.data.show_quantity ?
      this.props.defaultTexts.hint_amount : this.props.defaultTexts.hint_amount_with_quantity
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">Offer Amount Field</label>
          <input
            type="text"
            placeholder={defaultAmountFieldPlaceholder}
            defaultValue={this.props.themeSettings.data.hint_amount}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_amount")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic lock-input">
          <label className="color-label">Offer Amount Field</label>
          <input
            type="text"
            placeholder={defaultAmountFieldPlaceholder}
            defaultValue={this.props.themeSettings.data.hint_amount}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_amount")}
            className="form-control"
            disabled={true}
          />
          <i className="fa fa-lock"></i>
        </div>
      )
    )
  }

  offerEmailField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">Email Field</label>
          <input
            type="text"
            placeholder={this.props.defaultTexts.hint_email}
            defaultValue={this.props.themeSettings.data.hint_email}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_email")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic lock-input">
          <label className="color-label">Email Field</label>
          <i className="fa fa-lock"></i>
          <input
            type="text"
            placeholder={this.props.defaultTexts.hint_email}
            defaultValue={this.props.themeSettings.data.hint_email}
            disabled={true}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_email")}
            className="form-control"
          />
        </div>
      )
    )
  }

  phoneNumberSwitcher () {
    return (
      <div className="form-group">
        <label className="color-label">Phone Number Field:</label>
        <SwitchButton
          name="price"
          checked={this.props.themeSettings.data.show_phone_number}
          onChange={(e) => this.props.handleThemeSettingChange(e, 'show_phone_number')}
        />
        {this.phoneNumberField()}
      </div>
    )
  }

  phoneNumberField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <input
            type="text"
            placeholder={this.props.defaultTexts.hint_phone_number}
            disabled={!this.props.themeSettings.data.show_phone_number}
            defaultValue={this.props.themeSettings.data.hint_phone_number}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_phone_number")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic">
          <i className="fa fa-lock"></i>
          <input
            type="text"
            placeholder={this.props.defaultTexts.hint_phone_number}
            disabled={true}
            defaultValue={this.props.themeSettings.data.hint_phone_number}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_phone_number")}
            className="form-control"
          />
        </div>
      )
    )
  }

  nameSwitcher () {
    return (
      <div className="form-group">
        <label className="color-label">Name Field:</label>
        <SwitchButton
          name="name"
          checked={this.props.themeSettings.data.show_name}
          onChange={(e) => this.props.handleThemeSettingChange(e, 'show_name')}
        />
        {this.nameField()}
      </div>
    )
  }

  nameField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <input
            type="text"
            disabled={!this.props.themeSettings.data.show_name}
            defaultValue={this.props.themeSettings.data.hint_customer_name}
            placeholder={this.props.defaultTexts.hint_customer_name}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_customer_name")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic">
          <i className="fa fa-lock"></i>
          <input
            type="text"
            disabled={true}
            defaultValue={this.props.themeSettings.data.hint_customer_name}
            placeholder={this.props.defaultTexts.hint_customer_name}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_customer_name")}
            className="form-control"
          />
        </div>
      )
    )
  }

  shippingSwitcher () {
    return (
      <div className="form-group">
        <label className="color-label">Shipping Address Field:</label>
        <SwitchButton
          name="shipping_address"
          checked={this.props.themeSettings.data.show_shipping_address}
          onChange={(e) => this.props.handleThemeSettingChange(e, 'show_shipping_address')}
        />
        {this.shippingField()}
      </div>
    )
  }

  shippingField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <input
            type="text"
            disabled={!this.props.themeSettings.data.show_shipping_address}
            defaultValue={this.props.themeSettings.data.hint_shipping_address}
            placeholder={this.props.defaultTexts.hint_shipping_address}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_shipping_address")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic">
          <i className="fa fa-lock"></i>
          <input
            type="text"
            disabled={true}
            defaultValue={this.props.themeSettings.data.hint_shipping_address}
            placeholder={this.props.defaultTexts.hint_shipping_address}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_shipping_address")}
            className="form-control"
          />
        </div>
      )
    )
  }

  noteSwitcher () {
    return (
      <div className="form-group">
        <label className="color-label">Note Field:</label>
        <SwitchButton
          name="notes_text"
          checked={this.props.themeSettings.data.show_notes}
          onChange={(e) => this.props.handleThemeSettingChange(e, 'show_notes')}
        />
        {this.noteField()}
      </div>
    )
  }

  gdprSwitcher () {
    let showGDPR = this.props.themeSettings.data.show_gdpr;
    return (
      <React.Fragment>
        <div className="form-group gdpr">
          <label className="color-label">GDPR checkbox:</label>
          <SwitchButton
            name="gdpr_text"
            checked={showGDPR}
            onChange={(e) => this.props.handleThemeSettingChange(e, 'show_gdpr')}
            css_id="gdpr"
          />
        </div>
        {
          showGDPR ?
            <div className="form-group private_policy_link">
              <input
                type="text"
                defaultValue={this.props.userSetting.private_policy}
                placeholder="Your Private Policy Link"
                onInput={(e) => this.props.handlePrivatePolicyLinkChange(e)}
                className="form-control"
              />
              <p className="text-muted mt-5">
                by default we will use our <a target="_blank" href="/static/private_policy">Private Policy</a> link
              </p>
            </div>
          : null }
      </React.Fragment>
    )
  }

  termsSwitcher () {
    let showTerms = this.props.themeSettings.data.show_terms;
    return (
      <React.Fragment>
        <div className="form-group gdpr">
          <label className="color-label">Terms checkbox:</label>
          <SwitchButton
            name="terms_text"
            checked={showTerms}
            onChange={(e) => this.props.handleThemeSettingChange(e, 'show_terms')}
            css_id="terms"
          />
        </div>
        {
          showTerms ?
            <div>
              <div className="form-group private_policy_link">
                <input
                  type="text"
                  defaultValue={this.props.userSetting.terms_of_service}
                  placeholder="Your Terms Of Service Link"
                  onInput={(e) => this.props.handleTermsOfServiceLinkChange(e)}
                  className="form-control"
                />
                <p className="text-muted mt-5">
                  by default we will use our <a target="_blank" href="/static/terms_of_service">Terms Of Service</a> link
                </p>
              </div>

              <div className="form-group private_policy_link">
                <textarea
                  type="text"
                  defaultValue={this.props.userSetting.custom_terms_text}
                  placeholder="Terms of Service Text Custom HTML"
                  onInput={(e) => this.props.handleCustomTermsTextChange(e)}
                  className="form-control"
                  rows="4"
                  style={{ width: "400" }}
                />
              </div>
            </div>
           : null }
      </React.Fragment>
    )
  }

  googleAnalyticSwitcher () {
    let showGAC = this.props.themeSettings.data.show_gac;
    return (
      <React.Fragment>
        <div className="form-group gdpr">
          <label className="color-label">Google Analytics Events:</label>
          <SwitchButton
            name="gac_text"
            checked={showGAC}
            onChange={(e) => this.props.handleThemeSettingChange(e, 'show_gac')}
            css_id="gac"
          />
        </div>
        {
          showGAC ?
          <div className="form-group">
            <label className="color-label"> Google Analytics Code</label>
            <input
              name="google_analytics_code"
              type="text"
              defaultValue={this.props.userSetting.google_analytics_code}
              placeholder="Add Google Analytics code"
              onInput={(e) => this.props.handleGoogleAnalyticeCode(e)}
              className="form-control"
            />
          </div>
          : null }
      </React.Fragment>
    )
  }
  

  offerButtonGoogleFont () {
    let offer_button_font = this.props.userSetting.offer_btn_font
    return (
      <div className="form-group">
        <label className="color-label">Select Button Font:</label>
        <SelectFontOption 
          googleFonts={googleFonts}
          handleOnChange={this.handleOfferButtonFont}
          selectedValue={offer_button_font}
        />
      </div>
    )
  }

  offerTitleGoogleFont () {
    let offer_title_font = this.props.userSetting.offer_title_font
    return (
      <div className="form-group">
        <label className="color-label">Select Main Heading Title Font :</label>
         <SelectFontOption 
          googleFonts={googleFonts}
          handleOnChange={this.handleOfferTitleFont}
          selectedValue={offer_title_font}
        />
      </div>
    )
  }

  offerSubTitleGoogleFont () {
    let offer_subtitle_font = this.props.userSetting.offer_subtitle_font
    return (
      <div className="form-group">
        <label className="color-label">Select Secondary Heading Title Font:</label>
        <SelectFontOption 
          googleFonts={googleFonts}
          handleOnChange={this.handleOfferSubTitleFont}
          selectedValue={offer_subtitle_font}
        />
      </div>
    )
  }

  offerLabelsFont () {
    let offer_labels_font = this.props.userSetting.offer_labels_font
    return (
      <div className="form-group">
        <label className="color-label">Select Input Labels Font:</label>
        <SelectFontOption 
          googleFonts={googleFonts}
          handleOnChange={this.handleOfferLabelsFont}
          selectedValue={offer_labels_font}
        />
      </div>
    )
  }

  noteField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <input
            type="text"
            disabled={!this.props.themeSettings.data.show_notes}
            defaultValue={this.props.themeSettings.data.hint_notes}
            placeholder={this.props.defaultTexts.hint_notes}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_notes")}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic">
          <i className="fa fa-lock"></i>
          <input
            type="text"
            disabled={true}
            defaultValue={this.props.themeSettings.data.hint_notes}
            placeholder={this.props.defaultTexts.hint_notes}
            onInput={(e) => this.props.handleThemeSettingChange(e, "hint_notes")}
            className="form-control"
          />
        </div>
      )
    )
  }

  sendOfferButton () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">Offer Button</label>
          <input
            type="text"
            defaultValue={this.props.themeSettings.data.button_submit}
            placeholder={this.props.defaultTexts.button_submit}
            onInput={(e) => this.props.handleThemeSettingChange(e, "button_submit")}
            className="form-control"
          />
        </div>
      )
    )
  }


  howItWorksField () {
    return (
      supportsFeature(
        "form_customization",
        this.props.extraFeatures,
        <div className="form-group">
          <label className="color-label">How it works</label>
          <textarea
            type="text"
            rows="4"
            style={{ width: "400" }}
            placeholder={this.props.defaultTexts.how_it_works}
            defaultValue={this.props.themeSettings.data.how_it_works}
            onInput={(e) => this.props.handleThemeSettingChange(e, 'how_it_works')}
            className="form-control"
          />
        </div>,
        <div className="form-group lock-basic lock-input">
          <label className="color-label">How it works</label>
          <i className="fa fa-lock"></i>
          <textarea
            type="text"
            rows="4"
            style={{ width: "400" }}
            placeholder={this.props.defaultTexts.how_it_works}
            defaultValue={this.props.themeSettings.data.how_it_works}
            onInput={(e) => this.props.handleThemeSettingChange(e, 'how_it_works')}
            className="form-control"
            disabled={true}
          />
        </div>
      )
    )
  }

  buttonFontColor () {
    return (
      <div className="form-group">
        <label className="color-label">Button Font Color:</label>
        <input
          type="text"
          ref={this.linkColor}
          defaultValue={this.props.themeSettings.data.submit_button_color}
          className="form-control"
        />
      </div>
    )
  }

  buttonBackgroundColor () {
    return (
      <div className="form-group">
        <label className="color-label">Button Background Color:</label>
        <input
          type="text"
          ref={this.linkBackgroundColor}
          defaultValue={this.props.themeSettings.data.submit_button_background_color}
          className="form-control"
        />
      </div>
    )
  }

  quantityFieldSelector () {
    return supportsFeature(
      "quantity",
      this.props.extraFeatures,
      <div className="form-group quantity-field">
        <label className="color-label">Quantity Field:</label>
        <SwitchButton
          name="quantity"
          checked={this.props.themeSettings.data.show_quantity}
          onChange={(e) => this.props.handleThemeSettingChange(e, "show_quantity")}
          css_id="quantity-switcher"
        />
        {this.quantityField()}
      </div>,
      <div className="form-group quantity-field lock-basic-with-label">
        <label className="color-label">Quantity Field:</label>
        <i className="fa fa-lock"></i>
        <SwitchButton
          name="quantity"
          checked={false}
          css_id="quantity-switcher"
        />
        {this.quantityField()}
      </div>
    );
  }

  quantityField () {
    return supportsFeature(
      "form_customization",
      this.props.extraFeatures,
      <div className="form-group">
        <input
          type="text"
          placeholder={this.props.defaultTexts.hint_quantity}
          disabled={!this.props.themeSettings.data.show_quantity}
          defaultValue={this.props.themeSettings.data.hint_quantity}
          onInput={(e) => this.props.handleThemeSettingChange(e, "hint_quantity")}
          className="form-control"
        />
      </div>,
      <div className="form-group">
        <input
          type="text"
          placeholder={this.props.defaultTexts.hint_quantity}
          disabled={true}
          defaultValue={this.props.themeSettings.data.hint_quantity}
          onInput={(e) => this.props.handleThemeSettingChange(e, "hint_quantity")}
          className="form-control"
        />
      </div>
    );
  }

  lockExplanation () {
    return supportsFeature(
      "form_customization",
      this.props.extraFeatures,
      null,
      <div className="explanation-lock">
        <i className="fa fa-lock"></i> - Upgrade to standard plan to unlock these features. <span className="link_for_upgrade" onClick={this.showUpgradePlan}>Click here</span> to upgrade
      </div>
    );
  }

  showUpgradePlan () {
    $('#settings-btn').click();
    $('html, body').animate({
      scrollTop: $(".plan").offset().top
    }, 1000);
  }

  render () {
    return (
      <div className="user-setting-form">
        {this.mainHeadingTitle()}
        {this.mainHeadingColorField()}
        {this.secondaryHeadingTitle()}
        {this.secondaryHeadingColorField()}
        {this.headerBackgroundColorField()}
        {this.offerAmountField()}
        {this.offerEmailField()}
        {this.quantityFieldSelector()}
        {this.phoneNumberSwitcher()}
        {this.nameSwitcher()}
        {this.shippingSwitcher()}
        {this.noteSwitcher()}
        {this.sendOfferButton()}
        {this.howItWorksField()}
        {this.buttonFontColor()}
        {this.buttonBackgroundColor()}
        {this.lockExplanation()}
      </div>
    );
  }
}

export default ExitOfferForm
