import { h } from 'preact'
import React from "react"
import PropTypes from "prop-types"
import I18n from 'i18n-js/index.js.erb'

class EmailCopyPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var subject = this.props.userSetting.email_copies[this.props.type].subject;
    var body = this.props.userSetting.email_copies[this.props.type].body.replace(/\n/g, '<br>')

    var body = body.replace(/\[\[(A|C)\]\]/g, '')
    var body = body.replace(/\[\[LOGO\((.+)\)\]\]/, '<img src="$1"/>')
    var body = body.replace(/\[\[STORE_EMAIL_ADDRESS\]\]/, `<a href='mailto:${this.props.initialUserEmail}'>${this.props.initialUserEmail}</a>`)
    var body = body.replace(/\[\[STORE_SECONDARY_EMAIL_ADDRESS\]\]/, `<a href='mailto:${this.props.initialUserSecondaryEmail}'>${this.props.initialUserSecondaryEmail}</a>`)
    
    return (
      <div id="mf_email_copy_preview">
        <strong> Subject: </strong>
        <p>{subject}</p>
        <strong> Body: </strong>
        <p dangerouslySetInnerHTML={{__html: body}}></p>
      </div>
    )
  }
}


EmailCopyPreview.propTypes = {

}

export default EmailCopyPreview
