import { h } from 'preact'
import React from "react"
import Header from '../Header'

class DisplayingStep extends React.Component {
  showUpgradePlan () {
    $('#onboarding_modal').modal('hide');
    $('#settings-btn').click();
    $('html, body').animate({
      scrollTop: $(".plan").offset().top
    }, 1000);
  }

  render () {
    const unlock = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      null, <i className="fa fa-unlock"></i>
    )

    const lock = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      null, <i className="fa fa-lock"></i>
    )

    const lockExplanation = supportsFeature(
      "incentives",
      this.props.extraFeatures,
      null,
      <div className="explanation">
        <i className="fa fa-lock"></i>
        <p className="explanation_text">
          Upgrade to standard plan to unlock these features. <span className="link_for_upgrade" onClick={this.showUpgradePlan}>Click here</span> to upgrade
          (Don't worry, all your settings are saved and you can continue the setup where you left off.)
        </p>
      </div>
    )
    return (
      <div className="content">
        <Header additionalClass={'multi_line_title'} title="Where do you want to display the incentive options?" />
        <div className="step_content row display_incentive">
          <div className="col-sm-12 mb-30">
            <div className="col-sm-10 col-sm-offset-1 mt-30">
              <div className="form-group">
                <select className="form-control display_rule_type"
                  value={this.props.userSetting.display_rule_type}
                  onChange={this.props.handleConfiguration.bind(null, "display_rule_type")}>
                  <option value="1">All Products</option>
                  <option value="2">
                    Show only on product skus, collections or tags selected below
                  </option>
                  <option value="3">
                    Show on all products except skus, collections or tags selected below
                  </option>
                </select>
              </div>
              <div className="form-group">
                {unlock}
                <input type="text" placeholder="Enter the SKU numbers (separate them by commas)"
                       className="form-control sku"
                       value={this.props.userSetting.skus}
                       onChange={(e) => this.props.handleConfiguration("skus", e)} />
              </div>
              <div className="form-group">
                {lock}
                <input type="text"
                  placeholder="Enter collection titles (separate them by commas)"
                  className="form-control collection"
                  disabled={supportsFeature(
                    "incentives",
                    this.props.extraFeatures,
                    false, true
                  )}
                  value={this.props.userSetting.collections}
                  onChange={(e) => this.props.handleConfiguration("collections", e)} />
              </div>

              <div className="form-group">
                {lock}
                <input type="text"
                  placeholder="Enter tags (separate them by commas)"
                  className="form-control tags"
                  value={this.props.userSetting.tags}
                  disabled={supportsFeature(
                    "incentives",
                    this.props.extraFeatures,
                    false, true
                  )}
                  onChange={(e) => this.props.handleConfiguration("tags", e)} />
              </div>
              {lockExplanation}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayingStep
